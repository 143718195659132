<template>
    <div>
        <div class="d-none">
            <div ref="sheetpayments">
                <OSheetReceiptPaymentComp :receipt="receipt" />
            </div>
        </div>
        <div class="section-print pb-5">
            <div class="pa-2 d-flex align-center">
                <h1 class="text-body-title">COBROS DE DEUDAS</h1>
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    <OBtnHeaderToolTipComp
                        smsTooltip="Últimos 4 recibos"
                        icon="mdi-printer"
                        class="mr-1"
                        @click="ac_showDialgogLastFiveReceipts()"
                    />
                    <OBtnHeaderToolTipComp
                        v-if="tab!=0"
                        smsTooltip="Volver a la lista anterior."
                        icon="mdi-database"
                        class="mr-1"
                        @click="ac_backPreviousList()"
                    />
                    <OBtnHeaderToolTipComp
                        smsTooltip="Ir al detalle de pago"
                        icon="mdi-account-cash"
                        class="mr-1"
                        @click="tab=3"
                    />
                </div>
            </div>
            <MLineHeaderComp />
            <v-tabs-items
                style="background:transparent !important"
                v-model="tab"
                :touchless="true"
            >
                <v-tab-item>
                    <div class="d-flex justify-center mt-2 pr-1 pl-2">
                        <OSearchComp
                            style="width:400px; max-width:400px;"
                            label="Nombre o CI/NIT"
                            class="mr-1"
                            @search="ac_searchClient()"
                            v-model="searchClient"
                        />
                    </div>
                    <v-divider class="mt-2 mb-2"></v-divider>
                    <OLoaderDataComp
                        v-if="dialogLoaderData"
                        class="mt-2"
                        smsLoaderData="Obteniendo Clientes"
                    />
                    <MNoDataComp
                        smsNotData="SIN CLIENTES"
                        class="ma-1"
                        v-if="listClients.length==0 && !dialogLoaderData && !viewTable"
                    />
                    <div class="d-flex justify-center mt-3" v-if="viewTable">
                        <STableClientDebtsComp
                            v-if="!dialogLoaderData"
                            :listClients="listClients"
                            :searchClient="searchFilter"
                            @clickworks="ac_showWorksClient"
                        />
                    </div>
                    <div class="d-flex justify-center" v-else>
                        <div
                            v-if="!dialogLoaderData"
                            class="d-flex justify-center flex-wrap"
                            style="width:100%"
                        >
                            <SCardClientDebtsComp
                                v-for="(client, index) in listClients"
                                :key="index"
                                :client="client"
                                :searchClient="searchFilter"
                                class="ma-2"
                                @clickworks="ac_showWorksClient(client)"
                            />
                        </div>
                    </div>
                    <SPaginationComp
                        class="mt-2"
                        v-if="!dialogLoaderData && listClients.length>0"
                        :condicionGo="page < last_page"
                        :condicionBack="page > 1"
                        :titlePagination="'Pag. '+page+' de '+last_page"
                        @clickback="ac_changePage(false)"
                        @clickgo="ac_changePage(true)"
                    />
                </v-tab-item>
                <v-tab-item>
                    <div class="pa-2 o-header-work-payments" v-if="dataClient.full_name!=undefined">
                        <div class="o-header-work-payments__first">
                            <div class="d-flex">
                                <h1 class="text-body-title mr-1">Cliente:</h1>
                                <h1 class="text-body-subtitle">{{dataClient.full_name}}</h1>
                            </div>
                            <div class="d-flex">
                                <h1 class="text-body-title mr-1">CI/NIT:</h1>
                                <h1 class="text-body-subtitle">{{dataClient.ci_nit}}</h1>
                            </div>
                            <div class="d-flex">
                                <h1 class="text-body-title mr-1">Celular:</h1>
                                <h1 class="text-body-subtitle">
                                    {{dataClient.phone!=null ? dataClient.phone : 'S/N'}}</h1>
                            </div>
                        </div>
                        <div class="o-header-work-payments__first">
                            <div class="d-flex">
                                <h1 class="text-body-title mr-1">Expresado:</h1>
                                <h1 class="text-body-subtitle">Bolivianos</h1>
                            </div>
                            <div class="d-flex">
                                <h1 class="text-body-title mr-1">N° de Deudas</h1>
                                <h1 class="text-body-subtitle">
                                    {{dataClient.list_works.length}}</h1>
                            </div>
                            <div class="d-flex">
                                <h1 class="text-body-title mr-1">Total Adeudado</h1>
                                <h1 class="text-body-subtitle">
                                    {{ac_totalDebts().toFixed(2)}}</h1>
                            </div>
                        </div>
                        <div :class="viewTable ? 'mt-1' : 'mt-2'">
                            <MSelectComp
                                :items="itemsTypeHonorary"
                                itemText="fee"
                                itemValue="id"
                                label="Tipo Honorario"
                                :hideDetails="true"
                                v-model="type_honorary"
                            />
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <MNoDataComp
                        smsNotData="SIN DEUDAS"
                        class="ma-1 mt-2"
                        v-if="dataClient.list_works.length==0 && !dialogLoaderData && !viewTable"
                    />
                    <div class="d-flex justify-center mt-3" v-if="viewTable">
                        <!--old: dataClient.list_works-->
                        <STableWorksDebtComp
                            v-if="!dialogLoaderData"
                            :listWorks="list_worksFilterByFee"
                            @clickpayments="ac_showListPayments"
                            @ckickdetail="ac_showDetailWork"
                            @clickcancelled="ac_showDialogCancelled"
                        />
                    </div>
                    <div class="d-flex justify-center flex-wrap" v-else>
                        <SCardWorkDebtComp
                            v-for="(work, index) in list_worksFilterByFee"
                            :key="index"
                            class="ma-2"
                            :work="work"
                            @clickpayments="ac_showListPayments(work)"
                            @ckickdetail="ac_showDetailWork(work)"
                            @clickcancelled="ac_showDialogCancelled(work)"
                        />
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <div class="pa-2 o-header-work-payments" v-if="dataClient.full_name!=undefined">
                        <div class="o-header-work-payments__first">
                            <div class="d-flex">
                                <h1 class="text-body-title mr-1">Cliente:</h1>
                                <h1 class="text-body-subtitle">{{dataClient.full_name}}</h1>
                            </div>
                            <div class="d-flex">
                                <h1 class="text-body-title mr-1">Dirección:</h1>
                                <h1 class="text-body-subtitle">
                                    {{dataClient.address!=null ? dataClient.address : 'S/D'}}</h1>
                            </div>
                            <div class="d-flex">
                                <h1 class="text-body-title mr-1">Celular:</h1>
                                <h1 class="text-body-subtitle">
                                    {{dataClient.phone!=null ? dataClient.phone : 'S/N'}}</h1>
                            </div>
                        </div>
                        <div v-if="work.fee!=undefined">
                            <div class="d-flex">
                                <h1 class="text-body-title mr-1">Tipo de Trabajo:</h1>
                                <h1 class="text-body-subtitle">{{work.fee.fee}}</h1>
                            </div>
                            <div class="d-flex">
                                <h1 class="text-body-title mr-1">Periodo:</h1>
                                <h1 class="text-body-subtitle">
                                    {{work.period!=null ? work.period : 'S/P'}}</h1>
                            </div>
                            <div class="d-flex">
                                <h1 class="text-body-title mr-1">Fecha de Trabajo:</h1>
                                <h1 class="text-body-subtitle">
                                    {{work.work_date}}</h1>
                            </div>
                        </div>
                    </div>
                    <v-divider class="mb-2"></v-divider>
                    <OLoaderDataComp
                        v-if="dialogLoaderDataPayments"
                        class="mt-2"
                        smsLoaderData="Obteniendo Pagos"
                    />
                    <MNoDataComp
                        smsNotData="SIN DATOS"
                        class="ma-1"
                        v-if="listPayments.length==0 && !dialogLoaderDataPayments && !viewTable"
                    />
                    <div class="d-flex justify-center mt-3" v-if="viewTable">
                        <STablePaymentsComp
                            v-if="!dialogLoaderDataPayments"
                            :listPayments="listPayments"
                            :isTable="false"
                        />
                    </div>
                    <div class="d-flex justify-center" v-else>
                        <div
                            v-if="!dialogLoaderDataPayments"
                            class="d-flex justify-center flex-wrap"
                            style="width:100%"
                        >
                            <SCardPaymentComp
                                class="ma-2"
                                v-for="(payment, index) in listPayments"
                                :key="index"
                                :payment="payment"
                                :isTable="false"
                            />
                        </div>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <h1 class="text-center text-body-title mt-3">DETALLE DE PAGOS</h1>
                    <h1 class="text-center" style="font-size:12px">(Expresado en Bolivianos)</h1>
                    <div class="d-flex justify-center mt-3" v-if="viewTable">
                        <STableDetailPaymentsComp
                            :detailPayments="detailPayments"
                            @clickdelete="ac_deletePayment"
                        />
                    </div>
                    <div v-else class="mt-2">
                        <div class="d-flex pl-2 pr-2">
                            <h1 class="text-body-title mr-1">Total Deuda:</h1>
                            <h1 class="text-body-subtitle">
                                {{totalDebt.toFixed(2)}}</h1>
                        </div>
                        <div class="d-flex pl-2 pr-2">
                            <h1 class="text-body-title mr-1">Total monto a Cancelar:</h1>
                            <h1 class="text-body-subtitle">
                                {{totalPayment.toFixed(2)}}</h1>
                        </div>
                        <div class="d-flex pl-2 pr-2">
                            <h1 class="text-body-title mr-1">Total Saldo:</h1>
                            <h1 class="text-body-subtitle">
                                {{totalSaldo.toFixed(2)}}</h1>
                        </div>
                        <MNoDataComp
                            smsNotData="SIN PAGOS AGREGOS"
                            class="ma-1"
                            v-if="detailPayments.length==0"
                        />
                        <div class="d-flex justify-center">
                            <div
                                class="d-flex justify-center flex-wrap"
                                style="width:100%"
                            >
                                <SCardDetailPaymentsComp
                                    class="ma-2"
                                    v-for="(payment, index) in detailPayments"
                                    :key="index"
                                    :index="index"
                                    :payment="payment"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-center mt-3">
                        <MBtnNormalComp
                            color="primary"
                            :outlined="true"
                            @click="ac_confirmDetailPayments()"
                        >
                            REGISTRAR
                        </MBtnNormalComp>
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <v-dialog
            v-model="dialogDetailWork"
            width="650px"
            persistent
            content-class="dialog--margin"
        >
            <v-card class="ma-0 pa-0" v-if="work.client!=undefined">
                <ODialogHeaderComp
                    :title="work.client.full_name"
                    @clickclose="dialogDetailWork=false"

                />
                <SDetailWorkComp
                    :work="work"
                    :isInfo="true"
                />
            </v-card>
        </v-dialog>
        <!--dialog Cancelled debt-->
        <v-dialog
            persistent
            v-model="dialogCancelled"
            width="500"
            content-class="dialog--margin"
        >
            <v-card class="ma-0 pa-0">
                <ODialogHeaderComp
                    title="Cancelar Deuda"
                    @clickclose="dialogCancelled=false"
                />
                <div class="pa-2" v-if="work.client!=undefined">
                    <div>
                        <div class="d-flex">
                            <h1 class="text-body-title mr-1">Cliente:</h1>
                            <h1 class="text-body-subtitle">{{work.client.full_name}}</h1>
                        </div>
                        <div class="d-flex">
                            <h1 class="text-body-title mr-1">CI/NIT:</h1>
                            <h1 class="text-body-subtitle">{{work.client.ci_nit}}</h1>
                        </div>
                    </div>
                    <v-divider class="mt-1"></v-divider>
                    <div class="mt-2">
                        <div class="d-flex justify-cente pl-2 pr-2">
                            <table style="width:100%" class="s-table-fee-type animation-opacity" border="0">
                                <thead>
                                    <th>Concepto</th>
                                    <th>Deuda</th>
                                    <th :width="viewTable ? '220px' : '120px'">Monto a Cobrar</th>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(payment, index) in listDetailMoneyFilter"
                                        :key="index"
                                    >
                                        <td class="s-table-fee-type__border-line">
                                            <v-icon
                                                :x-small="viewTable ? false : true"
                                                color="green" v-if="payment.select"
                                            >mdi-check-circle</v-icon>
                                            {{payment.honorary==1 ? 'Honorario' : payment.tax}}</td>
                                        <td class="s-table-fee-type__border-line text-right">
                                            {{payment.debt.toFixed(2)}}</td>
                                        <td class="s-table-fee-type__border-line d-flex justify-center">
                                            <MTextFieldComp
                                                :style="viewTable
                                                    ? 'width:180px; max-width:180px;'
                                                    : 'width:100px; max-width:100px;'"
                                                :clearable="true"
                                                v-model="payment.payment_amount"
                                                :counter="10"
                                                class="mr-1"
                                                :hideDetails="true"
                                                :rules="[
                                                    v => !m_rule_emptyField(v) || 'El dato es requerido',
                                                    v => m_rule_numberDecimal(v) || 'El pago debe ser un número',
                                                    v => !m_rule_exceededNumber(v, payment.debt) || 'El pago no debe superar el precio del trabajo',
                                                    v => (v+'').length<=10 || 'El pago no debe exceder los 10 dígitos']"
                                            />
                                            <OBtnHeaderToolTipComp
                                                smsTooltip="Agregar Pago"
                                                icon="mdi-check"
                                                class="mr-1"
                                                @click="ac_addDetailFinalPayments(payment, index)"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </v-card>
        </v-dialog>
        <div class="payments__container-payment">
            <h1>{{totalPayment+' Bs.'}}</h1>
        </div>
        <v-dialog
            v-model="dialogReceipt"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card class="ma-0 pa-0 rounded-0">
                <ODialogHeaderComp
                    title="Últimos 4 recibos"
                    @clickclose="dialogReceipt=false"
                />
                <MNoDataComp
                    smsNotData="SIN RECIBOS"
                    class="ma-1"
                    v-if="receiptList.length==0 && !dialogLoaderDataReceipt"
                />
                <OLoaderDataComp
                    class="pt-2"
                    v-if="dialogLoaderDataReceipt"
                    smsLoaderData="Obteniendo Recibos"
                />
                <div class="d-flex justify-center flex-wrap">
                    <SCardReceiptComp
                        v-for="(receipt, index) in receiptList"
                        :key="index"
                        :receipt="receipt"
                        class="ma-2"
                        @clickprint="ac_showImpressionSheet(receipt)"
                    />
                </div>
            </v-card>
        </v-dialog>
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_cancelConfirmation()"
            @clickyes="ac_performOperation()"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_cancelConfirmation()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Realizando Operación"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            :colorMessage="colorMessage"
            @click="m_dataGeneral_frameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MNoDataComp,
    MTextFieldComp,
    MBtnNormalComp,
    MSelectComp
} from '../../components/molecules'
import {
    OSmsConfirmComp,
    OSmsErrorComp,
    OLoaderOperationComp,
    OLoaderDataComp,
    OSmsActionComp,
    OSearchComp,
    OBtnHeaderToolTipComp,
    ODialogHeaderComp,
    ODialogActionComp,
    OSheetReceiptPaymentComp
} from '../../components/organisms'
import {
    STableClientDebtsComp,
    SCardClientDebtsComp,
    SPaginationComp,
    SCardWorkDebtComp,
    STableWorksDebtComp,
    SCardPaymentComp,
    STableDetailPaymentsComp,
    STablePaymentsComp,
    SCardReceiptComp,
    SCardDetailPaymentsComp,
    SDetailWorkComp
} from '../../components/species'
import {
    dataGeneralComponetsMixin,
    responseServer,
    rulesMixin,
    calculationsWorkMixin,
    userMixin
} from '../../mixins'
import {
    Client,
    Payment,
    DetailPayments
} from '../../models'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        responseServer,
        rulesMixin,
        calculationsWorkMixin,
        userMixin
    ],
    components: {
        MTextFieldComp,
        OSmsConfirmComp,
        OSmsErrorComp,
        OLoaderOperationComp,
        OLoaderDataComp,
        OSmsActionComp,
        OSearchComp,
        OBtnHeaderToolTipComp,
        MLineHeaderComp,
        MNoDataComp,
        STableClientDebtsComp,
        SCardClientDebtsComp,
        SPaginationComp,
        SCardWorkDebtComp,
        STableWorksDebtComp,
        ODialogHeaderComp,
        ODialogActionComp,
        SCardPaymentComp,
        SDetailWorkComp,
        MBtnNormalComp,
        STableDetailPaymentsComp,
        OSheetReceiptPaymentComp,
        MSelectComp,
        STablePaymentsComp,
        SCardReceiptComp,
        SCardDetailPaymentsComp
    },
    data: () => ({
        valid: true,
        viewTable: true,
        listClients: [],
        page: 0,
        last_page: 0,
        searchClient: null,
        tab: 0,
        dataClient: {
            list_works: []
        },
        work: {},
        dialogDetailWork: false,
        dialogCancelled: false,
        indexClient: -1,
        itemsTypeHonorary: [{
            id: 0,
            fee: 'Todos'
        }],
        type_honorary: 0,
        dialogLoaderDataDetailPayment: false,
        detailPayments: [],
        listDetailPayments: [],
        dialogLoaderDataPayments: false,
        listPayments: [],
        listDetailMoney: [],
        receiptList: [],
        dialogLoaderDataReceipt: false,
        dialogReceipt: false,
        receipt: {}
    }),
    computed: {
        searchFilter: function() {
            if (this.searchClient === null) return ''
            else return this.searchClient
        },
        list_worksFilterByFee: function() {
            if (this.type_honorary === 0) return this.dataClient.list_works
            else return this.dataClient.list_works.filter(work => {
                return work.fee.id === this.type_honorary
            })
        },
        totalPayment: function() {
            var total = 0
            for (var i = 0; i < this.detailPayments.length; i++) {
                total += parseFloat(this.detailPayments[i].payment_amount)
            }
            return total
        },
        listDetailMoneyFilter: function() {
            return this.listDetailMoney.filter(money => {
                return money.cancelled === 0
            })
        },
        totalDebt: function() {
            var total = 0
            for(var i=0; i<this.detailPayments.length; i++) {
                total += parseFloat(this.detailPayments[i].debt)
            }
            return total
        },
        totalSaldo: function() {
            return this.totalDebt-this.totalPayment
        }
    },
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_enableActionBtn(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        ac_cancelConfirmation() {
            if (this.noAccess) {
                localStorage.removeItem('agapeconsultores')
                this.$router.replace('/login')
            } else {
                this.dialogConfirm = false
                this.dialogError = false
            }
        },
        getListClient(page, searchClient) {
            this.dialogLoaderData = true
            Client.getListClientsDebts(page, searchClient).then(response => {
                this.listClients = response.data
                this.page = response.current_page
                this.last_page = response.last_page
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_searchClient() {
            if (!this.m_rule_emptyField(this.searchClient))
                this.getListClient(1, this.searchClient)
            else this.searchClient = ''
        },
        ac_changePage(state) {
            this.m_dataGeneral_frameTimeReset()
            if (state) { ++this.page }
            else { --this.page }
            this.getListClient(this.page, this.searchClient)
        },
        ac_showWorksClient(client) {
            this.detailPayments = []
            this.generateItemsTypeHonoraries(client.list_works)
            this.indexClient = this.listClients.indexOf(client)
            this.dataClient = client
            this.tab = 1
        },
        ac_totalDebts() {
            var totalDebts = 0
            for(var i = 0; i < this.dataClient.list_works.length; i++) {
                const totalHonorary = this.m_calculation_totalHonorary(this.dataClient.list_works[i], 1)
                const totalCancelledHonorary = this.m_calculation_totalPaymentsHonorary(this.dataClient.list_works[i], 1)
                const totalTaxs = this.m_calculation_totalHonorary(this.dataClient.list_works[i], 0)
                const totalCancelledTaxs = this.m_calculation_totalPaymentsHonorary(this.dataClient.list_works[i], 0)
                totalDebts += (totalHonorary-totalCancelledHonorary)+(totalTaxs-totalCancelledTaxs)
            }
            return totalDebts
        },
        ac_showListPayments(work) {
            this.work = work
            this.tab = 2
            this.dialogLoaderDataPayments = true
            Payment.getListPaymentsWork(work.id).then(response => {
                this.listPayments = response
                this.dialogLoaderDataPayments = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_showDetailWork(work) {
            this.work = work
            this.dialogDetailWork = true
        },
        showEvent(message, color) {
            this.colorMessage = color
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        ac_backPreviousList() {
            if (this.tab === 3) {
                if (this.dataClient.full_name !== undefined) this.tab = 1
                else this.tab = 0
            } else {
                --this.tab
            }
        },
        ac_showDialogCancelled(work) {
            this.listDetailMoney = []
            for(var i = 0; i < work.detail_money.length; i++) {
                var totalCancelled = 0
                for (var k = 0; k < work.detail_money[i].payments.length; k++) {
                    totalCancelled += work.detail_money[i].payments[k].payment
                }
                this.listDetailMoney.push({
                    'select': false,
                    'cancelled': work.detail_money[i].cancelled,
                    'date': work.detail_money[i].date,
                    'honorary': work.detail_money[i].honorary,
                    'hours': work.detail_money[i].hours,
                    'id': work.detail_money[i].id,
                    'payments': work.detail_money[i].payments,
                    'price': work.detail_money[i].price,
                    'tax': work.detail_money[i].tax,
                    'payment_amount': 0,
                    'period': work.period!=null ? work.period : 'S/P',
                    'concept': work.detail_money[i].honorary == 1 ? 'Honorario' : work.detail_money[i].tax,
                    'debt': work.detail_money[i].price-totalCancelled
                })
            }
            this.m_dataGeneral_frameTimeReset()
            this.work = work
            this.dialogCancelled = true
        },
        validPaymentAmount(payment) {
            if (this.m_rule_emptyField(payment.payment_amount)) return false
            if (!this.m_rule_numberDecimal(payment.payment_amount)) return false
            if (parseInt(payment.payment_amount) > parseInt(payment.debt)) return false
            if (parseInt(payment.payment_amount) == 0) return false
            return true
        },
        thereIsPayment(id) {
            for(var i = 0; i < this.detailPayments.length; i++) {
                if (this.detailPayments[i].id === id) return true
            }
            return false
        },
        ac_addDetailFinalPayments(payment, index) {
            this.m_dataGeneral_frameTimeReset()
            const idExistInDetail = this.thereIsPayment(payment.id)
            const smsNamePayment = payment.honorary == 1 ? 'Honorario' : payment.tax
            if (this.validPaymentAmount(payment) && !idExistInDetail) {
                this.listDetailMoney[index].select = true
                payment.id_work = this.work.id
                this.detailPayments.push(payment)
                setTimeout(() => {
                    this.showEvent([`El pago de ${payment.payment_amount} Bs.`,
                        `por ${smsNamePayment} fue agregado`], 'success')
                }, 200)
            } else {
                var smsError = ['El pago debe ser un número mayor a 0', `y menor a la deuda`]
                if (idExistInDetail) smsError = [`El pago por ${smsNamePayment}, ya se`,
                `encuentra agregado`]
                setTimeout(() => {
                    this.showEvent(smsError, 'error')
                }, 200)
            }
        },
        ac_deletePayment(index) {
            this.detailPayments.splice(index, 1)
        },
        validDetailPayments() {
            for(var i=0; i < this.detailPayments.length; i++) {
                if (!this.validPaymentAmount(this.detailPayments[i])) return false
            }
            return true
        },
        ac_confirmDetailPayments() {
            if (this.detailPayments.length > 0) {
                if (this.validDetailPayments()) {
                    this.smsConfirm = ['¿Está seguro de registrar los pagos del cliente:',
                        `${this.dataClient.full_name}?`]
                    this.dialogConfirm = true
                } else {
                    this.smsError = ['Corrija los pagos marcados con rojo porfavor']
                    this.actionError = true
                    this.dialogError = true
                }
            } else {
                this.m_dataGeneral_frameTimeReset()
                setTimeout(() => {
                    this.showEvent(['Operación incorrecta',
                        `Ingrese pagos al detalle`], 'error')
                }, 200)
            }
        },
        generateItemsTypeHonoraries(works) {
            this.itemsTypeHonorary = [{
                'id': 0,
                'fee': 'Todos'
            }]
            for (var i=0; i < works.length; i++) {
                const existingList = this.itemsTypeHonorary.filter(type_honorary => {
                    return type_honorary.id === works[i].fee.id
                })
                if (existingList.length === 0) {
                    this.itemsTypeHonorary.push({
                        'id': works[i].fee.id,
                        'fee': works[i].fee.fee
                    })
                }
            }
        },
        generateParametersFinal() {
            var parameters = []
            this.detailPayments.forEach(payment => {
                parameters.push({
                    'payment': parseFloat(payment.payment_amount),
                    'pre_balance': payment.debt,
                    'fkdetailmoney': payment.id,
                    'cancelled': payment.debt-parseFloat(payment.payment_amount)==0 ? 1 : 0,
                    'id_work': payment.id_work
                })
            })
            return parameters
        },
        ac_performOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            const parameters = {
                detail: this.generateParametersFinal(),
                id_client: this.dataClient.id
            }
            Payment.addPayments(parameters).then(response => {
                Object.assign(this.listClients[this.indexClient], response.client)
                this.receipt = response.receipt
                this.detailPayments = []
                this.tab = 0
                this.dialogLoaderOperation = false
                this.showEvent(['Operación correcta',
                    `Se registraron los pagos`], 'success')
                if (window.innerWidth > 600) {
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.print(this.$refs.sheetpayments);
                        }, 500)
                    })
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_showDialgogLastFiveReceipts() {
            this.receiptList = []
            this.dialogReceipt = true
            this.dialogLoaderDataReceipt = true
            DetailPayments.getLastFiveReceipt().then(response => {
                this.receiptList = response
                this.dialogLoaderDataReceipt = false
            }).catch(error => {
                this.processError(error)
            })
        },
        print(element) {
            let printSection = document.getElementById('printSection')
            var domClone = element.cloneNode(true)
            var nodoImg = document.createElement("img")
            nodoImg.setAttribute("src", '/imgs/logo_print.jpg')
            nodoImg.onload = function() {
                printSection.appendChild(domClone)
                window.print()
                printSection.innerHTML = ""
            }
        },
        ac_showImpressionSheet(receipt) {
            this.receipt = receipt
            if (window.innerWidth <= 600) {
                this.showEvent(['Operación Incorrecta',
                    `Solo se puede imprimir en PC`], 'error')
            } else {
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.print(this.$refs.sheetpayments);
                    }, 500)
                })
            }
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (!this.m_user_saveToken()) this.$router.replace('/login')
    }
}
</script>