<template>
    <v-tooltip bottom color="secondary">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                fab
                v-bind="attrs"
                v-on="on"
                :ripple="false"
                x-small
                :color="color"
                :disabled="disabled"
                depressed
                @click="$emit('click')"
            >
                <slot></slot>
            </v-btn>
        </template>
        <div class="d-flex align-center">
            <v-icon class="mr-1" color="inverse">mdi-hand-pointing-right</v-icon>
            <h1 class="text-body-subtitle-inverse">{{smsTooltip}}</h1>
        </div>
    </v-tooltip>
</template>
<script>
export default {
    props: {
        smsTooltip: String,
        color: String,
        disabled: Boolean
    }
}
</script>