<template>
    <div>
        <div class="clients__header">
            <div class="clients__header-first">
                <h1 class="text-body-title">TARIFAS</h1>
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    <OSearchComp
                        label="Nombre o CI/NIT"
                        class="history-client__search"
                        v-model="search"
                        @search="ac_searchRate()"
                    />
                    <OBtnHeaderToolTipComp
                        v-if="tab!=1"
                        smsTooltip="Registrar nueva tarifa"
                        icon="mdi-plus"
                        class="ml-1"
                        @click="tab=1"
                    />
                    <OBtnHeaderToolTipComp
                        v-if="tab!=0"
                        smsTooltip="Volver a la lista de tarifas"
                        icon="mdi-database"
                        class="ml-1"
                        @click="ac_backListRates()"
                    />
                </div>
            </div>
        </div>
        <MLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <OLoaderDataComp
                    v-if="dialogLoaderData"
                    class="mt-2"
                    smsLoaderData="Obteniendo Tarifas"
                />
                <MNoDataComp
                    smsNotData="SIN TARIFAS"
                    class="ma-1"
                    v-if="listRates.length==0 && !dialogLoaderData && !viewTable"
                />
                <div class="d-flex justify-center mt-3" v-if="viewTable">
                    <STableRatesComp
                        v-if="!dialogLoaderData"
                        :listRates="listRates"
                        @clickupdate="ac_openFormUpdate"
                        @clickdelete="ac_confirmDeleteRate"
                    />
                </div>
                <div class="d-flex justify-center" v-else>
                    <div
                        v-if="!dialogLoaderData"
                        class="d-flex justify-center flex-wrap"
                        style="width:100%;"
                    >
                        <SCardRateComp
                            v-for="(rate, index) in listRates"
                            :key="index"
                            :rate="rate"
                            class="ma-2"
                            @clickupdate="ac_openFormUpdate(rate)"
                            @clickdelete="ac_confirmDeleteRate(rate)"
                        />
                    </div>
                </div>
                <SPaginationComp
                    class="mt-2"
                    v-if="!dialogLoaderData && listRates.length>0"
                    :condicionGo="page < last_page"
                    :condicionBack="page > 1"
                    :titlePagination="'Pag. '+page+' de '+last_page"
                    @clickback="ac_changePage(false)"
                    @clickgo="ac_changePage(true)"
                />
            </v-tab-item>
            <v-tab-item>
                <h1 class="text-body-title text-center mt-2">
                    {{indexRate!=-1 ? 'FORMULARIO DE EDICIÓN' : 'FORMULARIO DE REGISTRO'}}</h1>
                <div class="d-flex justify-center works__form-container">
                    <v-form
                        ref="formrate"
                        v-model="valid"
                        lazy-validation
                        class="works__form-work mt-3 mb-3"
                    >
                        <div :class="errorSelectClient ? 'd-flex pb-2' : 'd-flex pb-6'">
                            <div
                                v-if="viewTable"
                                class="expenses__title-form"
                            >
                                <h1 class="text-body-title text-right mr-1 pt-2">
                                    Cliente:</h1>
                            </div>
                            <div class="d-flex" :style="viewTable ? 'width:300px' : 'width:100%'">
                                <div style="width:100%" class="mr-1 works__client-form">
                                    <div :class="errorSelectClient ? 'works__client-form--danger' : 'works__client-form--normal'">
                                        <h1 class="text-body-subtitle">
                                            {{dataRate.client.full_name!=null ? dataRate.client.full_name : 'Sin cliente'}}</h1>
                                        <!--<h2>Cliente</h2>-->
                                    </div>
                                    <p class="mb-0" v-if="errorSelectClient">El cliente es requerido</p>
                                </div>
                                <OBtnHeaderToolTipComp
                                    v-if="indexRate==-1"
                                    smsTooltip="Añadir cliente"
                                    icon="mdi-plus"
                                    style="margin-top:2px"
                                    @click="dialogClient=true"
                                />
                            </div>
                        </div>
                        <div class="d-flex mt-2">
                            <div
                                v-if="viewTable"
                                class="expenses__title-form"
                            >
                                <h1 class="text-body-title text-right mr-1 pt-2">Tipo Trabajo:</h1>
                            </div>
                            <MSelectComp
                                :singleLine="viewTable ? true : false"
                                :label="viewTable ? '' : 'Tipo Trabajo'"
                                :style="viewTable ? 'width:300px; max-width:300px;' : ''"
                                :items="listFeeTypeFilter"
                                v-model="dataRate.fkfeetype"
                                itemValue="id"
                                itemText="fee"
                                :counter="10"
                                :rules="[v => !m_rule_emptyField(v) || 'La dato es requerido']"
                            />
                        </div>
                        <div class="d-flex mt-2">
                            <div
                                v-if="viewTable"
                                class="expenses__title-form"
                            >
                                <h1 class="text-body-title text-right mr-1 pt-2">Tarifa:</h1>
                            </div>
                            <MTextFieldComp
                                :singleLine="viewTable ? true : false"
                                :label="viewTable ? '' : 'Tarifa'"
                                :style="viewTable ? 'width:300px; max-width:300px;' : ''"
                                v-model="dataRate.rate"
                                :counter="10"
                                :rules="[
                                    v => !m_rule_emptyField(v) || 'La tarifa es requerida',
                                    v => m_rule_numberDecimal(v) || 'La tarifa debe ser un número',
                                    v => (v+'').length<=10 || 'La tarifa no debe exceder los 10 dígitos']"
                            />
                        </div>
                        <div class="d-flex">
                            <div
                                v-if="viewTable"
                                class="expenses__title-form"
                            >
                                <h1 class="text-body-title text-right mr-1 pt-2">
                                    Descripción:</h1>
                            </div>
                            <v-textarea
                                :singleLine="viewTable ? true : false"
                                :label="viewTable ? '' : 'Descripción'"
                                :style="viewTable ? 'width:300px; max-width:300px;' : ''"
                                v-model="dataRate.description"
                                outlined
                                class="mt-2"
                                height="90"
                                clearable
                                counter="100"
                                :rules="[v => (v+'').length<=100 || 'La descripción no puede ser mayor a los 100 dígitos']"
                            ></v-textarea>
                        </div>
                        <div class="d-flex justify-center mb-1">
                            <MBtnNormalComp
                                :outlined="true"
                                color="primary"
                                @click="ac_confirmAddRate()"
                                class="mr-1"
                            >{{indexRate!=-1 ? 'GUARDAR' : 'REGISTRAR'}}</MBtnNormalComp>
                        </div>
                    </v-form>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog
            v-model="dialogClient"
            persistent
            width="500"
            content-class="dialog--margin"
        >
            <v-card class="ma-0 pa-0">
                <ODialogHeaderComp
                    title="Ingresar Cliente"
                    @clickclose="ac_closeFormClient(true)"
                />
                <div class="d-flex align-center pa-2">
                    <OBtnHeaderToolTipComp
                        v-if="!optionFormClient"
                        smsTooltip="Nuevo cliente"
                        icon="mdi-plus"
                        @click="optionFormClient=true"
                    />
                    <OBtnHeaderToolTipComp
                        v-if="optionFormClient"
                        smsTooltip="Volver a la lista de clientes"
                        icon="mdi-arrow-left-bold"
                        @click="optionFormClient=false"
                    />
                    <OSearchComp
                        v-else
                        v-model="searchClient"
                        label="CI/NIT - Nombre"
                        class="ml-1"
                        @search="ac_seachClient(1)"
                    />
                </div>
                <v-divider></v-divider>
                <div class="pa-2 pt-4" v-if="optionFormClient">
                    <SFormClientComp
                        :client="dataClient"
                        :listTypeCompany="listTypeCompany"
                        @clickaction="ac_addNewClient"
                    />
                </div>
                <div class="works__list-clients mt-2" v-else>
                    <MNoDataComp
                        smsNotData="SIN CLIENTES"
                        class="ma-1"
                        v-if="listClients.length==0 && !dialogLoaderDataClient"
                    />
                    <OLoaderDataComp
                        v-if="dialogLoaderDataClient"
                        class="mt-2"
                        smsLoaderData="Obteniendo Clientes"
                    />
                    <div
                        v-for="(client, index) in listClients"
                        :key="index"
                        class="works__client-selection animation-opacity ma-2"
                        @click="ac_selectedClient(client)"
                    >
                        <div class="d-flex">
                            <h1 class="text-body-subtitle">
                            CI/NIT: {{client.ci_nit}}</h1>
                            <div style="heigth:100%; width:5px; background:black; border-radius:3px;" class="mr-1 ml-1"></div>
                            <h1 class="text-body-subtitle">{{client.full_name}}</h1>
                        </div>
                    </div>
                    <SPaginationComp
                        class="mt-2"
                        v-if="!dialogLoaderDataClient && listClients.length>0"
                        :condicionGo="page_client < last_page_client"
                        :condicionBack="page_client > 1"
                        :titlePagination="'Pag. '+page_client+' de '+last_page_client"
                        @clickback="ac_changePageClient(false)"
                        @clickgo="ac_changePageClient(true)"
                    />
                </div>
            </v-card>
        </v-dialog>
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_closeSmsError()"
            @clickyes="ac_performOperation()"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Realizando Operación"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MTextFieldComp,
    MSelectComp,
    MNoDataComp,
    MBtnNormalComp
} from '../../components/molecules'
import {
    OSearchComp,
    OBtnHeaderToolTipComp,
    OLoaderDataComp,
    ODialogHeaderComp,
    ODialogActionComp,
    OSmsConfirmComp,
    OSmsErrorComp,
    OLoaderOperationComp,
    OSmsActionComp
} from '../../components/organisms'
import {
    STableRatesComp,
    SPaginationComp,
    SFormClientComp,
    SCardRateComp
} from '../../components/species'
import { Rate, FeeType, Client, TypeCompany } from '../../models'
import {
    responseServer,
    dataGeneralComponetsMixin,
    rulesMixin,
    userMixin
} from '../../mixins'
export default {
    mixins: [
        responseServer,
        dataGeneralComponetsMixin,
        rulesMixin,
        userMixin
    ],
    components: {
        MLineHeaderComp,
        OSearchComp,
        OBtnHeaderToolTipComp,
        OLoaderDataComp,
        STableRatesComp,
        MTextFieldComp,
        MSelectComp,
        ODialogHeaderComp,
        ODialogActionComp,
        SPaginationComp,
        MNoDataComp,
        SFormClientComp,
        MBtnNormalComp,
        OSmsConfirmComp,
        OSmsErrorComp,
        OLoaderOperationComp,
        OSmsActionComp,
        SCardRateComp
    },
    data: () => ({
        viewTable: true,
        search: '',
        listRates: [],
        page: 0,
        last_page: 0,
        tab: 0,
        valid: true,
        indexRate: -1,
        dataRate: new Rate(),
        listFeeType: [{ id: 0, fee: 'Todos' }],
        errorSelectClient: false,
        dialogClient: false,
        searchClient: '',
        optionFormClient: false,
        listClients: [],
        page_client: 0,
        last_page_client: 0,
        dialogLoaderDataClient: false,
        validClient: true,
        dataClient: new Client(),
        isDelete: false,
        idRate: 0,
        listTypeCompany: []
    }),
    computed: {
        listFeeTypeFilter: function() {
            return this.listFeeType.filter(fee => {
                return fee.id !== 0
            })
        }
    },
    methods: {
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('agapeconsultores')
                this.$router.replace('/login')
            } else {
                if (this.isDelete) {
                    this.indexRate = -1
                    this.isDelete = false
                }
                this.dialogConfirm = false
                this.dialogError = false
            }
        },
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_enableActionBtn(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        getRequest() {
            this.dialogLoaderData = true
            const getRates = Rate.getList(1, '')
            const getFeeTypes = FeeType.getListFeeType()
            const getListTypeCompany = TypeCompany.getListTypeCompany()
            Promise.all([getRates, getFeeTypes, getListTypeCompany]).then(responses => {
                responses[1].forEach(fee => {
                    this.listFeeType.push(fee)
                })
                this.listRates = responses[0].data
                this.page = responses[0].page
                this.last_page = responses[0].last_page
                this.listTypeCompany = responses[2]
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        getListRates(page, search) {
            this.listRates = []
            this.dialogLoaderData = true
            Rate.getList(page, search).then(response => {
                this.listRates = response.data
                this.page = response.page
                this.last_page = response.last_page
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_searchRate() {
            this.tab = 0
            if (this.search == null) this.search = ''
            this.getListRates(this.page, this.search)
        },
        ac_backListRates() {
            this.tab = 0
            setTimeout(() => {
                this.dataRate = new Rate()
                this.$refs.formrate.resetValidation()
                this.indexRate = -1
            }, 200)
        },
        ac_addNewClient(state_form) {
            this.dataRate.client = this.dataClient
            this.dataRate.new_client = 1
            this.ac_closeFormClient(state_form)
        },
        ac_closeFormClient(errorSelectClient) {
            this.dialogClient = false
            if (errorSelectClient) {
                if (this.dataRate.client.id === null) this.errorSelectClient = true
            } else this.errorSelectClient = false
            setTimeout(() => {
                this.listClients = []
                this.page_client = 0
                this.last_page_client = 0
                this.searchClient = ''
                this.optionFormClient = false
                this.dataClient = new Client()
            }, 200)
        },
        ac_seachClient(page) {
            this.listClients = []
            this.dialogLoaderDataClient = true
            Client.getListClients(page, this.searchClient, 0).then(response => {
                this.listClients = response.data
                this.page_client = response.current_page
                this.last_page_client = response.last_page
                this.dialogLoaderDataClient = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_selectedClient(client) {
            this.dataRate.client = Object.assign({}, client)
            this.dataRate.new_client = 0
            this.ac_closeFormClient(false)
        },
        validateFormRate() {
            if (!this.$refs.formrate.validate()) return false
            return true
        },
        activateSmsError(smsError) {
            this.smsError = [smsError]
            this.actionError = true
            this.dialogError = true
        },
        ac_changePageClient(state) {
            if (state) { ++this.page_client }
            else { --this.page_client }
            this.ac_seachClient(this.page_client)
        },
        ac_confirmAddRate() {
            window.scroll(0, 0)
            setTimeout(() => {
                if (!this.validateFormRate()) {
                    if (this.dataRate.client.full_name === null) {
                        this.errorSelectClient = true
                    }
                    this.activateSmsError('Revise los datos marcados con rojo y corrija porfavor')
                } else {
                    const smsOperacion = this.indexRate == -1 ? 'registrar' : 'actualizar'
                    this.smsConfirm = [`¿Está seguro de ${smsOperacion} la tarifa para:`,
                        `${this.dataRate.client.full_name}`]
                    this.dialogConfirm = true
                }
            }, 100)
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        addRate() {
            Rate.addRate(this.dataRate).then(response => {
                this.listRates.unshift(response)
                this.dialogLoaderOperation = false
                this.ac_backListRates()
                this.showEvent(['Operación exitosa', 'Se registró la tarifa'])
            }).catch(error => {
                this.processError(error)
            })
        },
        updateRate() {
            Rate.updateRate(this.dataRate).then(response => {
                Object.assign(this.listRates[this.indexRate], response)
                this.dialogLoaderOperation = false
                this.ac_backListRates()
                this.showEvent(['Operación exitosa', 'Se actualizó la tarifa'])
            }).catch(error => {
                this.processError(error)
            })
        },
        deleteRate() {
            Rate.deleteRate({id: this.idRate}).then(() => {
                this.isDelete = false
                this.listRates.splice(this.indexRate, 1)
                this.dialogLoaderOperation = false
                this.indexRate = -1
                this.showEvent(['Operación exitosa', 'Se eliminó la tarifa'])
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_performOperation() {
            this.dialogConfirm  = false
            this.dialogLoaderOperation = true
            if (this.isDelete) this.deleteRate()
            else {
                if (this.indexRate !== -1) this.updateRate()
                else this.addRate()
            }
        },
        ac_openFormUpdate(rate) {
            this.dataRate = Object.assign({}, rate)
            this.indexRate = this.listRates.indexOf(rate)
            this.tab = 1
        },
        ac_confirmDeleteRate(rate) {
            this.idRate = rate.id
            this.isDelete = true
            this.indexRate = this.listRates.indexOf(rate) 
            this.smsConfirm = ['¿Está seguro de eliminar la tarifa de:',
                `${rate.client.full_name}?`]
            this.dialogConfirm = true
        },
        ac_changePage(state) {
            if (state) { ++this.page }
            else { --this.page }
            this.getListRates(this.page, this.search)
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            this.getRequest()
        } else this.$router.replace('/login')
    }
}
</script>