<template>
    <div>
        <div class="pa-2 o-header-work-payments" ><!--v-if="work.client!=undefined"-->
            <div class="o-header-work-payments__first">
                <div class="d-flex">
                    <h1 class="text-body-title">Expresado en:</h1>
                    <h1 class="text-body-subtitle ml-1">Bolivianos</h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-title">Cliente:</h1>
                    <h1 class="text-body-subtitle ml-1">{{work.client.full_name}}</h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-title">Tipo Trabajo:</h1>
                    <h1 class="text-body-subtitle ml-1">{{work.fee.fee}}</h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-title">Periodo:</h1>
                    <h1 class="text-body-subtitle ml-1">{{work.period!=null ? work.period : 'S/P'}}</h1>
                </div>
            </div>
            <div>
                <div class="d-flex align-center">
                    <h1 class="text-body-title mr-1">Trabajo Cancelado:</h1>
                    <h1 class="text-body-subtitle mr-1">
                        {{work.cancelled==1 ? 'SI' : 'NO'}}
                    </h1>
                    <v-icon color="green" v-if="work.cancelled==1">mdi-check-circle</v-icon>
                    <v-icon color="red" v-else>mdi-account-cash</v-icon>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-title">Total Precio:</h1>
                    <h1 class="text-body-subtitle ml-1">
                        {{m_calculation_totalCost(work).toFixed(2)}}
                    </h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-title">Cancelado:</h1>
                    <h1 class="text-body-subtitle ml-1">
                        {{m_calculation_totalCancelled(work).toFixed(2)}}</h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-title">Por Cobrar:</h1>
                    <h1 class="text-body-subtitle ml-1">
                        {{ac_totalDebt(work).toFixed(2)}}</h1>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { calculationsWorkMixin } from '../../mixins'
export default {
    mixins: [ calculationsWorkMixin ],
    props: {
        work: {}
    },
    methods: {
        ac_totalDebt(work) {
            const debt = this.m_calculation_totalCost(work)-this.m_calculation_totalCancelled(work)
            return debt
        }
    }
}
</script>