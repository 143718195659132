<template>
    <div>
        <div class="pa-2 d-flex align-center">
            <h1 class="text-body-title">REPORTE DE GASTOS</h1>
            <v-spacer></v-spacer>
            <OBtnHeaderToolTipComp
                smsTooltip="Generar Reporte PDF"
                icon="mdi-file-pdf-box"
                class="ml-1"
                classBtn="error"
                @click="ac_generateReport()"
            />
        </div>
        <MLineHeaderComp />
        <div class="pa-2">
            <div class="d-flex">
                <h1 class="text-body-title mr-1">Expresado en:</h1>
                <h1 class="text-body-subtitle">Bolivianos</h1>
            </div>
            <div class="d-flex">
                <h1 class="text-body-title mr-1">Cantidad de Registros:</h1>
                <h1 class="text-body-subtitle">{{quantity_expenses}}</h1>
            </div>
            <div class="d-flex">
                <h1 class="text-body-title mr-1">Total Gastos:</h1>
                <h1 class="text-body-subtitle">{{total_expenses.toFixed(2)}}</h1>
            </div>
        </div>
        <v-divider></v-divider>
        <div class="expenses__submenu">
            <div class="d-flex align-center">
                <MSelectComp
                    label="Tipo Gasto"
                    :hideDetails="true"
                    class="expenses__submenu-first"
                    :items="itemsTransients"
                    v-model="transient"
                    itemValue="value"
                    itemText="title"
                    @change="ac_searchTransient"
                />
                <MSelectComp
                    label="Categoría Gasto"
                    :hideDetails="true"
                    :items="listTypeExpenses"
                    v-model="idTypeExpenses"
                    itemValue="id"
                    itemText="type_expense"
                    class="expenses__submenu-first ml-1"
                    @change="ac_searchTypeExpense"
                />
            </div>
            <ODualDatesComp
                class="expenses__submenu-date"
                @onChangingDate="ac_searchDates"
            />
        </div>
        <v-divider id="position-scroll" ></v-divider>
        <OLoaderDataComp
            v-if="dialogLoaderData"
            class="mt-2"
            smsLoaderData="Obteniendo Lista"
        />
        <MNoDataComp
            smsNotData="SIN DATOS"
            class="ma-1"
            v-if="listExpenses.length==0 && !dialogLoaderData && !viewTable"
        />
        <div class="d-flex justify-center mt-3" v-if="viewTable">
            <STableExpenseComp
                v-if="!dialogLoaderData"
                :listExpenses="listExpenses"
                :itsJustInformation="true"
            />
        </div>
        <div class="d-flex justify-center flex-wrap mt-1" v-else>
            <SCardExpenseComp
                :itsJustInformation="true"
                v-for="(expense, index) in listExpenses"
                :key="index"
                :expense="expense"
                class="ma-2"
            />
        </div>
        <SPaginationComp
            class="mt-2"
            v-if="!dialogLoaderData && listExpenses.length>0"
            :condicionGo="page < last_page"
            :condicionBack="page > 1"
            :titlePagination="'Pag. '+page+' de '+last_page"
            @clickback="ac_changePage(false)"
            @clickgo="ac_changePage(true)"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="true"
            @click="ac_closeSmsError()"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            colorMessage="error"
            @click="m_dataGeneral_frameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MSelectComp,
    MNoDataComp
} from '../../components/molecules'
import {
    OBtnHeaderToolTipComp,
    ODualDatesComp,
    OLoaderDataComp,
    OSmsErrorComp,
    OSmsActionComp
} from '../../components/organisms'
import {
    STableExpenseComp,
    SCardExpenseComp,
    SPaginationComp
} from '../../components/species'
import {
    TypeExpense,
    Expense
} from '../../models'
import {
    dataGeneralComponetsMixin,
    responseServer,
    rulesMixin,
    datesMixin,
    userMixin
} from '../../mixins'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        responseServer,
        rulesMixin,
        datesMixin,
        userMixin
    ],
    components: {
        OBtnHeaderToolTipComp,
        MLineHeaderComp,
        MSelectComp,
        ODualDatesComp,
        STableExpenseComp,
        SCardExpenseComp,
        SPaginationComp,
        OLoaderDataComp,
        MNoDataComp,
        OSmsErrorComp,
        OSmsActionComp
    },
    data: () => ({
        viewTable: true,
        listTypeExpenses: [{
            type_expense: 'Todos',
            id: 0
        }],
        idTypeExpenses: 0,
        itemsTransients: [
            { title: 'Todos', value: 2 },
            { title: 'Transitorio', value: 1 },
            { title: 'No Transitorio', value: 0 }
        ],
        transient: 2,
        page: 0,
        last_page: 0,
        listExpenses: [],
        quantity_expenses: 0,
        total_expenses: 0,
        date1: '',
        date2: ''
    }),
    methods: {
        processError(error) {
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_enableActionBtn(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('agapeconsultores')
                this.$router.replace('/login')
            } else this.dialogError = false
        },
        getRequest() {
            this.dialogLoaderData = true
            const getListTypeExpenses = TypeExpense.getListTypeExpenses()
            const getListExpensesReport = Expense.getListExpensesReport(1, 0, '','',2)
            Promise.all([getListTypeExpenses, getListExpensesReport]).then(responses => {
                responses[0].forEach(element => {
                    this.listTypeExpenses.push(element)
                });
                this.listExpenses = responses[1].data
                this.page = responses[1].current_page
                this.last_page = responses[1].last_page
                this.total_expenses = responses[1].total_expenses
                this.quantity_expenses = responses[1].quantity
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        getListExpenses(page, idTypeExpenses, date1, date2, transient, topPos) {
            this.listExpenses = []
            this.dialogLoaderData = true
            Expense.getListExpensesReport(page, idTypeExpenses, date1, date2, transient).then(response => {
                this.listExpenses = response.data
                this.page = response.current_page
                this.last_page = response.last_page
                this.total_expenses = response.total_expenses
                this.quantity_expenses = response.quantity
                this.dialogLoaderData = false
                if (window.innerWidth <= 600) {
                    setTimeout(() => {
                        window.scroll(0, topPos)
                    }, 150)
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_searchTransient(transient) {
            this.getListExpenses(1, this.idTypeExpenses, this.date1, this.date2, transient)
        },
        ac_searchTypeExpense(value) {
            this.getListExpenses(1, value, this.date1, this.date2, this.transient)
        },
        ac_searchDates(dates = null) {
            this.date1 = dates ? dates.date1 : ''
            this.date2 = dates ? dates.date2 : ''
            setTimeout(()=> {
                this.getListExpenses(1, this.idTypeExpenses, this.date1, this.date2, this.transient)
            }, 200)
        },
        ac_changePage(state) {
            var myElement = document.getElementById('position-scroll');
            var topPos = parseInt(myElement.offsetTop) - 45;
            if (state) { ++this.page }
            else { --this.page }
            if (window.innerWidth <= 600) {
                window.scroll(0, 0)
                setTimeout(() => {
                    this.getListExpenses(this.page, this.idTypeExpenses, this.date1, this.date2, this.transient, topPos)
                }, 100)
            } else this.getListExpenses(this.page, this.idTypeExpenses, this.date1, this.date2, this.transient)
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        ac_generateReport() {
            if (this.listExpenses.length > 0) {
                Expense.reportPdf(this.date1, this.date2, this.transient, this.idTypeExpenses)
            } else {
                this.m_dataGeneral_frameTimeReset()
                setTimeout(() => {
                    this.showEvent(['Operación incorrecta', 'debe haber información para procesar el PDF'])
                }, 200)
            }
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            this.getRequest()
        } else this.$router.replace('/login')
    }
}
</script>