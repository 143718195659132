<template>
    <div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Fecha Actual:</h1>
            <h1 class="text-body-subtitle">{{current_date}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Día Actual:</h1>
            <h1 class="text-body-subtitle">{{current_day}}</h1>
        </div>
    </div>
</template>
<script>
import { datesMixin } from '../../mixins'
export default {
    mixins: [ datesMixin ],
    data: () => ({
        current_date: '',
        current_day: ''
    }),
    mounted() {
        this.current_date = this.m_date_systemDate(true)
        this.current_day = this.m_date_currentDaySystem()
    }
}
</script>