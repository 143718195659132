<template>
    <div>
        <div class="d-flex align-center pa-2">
            <h1 class="text-body-title">GASTOS</h1>
            <v-spacer></v-spacer>
            <div>
                <OBtnHeaderToolTipComp
                    v-if="tab!=1"
                    smsTooltip="Registrar nuevo gasto"
                    icon="mdi-plus"
                    class="ml-1"
                    @click="tab=1"
                />
                <OBtnHeaderToolTipComp
                    v-if="tab!=0"
                    smsTooltip="Volver a la lista de gastos."
                    icon="mdi-database"
                    class="ml-1"
                    @click="ac_backFormExpense()"
                />
            </div>
        </div>
        <MLineHeaderComp />
        <div class="expenses__submenu">
            <div class="d-flex align-center">
                <MSelectComp
                    label="Tipo Gasto"
                    :hideDetails="true"
                    class="expenses__submenu-first"
                    :items="itemsTransients"
                    v-model="transient"
                    itemValue="value"
                    itemText="title"
                    @change="ac_searchTransient"
                />
                <MSelectComp
                    :hideDetails="true"
                    label="Categoría Gasto"
                    class="expenses__submenu-first ml-1"
                    :items="listTypeExpenses"
                    itemValue="id"
                    itemText="type_expense"
                    v-model="searchTypeExpense"
                    @change="ac_changeTypeExpense"
                />
            </div>
            <ODualDatesComp
                class="expenses__submenu-date"
                @onChangingDate="ac_searchDates"
            />
        </div>
        <v-divider class="mt-2"></v-divider>
        <div class="pa-2 o-header-work-payments">
            <div class="o-header-work-payments__first">
                <div class="d-flex">
                    <h1 class="text-body-title mr-1">Expresado en:</h1>
                    <h1 class="text-body-subtitle">Bolivianos</h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-title mr-1">Cantidad de Registros:</h1>
                    <h1 class="text-body-subtitle">{{quantityExpenses}}</h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-title mr-1">Total Gastos:</h1>
                    <h1 class="text-body-subtitle">{{totalExpenses.toFixed(2)}}</h1>
                </div>
            </div>
            <MDataDatesComp />
        </div>
        <v-divider id="position-scroll" ></v-divider>
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <OLoaderDataComp
                    v-if="dialogLoaderData"
                    class="mt-2"
                    smsLoaderData="Obteniendo Lista"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="listExpenses.length==0 && !dialogLoaderData && !viewTable"
                />
                <div class="d-flex justify-center mt-3" v-if="viewTable">
                    <STableExpenseComp
                        v-if="!dialogLoaderData"
                        :listExpenses="listExpenses"
                        @clickupdate="ac_showFormUpdate"
                        @clickdelete="ac_confirmDelete"
                    />
                </div>
                <div class="d-flex justify-center flex-wrap" v-else>
                    <SCardExpenseComp
                        v-for="(expense, index) in listExpenses"
                        :key="index"
                        :expense="expense"
                        class="ma-2"
                        @clickupdate="ac_showFormUpdate(expense)"
                        @clickdelete="ac_confirmDelete(expense)"
                    />
                </div>
                <SPaginationComp
                    class="mt-2"
                    v-if="!dialogLoaderData && listExpenses.length>0"
                    :condicionGo="page < last_page"
                    :condicionBack="page > 1"
                    :titlePagination="'Pag. '+page+' de '+last_page"
                    @clickback="ac_changePage(false)"
                    @clickgo="ac_changePage(true)"
                />
            </v-tab-item>
            <v-tab-item>
                <h1 class="text-body-title text-center mt-2">
                    {{indexExpense!=-1 ? 'FORMULARIO DE EDICIÓN' : 'FORMULARIO DE REGISTRO'}}</h1>
                <div class="d-flex justify-center works__form-container">
                    <v-form
                        ref="formexpenses"
                        v-model="valid"
                        lazy-validation
                        class="works__form-work mt-3 mb-3"
                    >
                        <div class="d-flex">
                            <div
                                v-if="viewTable"
                                class="expenses__title-form"
                            >
                                <h1 class="text-body-title text-right mr-1 pt-2">
                                    Categoría Gasto:</h1>
                            </div>
                            <MSelectComp
                                :singleLine="viewTable ? true : false"
                                :label="viewTable ? '' : 'Categoría Gasto'"
                                :style="viewTable ? 'width:300px; max-width:300px;' : ''"
                                :items="listTypeExpensesFilterForm"
                                itemValue="id"
                                itemText="type_expense"
                                v-model="dataExpense.fktypeexpense"
                                :rules="[v => !m_rule_emptyField(v) || 'El tipo de gasto es requerido']"
                            />
                        </div>
                        <div class="d-flex">
                            <div
                                v-if="viewTable"
                                class="expenses__title-form"
                            >
                                <h1 class="text-body-title text-right mr-1 pt-2">Gasto:</h1>
                            </div>
                            <MTextFieldComp
                                :singleLine="viewTable ? true : false"
                                :label="viewTable ? '' : 'Gasto'"
                                :style="viewTable ? 'width:300px; max-width:300px;' : ''"
                                :clearable="true"
                                v-model="dataExpense.expense"
                                :counter="10"
                                :rules="[
                                    v => !m_rule_emptyField(v) || 'El gasto es requerido',
                                    v => m_rule_numberDecimal(v) || 'El gasto debe ser un número',
                                    v => (v+'').length<=10 || 'El gasto no debe exceder los 10 dígitos']"
                            />
                        </div>
                        <div class="d-flex mt-2">
                            <div
                                v-if="viewTable"
                                class="expenses__title-form">
                                <h1 class="text-body-title text-right mr-1 pt-2">
                                    N° Recibo o Factura:</h1>
                            </div>
                            <MTextFieldComp
                                :singleLine="viewTable ? true : false"
                                :label="viewTable ? '' : 'N° Recibo o Factura'"
                                :style="viewTable ? 'width:300px; max-width:300px;' : ''"
                                :clearable="true"
                                v-model="dataExpense.receipt_number"
                                :counter="30"
                                :rules="[
                                    v => (v+'').length<=30 || 'El N° de recibo/factura no debe exceder los 30 dígitos'
                                ]"
                            />
                        </div>
                        <!--<div class="d-flex mt-2">
                            <div
                                v-if="viewTable"
                                class="expenses__title-form">
                                <h1 class="text-body-title text-right mr-1 pt-2">
                                    Fecha de Gasto:</h1>
                            </div>
                            <ODateOneComp
                                :singleLine="viewTable ? true : false"
                                :label="viewTable ? '' : 'Fecha de Gasto'"
                                :styleDate="viewTable ? 'width:300px; max-width:300px;' : ''"
                                v-model="dateExpense"
                                @change="ac_electionDateExpense"
                                :rules="[ac_validDate() || 'La fecha debe ser menor o igual a la fecha actual']"
                            />
                        </div>-->
                        <div class="d-flex">
                            <div
                                v-if="viewTable"
                                class="expenses__title-form"
                            >
                                <h1 class="text-body-title text-right mr-1 pt-2">
                                    Tipo Gasto:</h1>
                            </div>
                            <MSelectComp
                                :singleLine="viewTable ? true : false"
                                :label="viewTable ? '' : 'Tipo Gasto'"
                                :style="viewTable ? 'width:300px; max-width:300px;' : ''"
                                :items="listTransientFilter"
                                itemValue="value"
                                itemText="title"
                                v-model="dataExpense.transient"
                                :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido']"
                            />
                        </div>
                        <div class="d-flex mr-2">
                            <div
                                v-if="viewTable"
                                class="expenses__title-form">
                                <h1 class="text-body-title text-right mr-1 pt-2">
                                    Información Adicional:</h1>
                            </div>
                            <v-textarea
                                :singleLine="viewTable ? true : false"
                                :label="viewTable ? '' : 'Información Adicional'"
                                :style="viewTable ? 'width:300px; max-width:300px;' : ''"
                                v-model="dataExpense.description"
                                outlined
                                class="mt-2"
                                height="90"
                                clearable
                                counter="100"
                                :rules="[v => (v+'').length<=100 || 'La información adicional no debe exceder los 100 dígitos']"
                            ></v-textarea>
                        </div>
                        <ODialogActionComp
                            actiontext="GUARDAR"
                            @clickaction="ac_confirmActionForm()"
                        />
                    </v-form>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_cancelConfirmation()"
            @clickyes="ac_performOperation()"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_cancelConfirmation()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            :smsLoaderOperation="smsLoaderOperation"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MLineHeaderComp,
    MNoDataComp,
    MSelectComp,
    MDataDatesComp
} from '../../components/molecules'
import {
    OSmsConfirmComp,
    OSmsErrorComp,
    OLoaderOperationComp,
    OSmsActionComp,

    OBtnHeaderToolTipComp,
    OLoaderDataComp,
    ODateOneComp,
    ODialogActionComp,
    ODualDatesComp
} from '../../components/organisms'
import {
    SCardExpenseComp,
    STableExpenseComp,
    SPaginationComp
} from '../../components/species'
import {
    Expense,
    TypeExpense
} from '../../models'
import {
    dataGeneralComponetsMixin,
    responseServer,
    rulesMixin,
    datesMixin,
    userMixin
} from '../../mixins'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        responseServer,
        rulesMixin,
        datesMixin,
        userMixin
    ],
    components : {
        OSmsConfirmComp,
        OSmsErrorComp,
        OLoaderOperationComp,
        OSmsActionComp,
        MTextFieldComp,
        OBtnHeaderToolTipComp,
        MLineHeaderComp,
        OLoaderDataComp,
        MNoDataComp,
        SCardExpenseComp,
        STableExpenseComp,
        SPaginationComp,
        MSelectComp,
        MDataDatesComp,
        ODateOneComp,
        ODialogActionComp,
        ODualDatesComp
    },
    data: () => ({
        searchTypeExpense: 0,
        dialogExpense: false,
        viewTable: true,
        tab: 0,
        dataExpense: new Expense(),
        listExpenses: [],
        page: 0,
        last_page: 0,
        quantityExpenses: 0,
        totalExpenses: 0,
        listTypeExpenses: [{
            'id': 0,
            'type_expense': 'Todos'
        }],
        indexExpense: -1,
        valid: true,
        dateExpense: null,
        dateOne: "",
        dateTwo: "",
        itemsTransients: [
            { title: 'Todos', value: 2 },
            { title: 'Transitorio', value: 1 },
            { title: 'No Transitorio', value: 0 }
        ],
        transient: 2,
        idExpense: 0,
        isDelete: false,
        priceExpense: 0
    }),
    computed: {
        listTypeExpensesFilterForm: function() {
            return this.listTypeExpenses.filter(expense => {
                return expense.id !== 0
            })
        },
        listTransientFilter: function() {
            return this.itemsTransients.filter(transient => {
                return transient.value !== 2
            })
        }
    },
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_enableActionBtn(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        getRequest() {
            this.dialogLoaderData = true
            const getListTypeExpenses = TypeExpense.getListTypeExpenses()
            const getListExpenses = Expense.getListExpenses(1, 0, '', '', 2)
            Promise.all([getListTypeExpenses, getListExpenses]).then(responses => {
                responses[0].forEach(element => {
                    this.listTypeExpenses.push(element)
                })
                this.listExpenses = responses[1].data
                this.page = responses[1].current_page
                this.last_page = responses[1].last_page
                this.quantityExpenses = responses[1].quantity,
                this.totalExpenses = responses[1].total_expenses
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        getListExpenses(page, fktypeexpense, date1, date2, transient, topPos = 0) {
            this.listExpenses = []
            this.dialogLoaderData = true
            Expense.getListExpenses(page, fktypeexpense, date1, date2, transient).then(response => {
                this.listExpenses = response.data
                this.page = response.current_page
                this.last_page = response.last_page
                this.quantityExpenses = response.quantity,
                this.totalExpenses = response.total_expenses
                this.dialogLoaderData = false
                if (window.innerWidth <= 600) {
                    setTimeout(() => {
                        window.scroll(0, topPos)
                    }, 150)
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        validateData() {
            if (!this.$refs.formexpenses.validate()) return false
            return true
        },
        ac_validDate() {
            if (this.dataExpense.expense_date > this.m_date_systemDate(false)) return false
            return true
        },
        ac_changePage(state) {
            var myElement = document.getElementById('position-scroll');
            var topPos = parseInt(myElement.offsetTop) - 45;
            this.m_dataGeneral_frameTimeReset()
            if (state) { ++this.page }
            else { --this.page }
            if (window.innerWidth <= 600) {
                window.scroll(0, 0)
                setTimeout(() => {
                    this.getListExpenses(this.page, this.searchTypeExpense, this.dateOne, this.dateTwo, this.transient, topPos)
                }, 100)
            } else this.getListExpenses(this.page, this.searchTypeExpense, this.dateOne, this.dateTwo, this.transient)
        },
        ac_changeTypeExpense(idTypeExpense) {
            this.tab = 0
            this.getListExpenses(1, idTypeExpense, this.dateOne, this.dateTwo, this.transient)
        },
        ac_electionDateExpense(e) {
            this.dateExpense = this.m_date_formatDate(e)
            this.dataExpense.expense_date = e
        },
        ac_cancelConfirmation() {
            if (this.noAccess) {
                localStorage.removeItem('agapeconsultores')
                this.$router.replace('/login')
            } else {
                this.dialogConfirm = false
                this.dialogError = false
                if (this.isDelete) {
                    this.isDelete = false
                    this.indexExpense = -1
                }
            }
        },
        ac_confirmActionForm() {
            window.scroll(0, 0)
            setTimeout(() => {
                if (!this.validateData()) {
                    this.smsError = ['Revise los datos marcados con rojo y corrija porfavor']
                    this.actionError = true
                    this.dialogError = true
                } else {
                    this.dataExpense.searchfktypeexpense = this.searchTypeExpense
                    if (this.indexExpense !== -1) {
                        this.smsConfirm = [
                            `Está seguro de actualizar el gasto de: ${parseFloat(this.dataExpense.expense).toFixed(2)} Bs.`]
                    } else {
                        this.smsConfirm = [`Está seguro de registrar el gasto de: 
                            ${parseFloat(this.dataExpense.expense).toFixed(2)} Bs.`]
                    }
                    this.dialogConfirm = true
                }
            }, 100)
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        addExpense() {
            Expense.addExpense(this.dataExpense).then(response => {
                this.listExpenses.unshift(response.expense)
                this.totalExpenses = response.total_expenses
                this.quantityExpenses += 1
                this.dialogLoaderOperation = false
                this.ac_backFormExpense()
                this.showEvent(['Registro exitoso',
                    `Gasto: ${response.expense.expense.toFixed(2)} Bs.`])
            }).catch(error => {
                this.processError(error)
            })
        },
        updateExpense() {
            Expense.updateExpense(this.dataExpense).then(response => {
                Object.assign(this.listExpenses[this.indexExpense], response.expense)
                this.totalExpenses = response.total_expenses
                this.dialogLoaderOperation = false
                this.ac_backFormExpense()
                this.showEvent(['Actualización exitosa',
                    `Gasto: ${response.expense.expense.toFixed(2)} Bs.`])
            }).catch(error => {
                this.processError(error)
            })
        },
        deleteExpense() {
            Expense.deleteExpense({id: this.idExpense}).then(() => {
                this.listExpenses.splice(this.indexExpense, 1)
                this.totalExpenses -= this.priceExpense
                this.dialogLoaderOperation = false
                this.isDelete = false
                this.indexExpense = -1
                this.showEvent(['Eliminación Exitosa',
                    `Se eliminó el gasto`])
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_performOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            if (this.isDelete)
                this.deleteExpense()
            else {
                if (this.indexExpense !== -1)
                    this.updateExpense()
                else this.addExpense()
            }
        },
        ac_backFormExpense() {
            this.tab = 0
            setTimeout(() => {
                this.dataExpense = new Expense()
                this.indexExpense = -1
                this.$refs.formexpenses.resetValidation()
                this.dateExpense = this.m_date_systemDate(true)
                this.dataExpense.expense_date = this.m_date_systemDate(false)
            }, 200)
        },
        ac_showFormUpdate(expense) {
            this.dataExpense = Object.assign({}, expense)
            this.indexExpense = this.listExpenses.indexOf(expense)
            this.tab = 1
        },
        ac_searchDates(dates = null) {
            this.dateOne = dates ? dates.date1 : ''
            this.dateTwo = dates ? dates.date2 : ''
            setTimeout(()=> {
                this.getListExpenses(1, this.searchTypeExpense, this.dateOne, this.dateTwo, this.transient)
            }, 200)
        },
        ac_searchTransient(transient) {
            this.getListExpenses(1, this.searchTypeExpense, this.dateOne, this.dateTwo, transient)
        },
        ac_confirmDelete(expense) {
            this.indexExpense = this.listExpenses.indexOf(expense)
            this.priceExpense = expense.expense
            this.idExpense = expense.id
            this.isDelete = true
            this.smsConfirm = [`Está seguro de eliminar el gasto:
                ${expense.type_expense.type_expense}, por ${expense.expense} Bs.?`]
            this.dialogConfirm = true
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            this.getRequest()
            this.dateExpense = this.m_date_systemDate(true)
            this.dataExpense.expense_date = this.m_date_systemDate(false)
        } else this.$router.replace('/login')
    }
}
</script>