<template>
    <table class="s-table-fee-type animation-opacity" border="0">
        <thead>
            <th>Cliente</th>
            <th>CI/NIT</th>
            <th>Dirección</th>
            <th>Celular</th>
            <th>N° de Deudas</th>
            <th>Total Adeudado</th>
            <th>Acciones</th>
        </thead>
        <tbody>
            <tr
                v-for="(client, index) in listClients"
                :key="index"
            >
                <td class="s-table-fee-type__border-line">
                    <text-highlight
                        :queries="searchClient"
                    >
                        {{client.full_name}}
                    </text-highlight>
                </td>
                <td class="s-table-fee-type__border-line">
                    <text-highlight
                        :queries="searchClient"
                    >
                        {{client.ci_nit}}
                    </text-highlight></td>
                <td class="s-table-fee-type__border-line">
                    {{client.address!=null ? client.address : 'S/D'}}</td>
                <td class="s-table-fee-type__border-line">
                    {{client.phone!=null ? client.phone : 'S/N'}}</td>
                <td class="s-table-fee-type__border-line">{{client.list_works.length}}</td>
                <td class="s-table-fee-type__border-line">{{ac_totalDebts(client).toFixed(2)}}</td>
                <td>
                    <div class="d-flex justify-center">
                        <OBtnOptionToolTipComp
                            smsTooltip="Ver estado de deudas"
                            icon="mdi-cash-minus"
                            style="margin:2px"
                            @click="$emit('clickworks', client)"
                        />
                    </div>
                </td>
            </tr>
            <tr v-if="listClients.length==0" class="text-center">
                <td colspan="7">SIN CLIENTES</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import { OBtnOptionToolTipComp } from '../organisms'
import {
    calculationsWorkMixin
} from '../../mixins'
import TextHighlight from 'vue-text-highlight'
export default {
    mixins: [
        calculationsWorkMixin
    ],
    props: {
        listClients: Array,
        searchClient: {
            type: String,
            default: ''
        }
    },
    components: {
        OBtnOptionToolTipComp,
        TextHighlight
    },
    methods: {
        ac_totalDebts(client) {
            var totalDebts = 0
            for(var i = 0; i < client.list_works.length; i++) {
                const totalHonorary = this.m_calculation_totalHonorary(client.list_works[i], 1)
                const totalCancelledHonorary = this.m_calculation_totalPaymentsHonorary(client.list_works[i], 1)
                const totalTaxs = this.m_calculation_totalHonorary(client.list_works[i], 0)
                const totalCancelledTaxs = this.m_calculation_totalPaymentsHonorary(client.list_works[i], 0)
                totalDebts += (totalHonorary-totalCancelledHonorary)+(totalTaxs-totalCancelledTaxs)
            }
            return totalDebts
        }
    }
}
</script>