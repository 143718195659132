<template>
    <div>
        <div class="clients__header">
            <div class="clients__header-first">
                <h1 class="text-body-title">TIPO DE EMPRESAS</h1>
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    <MTextFieldComp
                        label="Buscar"
                        :hideDetails="true"
                        background="white"
                        class="history-client__search"
                        :singleLine="true"
                        :clearable="true"
                        v-model="search"
                    />
                    <OBtnHeaderToolTipComp
                        smsTooltip="Registrar tipo de empresa"
                        icon="mdi-plus"
                        class="ml-1"
                        @click="dialogTypeCompany=true"
                    />
                </div>
            </div>
        </div>
        <MLineHeaderComp />
        <OLoaderDataComp
            v-if="dialogLoaderData"
            class="mt-2"
            smsLoaderData="Obteniendo Lista"
        />
        <MNoDataComp
            smsNotData="SIN DATOS"
            class="ma-1"
            v-if="listTypeCompanyFilter.length==0 && !dialogLoaderData && !viewTable"
        />
        <div class="d-flex justify-center mt-3" v-if="viewTable">
            <STableTypeCompanyComp
                v-if="!dialogLoaderData"
                :listTypeCompany="listTypeCompanyFilter"
                @clickupdate="ac_openFormUpdate"
            />
        </div>
        <div class="d-flex justify-center flex-wrap" v-else>
            <SCardTypeCompanyComp
                v-for="(type_company, index) in listTypeCompanyFilter"
                :key="index"
                :type_company="type_company"
                class="ma-2"
                @clickupdate="ac_openFormUpdate(type_company)"
            />
        </div>
        <v-dialog
            width="400"
            v-model="dialogTypeCompany"
            persistent
            content-class="dialog--margin"
        >
            <v-card class="pa-0 ma-0">
                <v-form
                    v-on:submit.prevent="ac_sendValidForm()"
                    ref="formtypecompany"
                    lazy-validation
                    v-model="valid"
                >
                    <ODialogHeaderComp
                        :title="indexTypeCompany!=-1 ? 'Actualizar tipo empresa' : 'Nuevo registro'"
                        @clickclose="ac_closeForm()"
                    />
                    <div class="pa-2 pb-0 pt-4">
                        <MTextFieldComp
                            label="Tipo de Empresa"
                            :clearable="true"
                            v-model="dataTypeCompany.type_company"
                            :counter="100"
                            :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                                    v => (v+'').length<=100 || 'El tipo de empresa no puede superar los 100 dígitos']"
                        />
                    </div>
                    <ODialogActionComp
                        actiontext="Guardar"
                        @clickaction="ac_sendValidForm()"
                    />
                </v-form>
            </v-card>
        </v-dialog>
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_cancelConfirmation()"
            @clickyes="ac_performOperation()"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_cancelConfirmation()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            :smsLoaderOperation="smsLoaderOperation"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MLineHeaderComp,
    MNoDataComp
} from '../../components/molecules'
import {
    OBtnHeaderToolTipComp,
    OSmsConfirmComp,
    OSmsErrorComp,
    OLoaderOperationComp,
    OSmsActionComp,
    ODialogHeaderComp,
    ODialogActionComp,
    OLoaderDataComp
} from '../../components/organisms'
import {
    STableTypeCompanyComp,
    SCardTypeCompanyComp
} from '../../components/species'
import { TypeCompany } from '../../models'
import {
    dataGeneralComponetsMixin,
    rulesMixin,
    responseServer,
    userMixin
} from '../../mixins'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        rulesMixin,
        responseServer,
        userMixin
    ],
    components: {
        MTextFieldComp,
        MLineHeaderComp,
        OBtnHeaderToolTipComp,
        OSmsConfirmComp,
        OSmsErrorComp,
        OLoaderOperationComp,
        OSmsActionComp,
        MNoDataComp,
        STableTypeCompanyComp,
        ODialogHeaderComp,
        MTextFieldComp,
        ODialogActionComp,
        OLoaderDataComp,
        SCardTypeCompanyComp
    },
    data: () => ({
        viewTable: true,
        search: '',
        dialogTypeCompany: false,
        listTypeCompany: [],
        valid: true,
        dataTypeCompany: new TypeCompany(),
        indexTypeCompany: -1
    }),
    computed: {
        listTypeCompanyFilter: function() {
            if (this.m_rule_emptyField(this.search)) return this.listTypeCompany
            else {
                return this.listTypeCompany.filter(type_company => {
                    return type_company.type_company.toLowerCase()
                            .indexOf(this.search.toLowerCase()) !== -1
                })
            }
        }
    },
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_enableActionBtn(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        getListTypeCompany() {
            this.dialogLoaderData = true
            TypeCompany.getListTypeCompany().then(response => {
                this.listTypeCompany = response
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_closeForm() {
            this.dialogTypeCompany = false
            setTimeout(() => {
                this.indexTypeCompany = -1
                this.dataTypeCompany = new TypeCompany()
                this.$refs.formtypecompany.resetValidation()
            }, 200)
        },
        ac_cancelConfirmation() {
            if (this.noAccess) {
                localStorage.removeItem('agapeconsultores')
                this.$router.replace('/login')
            } else {
                this.dialogConfirm = false
                this.dialogError = false
                this.dialogTypeCompany = true
            }
        },
        validateData() {
            if (!this.$refs.formtypecompany.validate()) return false
            return true
        },
        ac_sendValidForm() {
            this.dialogTypeCompany = false
            if (!this.validateData()) {
                this.smsError = ['Revise los datos marcados con rojo y corrija porfavor']
                this.actionError = true
                this.dialogError = true
            } else {
                this.smsConfirm = ['¿Está seguro(a) de registrar el tipo de empresa?']
                this.dialogConfirm = true
            }
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        addTypeCompany() {
            TypeCompany.addTypeCompany(this.dataTypeCompany).then(response => {
                this.listTypeCompany.push(response)
                this.showEvent(['Registro exitoso',
                    `Se registró: ${response.type_company}`])
                this.ac_closeForm()
                this.dialogLoaderOperation = false
            }).catch(error => {
                this.processError(error)
            })
        },
        updateTypeCompany() {
            TypeCompany.updateTypeCompany(this.dataTypeCompany).then(response => {
                Object.assign(this.listTypeCompany[this.indexTypeCompany], response)
                this.showEvent(['Operación exitosa',
                    `Se editó: ${response.type_company}`])
                this.ac_closeForm()
                this.dialogLoaderOperation = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_performOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            if (this.indexTypeCompany === -1) this.addTypeCompany()
            else this.updateTypeCompany()
        },
        ac_openFormUpdate(type_company) {
            this.indexTypeCompany = this.listTypeCompany.indexOf(type_company)
            this.dataTypeCompany = Object.assign({}, type_company)
            this.dialogTypeCompany = true
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            this.getListTypeCompany()
        } else this.$router.replace('/login')
    }
}
</script>