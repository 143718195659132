<template>
    <v-app>
        <router-view/>
    </v-app>
</template>
<script>
//VUE_APP_ROOT_API=http://127.0.0.1:8000/api/
//VUE_APP_ROOT_API=/api/
export default {
  name: 'App',
  data: () => ({
    //
  })
};
</script>
<style src="./sass_styles/main.scss" lang="scss"></style>
<style lang="sass">
    @import '../node_modules/typeface-roboto/index.css'
</style>