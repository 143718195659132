import axios from 'axios'
import { apiUrls } from '../plugins/apiUrls'
import { userStore } from '../store/modules'

export default class TypeExpense{
    constructor(
        type_expense = null
    ){
        this.type_expense = type_expense
    }
    static getListTypeExpenses() {
        return axios.get(
            apiUrls.TYPE_EXPENSE + '/list',
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static addTypeExpense(parameters) {
        return axios.post(
            apiUrls.TYPE_EXPENSE + '/add',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static updateTypeExpense(parameters) {
        return axios.put(
            apiUrls.TYPE_EXPENSE + '/update',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static deleteTypeExpense(parameters) {
        return axios.delete(
            apiUrls.TYPE_EXPENSE + '/delete',
            { headers: userStore.state.header, data: parameters }
        )
    }
}