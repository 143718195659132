<template>
    <div>
        <div class="d-flex align-center pa-2">
            <h1 class="text-body-title">REPORTE TRABAJOS</h1>
            <v-spacer></v-spacer>
            <div class="d-flex">
                <OBtnHeaderToolTipComp
                    smsTooltip="Generar Reporte PDF"
                    icon="mdi-file-pdf-box"
                    class="mr-1"
                    classBtn="error"
                    @click="ac_generateReport()"
                />
                <OBtnHeaderToolTipComp
                    v-if="tab!=0"
                    smsTooltip="Volver a la lista de trabajos"
                    icon="mdi-database"
                    class="mr-1"
                    @click="tab=0"
                />
            </div>
        </div>
        <MLineHeaderComp />
        <div class="works__submenu">
            <MSelectComp
                label="Estado"
                class="works__submenu-first"
                style="width:100% !important"
                :items="itemsStateWork"
                itemText="title"
                itemValue="value"
                v-model="searchWork"
                :hideDetails="true"
                @change="ac_changeStateWork"
            />
            <div class="d-flex align-center works__submenu-second">
                <MSelectComp
                    label="Tipo de Fecha"
                    :items="itemsTypeWork"
                    style="width:50%"
                    itemText="title"
                    itemValue="value"
                    class="mr-1"
                    v-model="searchTypeWork"
                    :hideDetails="true"
                    @change="ac_changeTypeWork"
                />
                <MSelectComp
                    label="Tipo de Honorario"
                    :items="itemsFeeType"
                    style="width:50%"
                    itemText="fee"
                    itemValue="id"
                    v-model="searchFeeType"
                    :hideDetails="true"
                    @change="ac_changeFeeType"
                />
            </div>
            <ODualDatesComp
                class="works__submenu-date"
                @onChangingDate="ac_searchDates"
            />
        </div>
        <v-divider id="position-scroll" class="mt-2"></v-divider>
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="pa-2 o-header-work-payments">
                    <div class="o-header-work-payments__first">
                        <div class="d-flex">
                            <h1 class="text-body-title mr-1">Expresado en:</h1>
                            <h1 class="text-body-subtitle">Bolivianos</h1>
                        </div>
                        <div class="d-flex">
                            <h1 class="text-body-title mr-1">Cantidad de Trabajos:</h1>
                            <h1 class="text-body-subtitle">{{quantityWorks}}</h1>
                        </div>
                    </div>
                    <div class="o-header-work-payments__first">
                        <div class="d-flex">
                            <h1 class="text-body-title mr-1">Total Ingresos (Honorario):</h1>
                            <h1 class="text-body-subtitle">{{totalWorks.total_honorary.toFixed(2)}}</h1>
                        </div>
                        <div class="d-flex">
                            <h1 class="text-body-title mr-1">Total Cancelado (Honorario):</h1>
                            <h1 class="text-body-subtitle">{{totalWorks.total_cancelled_honorary.toFixed(2)}}</h1>
                        </div>
                        <div class="d-flex">
                            <h1 class="text-body-title mr-1">Total Por Cobrar (Honorario):</h1>
                            <h1 class="text-body-subtitle">
                                {{(totalWorks.total_honorary-totalWorks.total_cancelled_honorary).toFixed(2)}}</h1>
                        </div>
                    </div>
                    <div>
                        <div class="d-flex">
                            <h1 class="text-body-title mr-1">Total Recaudado (Impuestos):</h1>
                            <h1 class="text-body-subtitle">{{totalWorks.total_taxs.toFixed(2)}}</h1>
                        </div>
                        <div class="d-flex">
                            <h1 class="text-body-title mr-1">Total Cancelado (Impuestos):</h1>
                            <h1 class="text-body-subtitle">{{totalWorks.total_cancelled_taxs.toFixed(2)}}</h1>
                        </div>
                        <div class="d-flex">
                            <h1 class="text-body-title mr-1">Total Por Cobrar (Impuestos):</h1>
                            <h1 class="text-body-subtitle">
                                {{(totalWorks.total_taxs-totalWorks.total_cancelled_taxs).toFixed(2)}}</h1>
                        </div>
                    </div>
                </div>
                <v-divider></v-divider>
                <OLoaderDataComp
                    v-if="dialogLoaderData"
                    class="mt-2"
                    smsLoaderData="Obteniendo Lista"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="listWorks.length==0 && !dialogLoaderData && !viewTable"
                />
                <div class="d-flex justify-center mt-3" v-if="viewTable">
                    <STableWorkComp
                        v-if="!dialogLoaderData"
                        :listWorks="listWorks"
                        :isReport="true"
                        @clickpayments="ac_showListPayments"
                        @ckickdetail="ac_showDetailWork"
                    />
                </div>
                <div class="d-flex justify-center" v-else>
                    <div v-if="!dialogLoaderData" class="d-flex justify-center flex-wrap">
                        <SCardWorkComp
                            v-for="(work, index) in listWorks"
                            :key="index"
                            class="ma-2"
                            :work="work"
                            :isReport="true"
                            @clickpayments="ac_showListPayments(work)"
                            @ckickdetail="ac_showDetailWork(work)"
                        />
                    </div>
                </div>
                <SPaginationComp
                    class="mt-2"
                    v-if="!dialogLoaderData && listWorks.length>0"
                    :condicionGo="page < last_page"
                    :condicionBack="page > 1"
                    :titlePagination="'Pag. '+page+' de '+last_page"
                    @clickback="ac_changePage(false)"
                    @clickgo="ac_changePage(true)"
                />
            </v-tab-item>
            <v-tab-item>
                <OHeaderWorkPaymentsComp
                    :work="work"
                />
                <v-divider></v-divider>
                <OLoaderDataComp
                    v-if="dialogLoaderDataPayments"
                    class="mt-2"
                    smsLoaderData="Obteniendo Pagos"
                />
                <MNoDataComp
                    smsNotData="SIN PAGOS REALIZADOS"
                    class="ma-1"
                    v-if="listPayments.length==0 && !dialogLoaderDataPayments && !viewTable"
                />
                <div class="d-flex justify-center mt-3" v-if="viewTable">
                    <STablePaymentsComp
                        v-if="!dialogLoaderDataPayments"
                        :listPayments="listPayments"
                        :isTable="false"
                    />
                </div>
                <div class="d-flex justify-center" v-else>
                    <div
                        v-if="!dialogLoaderData"
                        class="d-flex justify-center flex-wrap"
                        style="width:100%"
                    >
                        <SCardPaymentComp
                            class="ma-2"
                            v-for="(payment, index) in listPayments"
                            :key="index"
                            :payment="payment"
                            :isTable="false"
                        />
                    </div>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog
            v-model="dialogDetailWork"
            width="650px"
            persistent
            content-class="dialog--margin"
        >
            <v-card class="ma-0 pa-0" v-if="work.client!=undefined">
                <ODialogHeaderComp
                    :title="work.client.full_name"
                    @clickclose="dialogDetailWork=false"
                />
                <SDetailWorkComp
                    :work="work"
                    :isInfo="true"
                />
            </v-card>
        </v-dialog>
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            colorMessage="error"
            @click="m_dataGeneral_frameTimeReset()"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MSelectComp,
    MNoDataComp
} from '../../components/molecules'
import {
    ODualDatesComp,
    OLoaderDataComp,
    OBtnHeaderToolTipComp,
    OHeaderWorkPaymentsComp,
    ODialogHeaderComp,
    OSmsActionComp,
    OSmsErrorComp
} from '../../components/organisms'
import {
    STableWorkComp,
    SCardWorkComp,
    STablePaymentsComp,
    SCardPaymentComp,
    SPaginationComp,
    SDetailWorkComp
} from '../../components/species'
import {
    FeeType,
    Work,
    Payment
} from '../../models'
import {
    dataGeneralComponetsMixin,
    responseServer,
    userMixin
} from '../../mixins'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        responseServer,
        userMixin
    ],
    components : {
        MLineHeaderComp,
        MSelectComp,
        ODualDatesComp,
        OLoaderDataComp,
        OBtnHeaderToolTipComp,
        STableWorkComp,
        SCardWorkComp,
        OHeaderWorkPaymentsComp,
        STablePaymentsComp,
        SCardPaymentComp,
        ODialogHeaderComp,
        SDetailWorkComp,
        SPaginationComp,
        OSmsActionComp,
        OSmsErrorComp,
        MNoDataComp
    },
    data: () => ({
        tab: 0,
        work: {},
        viewTable: true,
        searchWork: -1,
        itemsStateWork: [
            { title: 'Todos', value: -1 },
            { title: 'Cancelado', value: 1 },
            { title: 'Con deuda', value: 0 }
        ],
        itemsTypeWork: [
            { title: 'Por registro', value: 'created_at'},
            { title: 'Por trabajo', value: 'work_date'}
        ],
        searchTypeWork: 'work_date',
        itemsFeeType: [{ id: 0, fee: 'Todos'}],
        searchFeeType: 0,
        listWorks: [],
        page: 0,
        last_page: 0,
        date1: '',
        date2: '',
        quantityWorks: 0,
        totalWorks: {
            total_honorary: 0,
            total_cancelled_honorary: 0,
            total_taxs: 0,
            total_cancelled_taxs: 0
        },
        dialogDetailWork: false,
        listPayments: [],
        dialogLoaderDataPayments: false
    }),
    methods: {
        processError(error) {
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_enableActionBtn(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('agapeconsultores')
                this.$router.replace('/login')
            } else this.dialogError = false
        },
        getListReport(page, date1, date2, id_fee, id_state, type_date, topPos = 0) {
            this.listWorks = []
            this.dialogLoaderData = true
            const listWork = Work.getListWorkReport(page, date1, date2, id_fee, id_state, type_date)
            const totalWorks = Work.totalWorkReport(page, date1, date2, id_fee, id_state, type_date)
            Promise.all([listWork, totalWorks]).then(responses => {
                this.listWorks = responses[0].data
                this.page = responses[0].page
                this.last_page = responses[0].last_page
                this.quantityWorks = responses[0].quantity
                this.totalWorks = responses[1]
                this.dialogLoaderData = false
                if (window.innerWidth <= 600) {
                    setTimeout(() => {
                        window.scroll(0, topPos)
                    }, 150)
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        getRequest() {
            const listWorks = Work.getListWorkReport(1, '', '', 0, -1, 'work_date')
            const totalWorks = Work.totalWorkReport(1, '', '', 0, -1, 'work_date')
            const listFeeType = FeeType.getListFeeType()
            this.dialogLoaderData = true
            Promise.all([listWorks, listFeeType, totalWorks]).then(responses => {
                this.listWorks = responses[0].data
                this.page = responses[0].page
                this.last_page = responses[0].last_page
                this.quantityWorks = responses[0].quantity
                this.totalWorks = responses[2]
                responses[1].forEach(element => {
                    this.itemsFeeType.push(element)
                })
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_searchDates(dates = null) {
            this.date1 = dates ? dates.date1 : ''
            this.date2 = dates ? dates.date2 : ''
            setTimeout(()=> {
                this.listWorks = []
                this.dialogLoaderData = true
                this.getListReport(1,this.date1, this.date2, this.searchFeeType, this.searchWork, this.searchTypeWork)
            }, 200)
        },
        ac_showListPayments(work) {
            window.scroll(0, 0)
            setTimeout(() => {
                this.listPayments = []
                this.work = work
                this.tab = 1
                this.dialogLoaderDataPayments = true
                Payment.getListPaymentsWork(work.id).then(response => {
                    this.listPayments = response
                    this.dialogLoaderDataPayments = false
                }).catch(error => {
                    this.processError(error)
                })
            }, 150)
        },
        ac_showDetailWork(work) {
            this.work = work
            this.dialogDetailWork = true
        },
        ac_changeStateWork(e) {
            this.getListReport(1,this.date1, this.date2, this.searchFeeType, e, this.searchTypeWork)
        },
        ac_changeTypeWork(e) {
            this.getListReport(1,this.date1, this.date2, this.searchFeeType, this.searchWork, e)
        },
        ac_changeFeeType(e) {
            this.getListReport(1,this.date1, this.date2, e, this.searchWork, this.searchTypeWork)
        },
        ac_changePage(state) {
            var myElement = document.getElementById('position-scroll');
            var topPos = parseInt(myElement.offsetTop) - 45;
            if (state) { ++this.page }
            else { --this.page }
            if (window.innerWidth <= 600) {
                window.scroll(0, 0)
                setTimeout(() => {
                    this.getListReport(this.page, this.date1, this.date2, this.searchFeeType, this.searchWork, this.searchTypeWork, topPos)
                }, 100)
            } else this.getListReport(this.page, this.date1, this.date2, this.searchFeeType, this.searchWork, this.searchTypeWork)
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        ac_generateReport() {
            if (this.listWorks.length > 0) {
                const nameState = this.itemsStateWork.filter(state => {
                    return state.value === this.searchWork
                })
                const nameTypeWork = this.itemsTypeWork.filter(typeWork => {
                    return typeWork.value === this.searchTypeWork
                })
                const nameTypeHonorary = this.itemsFeeType.filter(fee => {
                    return fee.id === this.searchFeeType
                })
                Work.reportPdf(this.date1, this.date2, this.searchFeeType, this.searchWork, this.searchTypeWork,
                    nameState[0].title, nameTypeWork[0].title, nameTypeHonorary[0].fee)
            } else {
                this.m_dataGeneral_frameTimeReset()
                setTimeout(() => {
                    this.showEvent(['Operación incorrecta', 'debe haber información para procesar el PDF'])
                }, 200)
            }
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            this.getRequest()
        } else this.$router.replace('/login')
    }
}
</script>