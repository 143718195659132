<template>
    <div class="d-flex justify-center animation-opacity">
        <div>
            <h1 class="text-body-title">
            {{smsLoaderData}}</h1>
            <div class="text-center mt-1">
                <v-progress-circular
                    :size="30"
                    color="primary"
                    :width="7"
                    indeterminate
                ></v-progress-circular>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        smsLoaderData: String
    }
}
</script>