<template>
    <table class="s-table-fee-type animation-opacity" border="0">
        <thead>
            <th>Fecha Trabajo</th>
            <th>Tipo Trabajo</th>
            <th>Periodo</th>
            <!--<th>N° de Pagos</th>-->
            <th>Total Precio</th>
            <th>Total Cancelado</th>
            <th>Total Por Cobrar</th>
            <th>Acciones</th>
        </thead>
        <tbody>
            <tr
                v-for="(work, index) in listWorks"
                :key="index"
            >
                <td class="s-table-fee-type__border-line">{{work.work_date}}</td>
                <td class="s-table-fee-type__border-line">{{work.fee.fee}}</td>
                <td class="s-table-fee-type__border-line">
                    {{work.period!=null ? work.period : 'S/P'}}</td>
                <!--<td class="s-table-fee-type__border-line text-center">
                    {{work.detail_payments.length}}</td>-->
                <td class="s-table-fee-type__border-line text-right">
                    {{m_calculation_totalCost(work).toFixed(2)}}</td>
                <td class="s-table-fee-type__border-line" style="text-align:right">
                    {{m_calculation_totalCancelled(work).toFixed(2)}}</td>
                <td class="s-table-fee-type__border-line" style="text-align:right">
                    {{ac_totalDebt(work).toFixed(2)}}</td>
                <td style="max-width:130px">
                    <div class="d-flex justify-center flex-wrap">
                        <OBtnOptionToolTipComp
                            smsTooltip="Detalle del trabajo"
                            icon="mdi-format-list-checkbox"
                            style="margin:2px"
                            @click="$emit('ckickdetail', work)"
                        />
                        <OBtnOptionToolTipComp
                            smsTooltip="Detalle de Pagos"
                            icon="mdi-cash"
                            style="margin:2px"
                            @click="$emit('clickpayments', work)"
                        />
                        <OBtnOptionToolTipComp
                            smsTooltip="Cancelar Deuda"
                            icon="mdi-account-cash"
                            style="margin:2px"
                            @click="$emit('clickcancelled', work)"
                        />
                    </div>
                </td>
            </tr>
            <tr v-if="listWorks.length==0" class="text-center">
                <td colspan="7">SIN DEUDAS</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import { OBtnOptionToolTipComp } from '../organisms'
import { calculationsWorkMixin } from '../../mixins'
export default {
    mixins: [
        calculationsWorkMixin
    ],
    props: {
        listWorks: Array
    },
    components: {
        OBtnOptionToolTipComp
    },
    methods: {
        ac_totalDebt(work) {
            const debt = this.m_calculation_totalCost(work)-this.m_calculation_totalCancelled(work)
            return debt
        }
    }
}
</script>