<template>
    <table class="s-table-fee-type animation-opacity" border="0">
        <thead>
            <th>Cliente</th>
            <th>Tipo Trabajo</th>
            <th>Periodo</th>
            <th>Total Precio</th>
            <th>Total Cancelado</th>
            <th>Total por Cobrar</th>
            <th>Acciones</th>
        </thead>
        <tbody>
            <tr
                v-for="(work, index) in listWorks"
                :key="index"
            >
                <td class="s-table-fee-type__border-line">{{work.client.full_name}}</td>
                <td class="s-table-fee-type__border-line">
                    <v-icon
                        :color="work.cancelled==1 ? 'green' : 'red'"
                        class="mr-1"
                    >{{work.cancelled==1 ? 'mdi-check-circle' : 'mdi-account-cash'}}</v-icon>
                    {{work.fee.fee}}</td>
                <td class="s-table-fee-type__border-line">{{work.period!=null ? work.period : 'S/P'}}</td>
                
                <td class="s-table-fee-type__border-line text-right">
                    {{m_calculation_totalCost(work).toFixed(2)}}</td>
                <td class="s-table-fee-type__border-line" style="text-align:right">
                    {{m_calculation_totalCancelled(work).toFixed(2)}}</td>
                <td class="s-table-fee-type__border-line" style="text-align:right">
                    {{ac_totalDebt(work).toFixed(2)}}</td>
                <td>
                    <div class="d-flex justify-center flex-wrap">
                        <OBtnOptionToolTipComp
                            smsTooltip="Detalle del trabajo"
                            icon="mdi-format-list-checkbox"
                            style="margin:2px"
                            @click="$emit('ckickdetail', work)"
                        />
                        <OBtnOptionToolTipComp
                            v-if="ac_validPrint(work) && !isReport"
                            smsTooltip="Imprimir recibo"
                            icon="mdi-printer"
                            style="margin:2px"
                            @click="$emit('clickprint', work)"
                        />
                        <OBtnOptionToolTipComp
                            smsTooltip="Lista de Pagos"
                            icon="mdi-cash"
                            style="margin:2px"
                            @click="$emit('clickpayments', work)"
                        />
                        <OBtnOptionToolTipComp
                            v-if="!isReport"
                            smsTooltip="Anular trabajo"
                            icon="mdi-trash-can"
                            style="margin:2px"
                            @click="$emit('clickdiscard', work)"
                        />
                    </div>
                </td>
            </tr>
            <tr v-if="listWorks.length==0" class="text-center">
                <td colspan="8">SIN DATOS</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import { OBtnOptionToolTipComp } from '../organisms'
import { calculationsWorkMixin } from '../../mixins'
export default {
    mixins: [
        calculationsWorkMixin
    ],
    props: {
        listWorks: Array,
        isReport: {
            default: false,
            type: Boolean
        }
    },
    components: {
        OBtnOptionToolTipComp
    },
    methods: {
        ac_totalDebt(work) {
            const debt = this.m_calculation_totalCost(work)-this.m_calculation_totalCancelled(work)
            return debt
        },
        ac_validPrint(work) {
            var quantityPayments = 0
            for(var i=0; i < work.detail_money.length; i++) {
                if (work.detail_money[i].payments.length > 0) quantityPayments++
                if (work.detail_money[i].payments.length > 1) return false
            }
            if (quantityPayments === 0) return false
            return true
        }
    }
}
</script>