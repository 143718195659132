<template>
    <div>
        <div class="clients__header">
            <div class="clients__header-first">
                <h1 class="text-body-title">CLIENTES</h1>
                <v-spacer></v-spacer>
                <MSelectComp
                    class="history-client__search"
                    :singleLine="true"
                    :items="listTypeCompany"
                    v-model="optionTypeCompany"
                    :hideDetails="true"
                    itemValue="id"
                    itemText="type_company"
                    @change="ac_searchOptionType"
                />
            </div>
            <div class="clients__header-second">
                <OSearchComp
                    label="Nombre o CI/NIT"
                    class="mr-1 clients__header-input--width"
                    v-model="searchAltern"
                    @search="ac_searchClient()"
                />
                <OBtnHeaderToolTipComp
                    v-if="tab!=1"
                    smsTooltip="Registrar nuevo cliente"
                    icon="mdi-plus"
                    class="mr-1"
                    @click="tab=1"
                />
                <OBtnHeaderToolTipComp
                    v-if="tab!=0"
                    smsTooltip="Volver a la lista de tarifas"
                    icon="mdi-database"
                    class="mr-1"
                    @click="ac_backListClients()"
                />
            </div>
        </div>
        <MLineHeaderComp id="position-scroll" />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <OLoaderDataComp
                    v-if="dialogLoaderData"
                    class="mt-2"
                    smsLoaderData="Obteniendo Clientes"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="listClients.length==0 && !dialogLoaderData && !viewTable"
                />
                <div class="d-flex justify-center mt-3" v-if="viewTable">
                    <STableClientsComp
                        v-if="!dialogLoaderData"
                        :listClients="listClients"
                        @clickupdate="ac_showFormUpdate"
                        @clickdetail="ac_showDetailClient"
                        @clickstate="ac_changeState"
                        :searchClient="searchFilter"
                    />
                </div>
                <div class="d-flex justify-center" v-else>
                    <div
                        v-if="!dialogLoaderData"
                        class="d-flex justify-center flex-wrap"
                        style="width:100%;"
                    >
                        <SCardClientComp
                            v-for="(client, index) in listClients"
                            :key="index"
                            :client="client"
                            class="ma-2"
                            :searchClient="searchFilter"
                            @clickupdate="ac_showFormUpdate(client)"
                            @clickdetail="ac_showDetailClient(client)"
                            @clickstate="ac_changeState(client)"
                        />
                    </div>
                </div>
                <SPaginationComp
                    class="mt-2"
                    v-if="!dialogLoaderData && listClients.length > 0"
                    :condicionGo="page < last_page"
                    :condicionBack="page > 1"
                    :titlePagination="'Pag. '+page+' de '+last_page"
                    @clickback="ac_changePage(false)"
                    @clickgo="ac_changePage(true)"
                />
            </v-tab-item>
            <v-tab-item>
                <h1 class="text-body-title text-center mt-2">
                    {{indexClient!=-1 ? 'FORMULARIO DE EDICIÓN' : 'FORMULARIO DE REGISTRO'}}</h1>
                <div class="d-flex justify-center works__form-container">
                    <v-form
                        v-on:submit.prevent="ac_confirmActionForm()"
                        ref="formclient"
                        v-model="valid"
                        lazy-validation
                        class="works__form-work mt-3 mb-3"
                    >
                        <div class="d-flex mt-2">
                            <div
                                v-if="viewTable"
                                class="expenses__title-form"
                            >
                                <h1 class="text-body-title text-right mr-1 pt-2">Nombre / Empresa:</h1>
                            </div>
                            <MTextFieldComp
                                :singleLine="viewTable ? true : false"
                                :label="viewTable ? '' : 'Nombre / Empresa'"
                                :clearable="true"
                                v-model="dataClient.full_name"
                                :counter="50"
                                :style="viewTable ? 'width:300px; max-width:300px;' : ''"
                                :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                                        v => (v+'').length<=50 || 'El nombre no debe exceder los 50 dígitos']"
                            />
                        </div>
                        <div class="d-flex mt-2">
                            <div
                                v-if="viewTable"
                                class="expenses__title-form"
                            >
                                <h1 class="text-body-title text-right mr-1 pt-2">CI / NIT:</h1>
                            </div>
                            <MTextFieldComp
                                :singleLine="viewTable ? true : false"
                                :label="viewTable ? '' : 'CI / NIT'"
                                :style="viewTable ? 'width:300px; max-width:300px;' : ''"
                                :clearable="true"
                                v-model="dataClient.ci_nit"
                                :counter="20"
                                :rules="[
                                    v => !m_rule_emptyField(v) || 'El CI/NIT es requerido',
                                    v => m_rule_numberInteger(v) || 'El CI/NIT solo debe contener números',
                                    v => (v+'').length<=20 || 'El CI/NIT no debe exceder los 20 dígitos'
                                ]"
                            />
                        </div>
                        <div class="d-flex mt-2">
                            <div
                                v-if="viewTable"
                                class="expenses__title-form"
                            >
                                <h1 class="text-body-title text-right mr-1 pt-2">Dirección:</h1>
                            </div>
                            <MTextFieldComp
                                :singleLine="viewTable ? true : false"
                                :label="viewTable ? '' : 'Dirección'"
                                :style="viewTable ? 'width:300px; max-width:300px;' : ''"
                                :clearable="true"
                                v-model="dataClient.address"
                                :counter="50"
                                :rules="[v => (v+'').length<=50 || 'La dirección no debe exceder los 50 dígitos']"
                            />
                        </div>
                        <div class="d-flex mt-2">
                            <div
                                v-if="viewTable"
                                class="expenses__title-form"
                            >
                                <h1 class="text-body-title text-right mr-1 pt-2">Celular:</h1>
                            </div>
                            <MTextFieldComp
                                :singleLine="viewTable ? true : false"
                                :label="viewTable ? '' : 'Celular'"
                                :style="viewTable ? 'width:300px; max-width:300px;' : ''"
                                :clearable="true"
                                v-model="dataClient.phone"
                                :counter="15"
                                :rules="[v => ac_validPhone(v) || 'El celular debe ser un número',
                                    v => (v+'').length<=15 || 'El celular no debe exceder los 15 dígitos']"
                            />
                        </div>
                        <div class="d-flex mt-2">
                            <div
                                v-if="viewTable"
                                class="expenses__title-form"
                            >
                                <h1 class="text-body-title text-right mr-1 pt-2">Tipo de empresa:</h1>
                            </div>
                            <MSelectComp
                                :singleLine="viewTable ? true : false"
                                :label="viewTable ? '' : 'Tipo de Empresa'"
                                :style="viewTable ? 'width:300px; max-width:300px;' : ''"
                                :items="listTypeCompanyFilter"
                                v-model="dataClient.fktypecompany"
                                itemValue="id"
                                itemText="type_company"
                                :rules="[v => !m_rule_emptyField(v) || 'La dato es requerido']"
                            />
                        </div>
                        <div class="d-flex mt-2">
                            <div
                                v-if="viewTable"
                                class="expenses__title-form"
                            >
                                <h1 class="text-body-title text-right mr-1 pt-2">Mes de Cierre:</h1>
                            </div>
                            <MTextFieldComp
                                :singleLine="viewTable ? true : false"
                                :label="viewTable ? '' : 'Mes de Cierre'"
                                :style="viewTable ? 'width:300px; max-width:300px;' : ''"
                                :clearable="true"
                                v-model="dataClient.closing_month"
                                :counter="30"
                                :rules="[v => (v+'').length<=30 || 'El mes de cierre no debe exceder los 15 dígitos']"
                            />
                        </div>
                        <div class="d-flex mt-2">
                            <div
                                v-if="viewTable"
                                class="expenses__title-form"
                            >
                                <h1 class="text-body-title text-right mr-1 pt-2">Actividad principal:</h1>
                            </div>
                            <v-textarea
                                :single-line="viewTable ? true : false"
                                :label="viewTable ? '' : 'Actividad Principal'"
                                :style="viewTable ? 'width:300px; max-width:300px;' : ''"
                                outlined
                                height="120"
                                v-model="dataClient.main_activity"
                                :counter="400"
                                :rules="[
                                    v => (v+'').length<=400 || 'La actividad principal no debe exceder los 400 dígitos'
                                ]"
                            ></v-textarea>
                        </div>
                        <div class="d-flex justify-center mb-1">
                            <MBtnNormalComp
                                :outlined="true"
                                color="primary"
                                @click="ac_confirmActionForm()"
                                class="mr-1"
                            >{{indexClient!=-1 ? 'GUARDAR' : 'REGISTRAR'}}</MBtnNormalComp>
                        </div>
                    </v-form>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog
            width="500"
            v-model="dialogClientDetail"
            persistent
            content-class="dialog--margin"
        >
            <ODialogHeaderComp
                title="Información Cliente"
                @clickclose="dialogClientDetail=false"
            />
            <v-card class="pa-2 ma-0">
                <div v-if="client.full_name!=undefined">
                    <div class="d-flex">
                        <h1 class="text-body-title mr-1">Cliente:</h1>
                        <h1 class="text-body-subtitle">{{client.full_name}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-title mr-1">CI/NIT:</h1>
                        <h1 class="text-body-subtitle">{{client.ci_nit}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-title mr-1">Dirección:</h1>
                        <h1 class="text-body-subtitle">
                            {{client.address!=null ? client.address : 'S/D'}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-title mr-1">Celular:</h1>
                        <h1 class="text-body-subtitle">
                            {{client.phone!=null ? client.phone : 'S/N'}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-title mr-1">Mes de Cierre:</h1>
                        <h1 class="text-body-subtitle">
                            {{client.closing_month!=null ? client.closing_month : 'S/C'}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-title mr-1">Tipo de Empresa:</h1>
                        <h1 class="text-body-subtitle">
                            {{client.company.type_company}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-title mr-1">Estado:</h1>
                        <h1 class="text-body-subtitle">
                            {{client.enabled!=0 ? 'Habilitado' : 'Inhabilitado'}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-title mr-1">Actividad Principal:</h1>
                        <h1 class="text-body-subtitle">
                            {{client.main_activity}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-title mr-1">Fecha de Registro:</h1>
                        <h1 class="text-body-subtitle">
                            {{client.date}}</h1>
                    </div>
                </div>
            </v-card>
        </v-dialog>
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_cancelConfirmation()"
            @clickyes="ac_performOperation()"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_cancelConfirmation()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Realizando Operación"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MNoDataComp,
    MTextFieldComp,
    MBtnNormalComp,
    MSelectComp
} from '../../components/molecules'
import {
    OSmsConfirmComp,
    OSmsErrorComp,
    OLoaderOperationComp,
    OLoaderDataComp,
    OSmsActionComp,
    OBtnHeaderToolTipComp,
    OSearchComp,
    ODialogHeaderComp,
} from '../../components/organisms'
import {
    SCardClientComp,
    STableClientsComp,
    SPaginationComp
} from '../../components/species'
import {
    dataGeneralComponetsMixin,
    responseServer,
    rulesMixin,
    userMixin
} from '../../mixins'
import {
    Client,
    TypeCompany
} from '../../models'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        responseServer,
        rulesMixin,
        userMixin
    ],
    components: {
        OSmsConfirmComp,
        OSmsErrorComp,
        OLoaderOperationComp,
        OLoaderDataComp,
        MLineHeaderComp,
        OSmsActionComp,
        MNoDataComp,
        OBtnHeaderToolTipComp,
        OSearchComp,
        SCardClientComp,
        STableClientsComp,
        SPaginationComp,
        MTextFieldComp,
        MBtnNormalComp,
        MSelectComp,
        ODialogHeaderComp
    },
    data: () => ({
        viewTable: true,
        listClients: [],
        dataClient: new Client(),
        last_page: 0,
        page: 0,
        search: '',
        searchAltern: '',
        valid: true,
        indexClient: -1,
        total: 0,
        listTypeCompany: [ {type_company: 'Todos', id: 0} ],
        tab: 0,
        client: {},
        dialogClientDetail: false,
        isChangeState: false,
        idClient: 0,
        optionTypeCompany: 0
    }),
    computed: {
        searchFilter: function() {
            if (this.searchAltern === null) return ''
            else return this.searchAltern
        },
        listTypeCompanyFilter: function() {
            return this.listTypeCompany.filter(type_company => {
                return type_company.id !== 0
            })
        }
    },
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_enableActionBtn(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        getListClients(page, search, fktypecompany, topPos = 0) {
            this.dialogLoaderData = true
            Client.getListClients(page, search, fktypecompany).then(response => {
                this.last_page = response.last_page
                this.page = response.current_page
                this.listClients = response.data
                this.dialogLoaderData = false
                if (window.innerWidth <= 600) {
                    setTimeout(() => {
                        window.scroll(0, topPos)
                    }, 150)
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        validateData() {
            if (!this.$refs.formclient.validate()) return false
            return true
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        ac_validPhone(input) {
            if (this.m_rule_emptyField(input)) return true
            else return this.m_rule_numberInteger(input)
        },
        ac_changePage(state) {
            var myElement = document.getElementById('position-scroll');
            var topPos = parseInt(myElement.offsetTop) - 45;
            this.m_dataGeneral_frameTimeReset()
            this.searchAltern = this.search
            if (state) { ++this.page }
            else { --this.page }
            if (window.innerWidth <= 600) {
                window.scroll(0, 0)
                setTimeout(() => {
                    this.getListClients(this.page, this.search, this.optionTypeCompany, topPos)
                }, 100)
            } else this.getListClients(this.page, this.search, this.optionTypeCompany)
        },
        ac_searchClient() {
            this.tab = 0
            this.m_dataGeneral_frameTimeReset()
            if (this.m_rule_emptyField(this.searchAltern)) {
                this.searchAltern = this.search = ''
            } else {
                this.search = this.searchAltern
            }
            this.getListClients(1, this.searchAltern, this.optionTypeCompany)
        },
        ac_showFormUpdate(client) {
            this.m_dataGeneral_frameTimeReset()
            this.indexClient = this.listClients.indexOf(client)
            this.dataClient = Object.assign({}, client)
            this.tab = 1
        },
        ac_closeForm() {
            this.tab = 0
            setTimeout(() => {
                this.$refs.formclient.resetValidation()
                this.dataClient = new Client()
                this.indexClient = -1
            }, 200)
        },
        ac_cancelConfirmation() {
            if (this.noAccess) {
                localStorage.removeItem('agapeconsultores')
                this.$router.replace('/login')
            } else {
                if (this.isChangeState) {
                    this.indexClient = -1
                    this.isChangeState = false
                }
                this.dialogConfirm = false
                this.dialogError = false
            }
        },
        ac_confirmActionForm() {
            window.scroll(0, 0)
            setTimeout(() => {
                if (!this.validateData()) {
                    this.smsError = ['Revise los datos marcados con rojo y corrija porfavor']
                    this.actionError = true
                    this.dialogError = true
                } else {
                    this.smsConfirm = [`¿Está seguro de actualizar los datos del cliente: ${this.dataClient.full_name}?`]
                    this.dialogConfirm = true
                }
            }, 100)
        },
        updateClient() {
            Client.updateClient(this.dataClient).then(response => {
                this.dialogLoaderOperation = false
                Object.assign(this.listClients[this.indexClient], response)
                this.showEvent(['Actualización exitosa',
                    `El cliente fue actualizado`])
                this.ac_closeForm()
            }).catch(error => {
                this.processError(error)
            })
        },
        addClient() {
            Client.addClient(this.dataClient).then(response => {
                this.listClients.push(response)
                this.dialogLoaderOperation = false
                this.showEvent(['Registro exitoso',
                    `El cliente fue registrado`])
                this.ac_closeForm()
            }).catch(error => {
                this.processError(error)
            })
        },
        changeStateClient() {
            Client.changeState({id: this.idClient}).then(response => {
                Object.assign(this.listClients[this.indexClient], response)
                this.dialogLoaderOperation = false
                this.indexClient = -1
                this.isChangeState = false
                this.showEvent(['Operación exitosa',
                    `Se cambió el estado`])
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_performOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            if (this.isChangeState) this.changeStateClient()
            else {
                if (this.indexClient !== -1) this.updateClient()
                else this.addClient()
            }
        },
        getRequest() {
            this.dialogLoaderData = true
            const getListClient = Client.getListClients(1, '', 0)
            const getListTypeCompany = TypeCompany.getListTypeCompany()
            Promise.all([getListClient, getListTypeCompany]).then(responses => {
                this.last_page = responses[0].last_page
                this.page = responses[0].current_page
                this.listClients = responses[0].data
                this.totalClients = responses[0].total
                responses[1].forEach(element => {
                    this.listTypeCompany.push(element)
                })
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_backListClients() {
            this.tab = 0
            setTimeout(() => {
                this.dataClient = new Client()
                this.$refs.formclient.resetValidation()
                this.indexClient = -1
            }, 200)
        },
        ac_showDetailClient(client) {
            this.client = client
            this.dialogClientDetail = true
        },
        ac_changeState(client) {
            this.idClient = client.id
            this.isChangeState = true
            this.indexClient = this.listClients.indexOf(client)
            const smsState = client.enabled !== 0 ? 'inhabilitar' : 'habilitar'
            this.smsConfirm = [`¿Está seguro de ${smsState} a: ${client.full_name}?`]
            this.dialogConfirm = true
        },
        ac_searchOptionType(e) {
            this.getListClients(1, this.searchAltern, e)
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            this.getRequest()
        } else this.$router.replace('/login')
    }
}
</script>
