<template>
    <div>
        <div class="clients__header">
            <div class="clients__header-first">
                <h1 class="text-body-title">HISTORIAL TRABAJOS</h1>
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    <OSearchComp
                        label="Nombre o CI/NIT"
                        class="history-client__search"
                        v-model="searchClient"
                        @search="ac_searchClient()"
                    />
                    <OBtnHeaderToolTipComp
                        v-if="tab!=0"
                        smsTooltip="Volver a la lista de trabajos."
                        icon="mdi-database"
                        class="ml-1"
                        @click="tab=0"
                    />
                </div>
            </div>
        </div>
        <MLineHeaderComp id="position-scroll" />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <OLoaderDataComp
                    v-if="dialogLoaderData"
                    class="mt-2"
                    smsLoaderData="Obteniendo Trabajos"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="listWorks.length==0 && !dialogLoaderData && !viewTable"
                />
                <div class="d-flex justify-center mt-3" v-if="viewTable">
                    <STableClientWorksComp
                        v-if="!dialogLoaderData"
                        :listWorks="listWorks"
                        :searchClient="searchFilter"
                        @clickpayments="ac_showListPayments"
                        @ckickdetail="ac_showDetailWork"
                    />
                </div>
                <div class="d-flex justify-center" v-else>
                    <div
                        v-if="!dialogLoaderData"
                        class="d-flex justify-center flex-wrap"
                        style="width:100%"
                    >
                        <SCardClientWorksComp
                            v-for="(work, index) in listWorks"
                            :key="index"
                            class="ma-2"
                            :work="work"
                            :searchClient="searchFilter"
                            @clickpayments="ac_showListPayments(work)"
                            @ckickdetail="ac_showDetailWork(work)"
                        />
                    </div>
                </div>
                <SPaginationComp
                    class="mt-2"
                    v-if="!dialogLoaderData && listWorks.length>0"
                    :condicionGo="page < last_page"
                    :condicionBack="page > 1"
                    :titlePagination="'Pag. '+page+' de '+last_page"
                    @clickback="ac_changePage(false)"
                    @clickgo="ac_changePage(true)"
                />
            </v-tab-item>
            <v-tab-item>
                <OHeaderWorkPaymentsComp
                    :work="work"
                />
                <v-divider></v-divider>
                <OLoaderDataComp
                    v-if="dialogLoaderDataPayments"
                    class="mt-2"
                    smsLoaderData="Obteniendo Pagos"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="listPayments.length==0 && !dialogLoaderDataPayments && !viewTable"
                />
                <div class="d-flex justify-center mt-3" v-if="viewTable">
                    <STablePaymentsComp
                        v-if="!dialogLoaderDataPayments"
                        :listPayments="listPayments"
                        :isTable="false"
                    />
                </div>
                <div class="d-flex justify-center" v-else>
                    <div
                        v-if="!dialogLoaderDataPayments"
                        class="d-flex justify-center flex-wrap"
                        style="width:100%"
                    >
                        <SCardPaymentComp
                            class="ma-2"
                            v-for="(payment, index) in listPayments"
                            :key="index"
                            :payment="payment"
                            :isTable="false"
                        />
                    </div>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog
            v-model="dialogDetailWork"
            width="650px"
            persistent
            content-class="dialog--margin"
        >
            <v-card class="ma-0 pa-0" v-if="work.client!=undefined">
                <ODialogHeaderComp
                    :title="work.client.full_name"
                    @clickclose="dialogDetailWork=false"
                />
                <SDetailWorkComp
                    :work="work"
                    :isInfo="true"
                />
            </v-card>
        </v-dialog>
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MNoDataComp
} from '../../components/molecules'
import {
    OBtnHeaderToolTipComp,
    OSearchComp,
    OLoaderDataComp,
    ODialogHeaderComp,
    OHeaderWorkPaymentsComp,
    OSmsErrorComp
} from '../../components/organisms'
import {
    STableClientWorksComp,
    SPaginationComp,
    STablePaymentsComp,
    SCardPaymentComp,
    SCardClientWorksComp,
    SDetailWorkComp
} from '../../components/species'
import {
    rulesMixin,
    responseServer,
    dataGeneralComponetsMixin,
    userMixin
} from '../../mixins'
import {
    Payment,
    Work
} from '../../models'
export default {
    mixins: [
        rulesMixin,
        responseServer,
        dataGeneralComponetsMixin,
        userMixin
    ],
    components: {
        OBtnHeaderToolTipComp,
        MLineHeaderComp,
        OSearchComp,
        OLoaderDataComp,
        MNoDataComp,
        STableClientWorksComp,
        ODialogHeaderComp,
        SDetailWorkComp,
        SPaginationComp,
        OHeaderWorkPaymentsComp,
        STablePaymentsComp,
        SCardPaymentComp,
        SCardClientWorksComp,
        OSmsErrorComp
    },
    data: () => ({
        tab: 0,
        viewTable: true,
        searchClient: '',
        listWorks: [],
        dialogLoaderDataPayments: false,
        listPayments: [],
        dialogDetailWork: false,
        work: {},
        page: 0,
        last_page: 0
    }),
    computed: {
        searchFilter: function() {
            if (this.searchClient === null) return ''
            else return this.searchClient
        }
    },
    methods: {
        processError(error) {
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_enableActionBtn(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('agapeconsultores')
                this.$router.replace('/login')
            } else this.dialogError = false
        },
        ac_showListPayments(work) {
            this.work = work
            this.tab = 1
            this.dialogLoaderDataPayments = true
            Payment.getListPaymentsWork(work.id).then(response => {
                this.listPayments = response
                this.dialogLoaderDataPayments = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_showDetailWork(work) {
            this.work = work
            this.dialogDetailWork = true
        },
        ac_searchClient() {
            this.tab = 0
            if (!this.m_rule_emptyField(this.searchClient)) {
                this.getClientWorks(1, this.searchClient)
            }
        },
        getClientWorks(page, search, topPos = 0) {
            this.dialogLoaderData = true
            this.listWorks = []
            Work.getListClientWorks(page, search).then(response => {
                this.listWorks = response.data
                this.page = response.page
                this.last_page = response.last_page
                this.dialogLoaderData = false
                if (window.innerWidth <= 600) {
                    setTimeout(() => {
                        window.scroll(0, topPos)
                    }, 150)
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_changePage(state) {
            var myElement = document.getElementById('position-scroll');
            var topPos = parseInt(myElement.offsetTop) - 45;
            if (state) { ++this.page }
            else { --this.page }
            if (window.innerWidth <= 600) {
                window.scroll(0, 0)
                setTimeout(() => {
                    this.getClientWorks(this.page, this.searchClient, topPos)
                }, 100)
            } else this.getClientWorks(this.page, this.searchClient, topPos)
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (!this.m_user_saveToken()) this.$router.replace('/login')
    }
}
</script>