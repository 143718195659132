<template>
    <v-select
        :single-line="singleLine"
        :menu-props="{ bottom: true, offsetY: true }"
        :background-color="backgroundColor"
        dense
        outlined
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        :label="label"
        :hide-details="hideDetails"
        :append-icon="icono"
        @change="(e)=>(this.$emit('change', e))"
        :rules="rules"
        :value="value"
    >
    </v-select>
</template>
<script>
export default {
    model:{
        event: 'change'
    },
    props:{
        singleLine: Boolean,
        backgroundColor:String,
        icono:String,
        items:Array,
        itemText:String,
        itemValue: [String, Number],
        label:String,
        hideDetails:Boolean,
        rules:Array,
        value:[Number, String]
    }
}
</script>