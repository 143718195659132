import axios from 'axios'
import { apiUrls } from '../plugins/apiUrls'
import Client from './Client'
import { userStore } from '../store/modules'

export default class Rate{
    constructor(
        rate = null,
        description = null,
        fkfeetype = null,
        client = new Client(),
        new_client = 0
    ){
        this.rate = rate
        this.description = description
        this.fkfeetype = fkfeetype
        this.client = client
        this.new_client = new_client
    }
    static getList(page, search) {
        return axios.get(
            apiUrls.RATE + '/list?page='+page+'&search='+search,
            { headers: userStore.state.header }
        ).then(response => {
                return response.data
            })
    }
    static addRate(parameters) {
        return axios.post(
            apiUrls.RATE + '/add',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static updateRate(parameters) {
        return axios.put(
            apiUrls.RATE + '/update',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static deleteRate(parameters) {
        return axios.delete(
            apiUrls.RATE + '/delete',
            { headers: userStore.state.header, data: parameters }
        )
    }
    static rateClient(id_client, id_feetype) {
        return axios.get(
            apiUrls.RATE + '/rate-client?id_client='+id_client+'&id_feetype='+id_feetype,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
}