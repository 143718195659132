import axios from 'axios'
import { apiUrls } from '../plugins/apiUrls'
import { userStore } from '../store/modules'

export default class User {
    constructor (
        username = null,
        password = null
    ) {
        this.username = username
        this.password = password
    }
    static list() {
        return axios.get(
            apiUrls.USER + '/list',
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static login(account) {
        return axios.post(apiUrls.USER + '/login', account).then(
            response => {
                return response.data
            }
        )
    }
    static logout(header) {
        return axios.post(
            apiUrls.USER + '/logout', {},
            { headers: header }
        ).then(
            response => {
                return response.data
            }
        )
    }
    static changePassword(parameters) {
        return axios.put(
            apiUrls.USER + '/change-password',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
}