<template>
    <table class="s-table-fee-type animation-opacity" border="0">
        <thead>
            <th>Gasto</th>
            <th>Monto</th>
            <th>N° Rec. / Fac.</th>
            <th>Transitorio</th>
            <th>Fecha de gasto</th>
            <th>Inf. Adicional</th>
            <th>Fecha de registro</th>
            <th v-if="!itsJustInformation">Acciones</th>
        </thead>
        <tbody>
            <tr
                v-for="(expense, index) in listExpenses"
                :key="index"
            >
                <td class="s-table-fee-type__border-line">{{expense.type_expense.type_expense}}</td>
                <td class="s-table-fee-type__border-line" style="text-align:right">
                    {{expense.expense.toFixed(2)}}</td>
                <td class="s-table-fee-type__border-line text-center">
                    {{expense.receipt_number!= null ? expense.receipt_number : 'S/N'}}</td>
                <td class="s-table-fee-type__border-line text-center">
                    {{expense.transient==0 ? 'No' : 'Si'}}</td>
                <td class="s-table-fee-type__border-line">
                    {{expense.expense_date_format}}</td>
                <td class="s-table-fee-type__border-line">
                    {{expense.description!=null ? expense.description : 'S/I'}}</td>
                <td class="s-table-fee-type__border-line">
                    {{expense.registration_date}}</td>
                <td v-if="!itsJustInformation">
                    <div class="d-flex justify-center">
                        <OBtnOptionToolTipComp
                            smsTooltip="Actualizar gasto"
                            icon="mdi-lead-pencil"
                            style="margin:2px"
                            @click="$emit('clickupdate', expense)"
                        />
                        <OBtnOptionToolTipComp
                            smsTooltip="Eliminar gasto"
                            icon="mdi-trash-can"
                            style="margin:2px"
                            @click="$emit('clickdelete', expense)"
                        />
                    </div>
                </td>
            </tr>
            <tr v-if="listExpenses.length==0" class="text-center">
                <td
                    :colspan="itsJustInformation ? 7 : 8"
                >SIN DATOS</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import { OBtnOptionToolTipComp } from '../organisms'
export default {
    props: {
        listExpenses: Array,
        itsJustInformation: {
            type: Boolean,
            default: false
        }
    },
    components: {
        OBtnOptionToolTipComp
    }
}
</script>