var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","value":_vm.value,"width":"500px","content-class":"dialog--margin"}},[(_vm.payment.id != undefined)?_c('v-card',{staticClass:"ma-0 pa-0"},[_c('ODialogHeaderComp',{attrs:{"title":"Editar Pago"},on:{"clickclose":function($event){return _vm.$emit('clickclose')}}}),_c('div',{staticClass:"pt-2 pb-2"},[_c('div',{staticClass:"d-flex pl-2"},[_c('h1',{staticClass:"text-body-title"},[_vm._v("Cliente:")]),_c('h1',{staticClass:"text-body-subtitle ml-1"},[_vm._v(_vm._s(_vm.work.client.full_name))])]),_c('div',{staticClass:"d-flex pl-2"},[_c('h1',{staticClass:"text-body-title"},[_vm._v("Tipo Trabajo:")]),_c('h1',{staticClass:"text-body-subtitle ml-1"},[_vm._v(_vm._s(_vm.work.fee.fee))])]),_c('div',{staticClass:"d-flex pl-2"},[_c('h1',{staticClass:"text-body-title"},[_vm._v("Periodo:")]),_c('h1',{staticClass:"text-body-subtitle ml-1"},[_vm._v(_vm._s(_vm.work.period))])]),_c('v-divider',{staticClass:"mt-1 mb-2"}),(_vm.viewTable)?_c('div',{staticClass:"d-flex justify-cente pl-2 pr-2"},[_c('table',{staticClass:"s-table-fee-type animation-opacity",staticStyle:{"width":"100%"},attrs:{"border":"0"}},[_c('thead',[_c('th',[_vm._v("Concepto")]),_c('th',[_vm._v("Adeudado")]),_c('th',{attrs:{"width":"220px"}},[_vm._v("Monto a Cancelar")])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"s-table-fee-type__border-line"},[_vm._v(" "+_vm._s(_vm.payment.detail_money.honorary == 1 ? 'Honorario' : _vm.payment.detail_money.tax))]),_c('td',{staticClass:"s-table-fee-type__border-line"},[_vm._v(" "+_vm._s(_vm.payment.pre_balance))]),_c('td',{staticClass:"s-table-fee-type__border-line d-flex justify-center"},[_c('MTextFieldComp',{staticClass:"mr-1",staticStyle:{"width":"180px","max-width":"180px"},attrs:{"clearable":true,"counter":10,"hideDetails":true,"rules":[
                                        function (v) { return !_vm.m_rule_emptyField(v) || 'El dato es requerido'; },
                                        function (v) { return _vm.m_rule_numberDecimal(v) || 'El pago debe ser un número'; },
                                        function (v) { return !_vm.m_rule_exceededNumber(v, _vm.payment.pre_balance) || 'El pago no debe superar el monto adeudado'; },
                                        function (v) { return (v+'').length<=10 || 'El pago no debe exceder los 10 dígitos'; }]},on:{"click":function($event){return _vm.ac_validateForm()}},model:{value:(_vm.payment.payment),callback:function ($$v) {_vm.$set(_vm.payment, "payment", $$v)},expression:"payment.payment"}})],1)])])])]):_c('div',{staticClass:"pa-2 pt-0"},[_c('div',{staticClass:"d-flex"},[_c('h1',{staticClass:"text-body-title mr-1"},[_vm._v("Concepto:")]),_c('h1',{staticClass:"text-body-subtitle"},[_vm._v(" "+_vm._s(_vm.payment.detail_money.honorary == 1 ? 'Honorario' : _vm.payment.detail_money.tax)+" ")])]),_c('div',{staticClass:"d-flex"},[_c('h1',{staticClass:"text-body-title mr-1"},[_vm._v("Adeudado:")]),_c('h1',{staticClass:"text-body-subtitle"},[_vm._v(_vm._s(_vm.payment.pre_balance))])]),_c('div',[_c('h1',{staticClass:"text-body-title mr-1"},[_vm._v("Monto a Cancelar:")]),_c('MTextFieldComp',{staticClass:"mr-1 mt-1",staticStyle:{"width":"100%"},attrs:{"clearable":true,"counter":10,"hideDetails":true,"rules":[
                            function (v) { return !_vm.m_rule_emptyField(v) || 'El dato es requerido'; },
                            function (v) { return _vm.m_rule_numberDecimal(v) || 'El pago debe ser un número'; },
                            function (v) { return !_vm.m_rule_exceededNumber(v, _vm.payment.pre_balance) || 'El pago no debe superar el monto adeudado'; },
                            function (v) { return (v+'').length<=10 || 'El pago no debe exceder los 10 dígitos'; }]},model:{value:(_vm.payment.payment),callback:function ($$v) {_vm.$set(_vm.payment, "payment", $$v)},expression:"payment.payment"}})],1)])],1),_c('ODialogActionComp',{attrs:{"actiontext":"Editar"},on:{"clickaction":function($event){return _vm.ac_validateForm()}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }