<template>
    <div>
        <div class="clients__header">
            <div class="clients__header-first">
                <h1 class="text-body-title">TIPO DE TRABAJOS</h1>
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    <MTextFieldComp
                        label="Buscar"
                        :hideDetails="true"
                        class="history-client__search"
                        background="white"
                        :singleLine="true"
                        :clearable="true"
                        v-model="searchFeeType"
                    />
                    <OBtnHeaderToolTipComp
                        smsTooltip="Registrar nuevo tipo de trabajo"
                        icon="mdi-plus"
                        class="ml-1"
                        @click="dialogHonorary=true"
                    />
                </div>
            </div>
        </div>
        <MLineHeaderComp />
        <OLoaderDataComp
            v-if="dialogLoaderData"
            class="mt-2"
            smsLoaderData="Obteniendo Lista"
        />
        <MNoDataComp
            smsNotData="SIN DATOS"
            class="ma-1"
            v-if="listFeeTypeFilter.length==0 && !dialogLoaderData && !viewTable"
        />
        <div class="d-flex justify-center mt-3" v-if="viewTable">
            <STableFeeTypeComp
                v-if="!dialogLoaderData"
                :listFeeType="listFeeTypeFilter"
                @clickupdate="ac_showFormUpdate"
                @clickdelete="ac_confirmDelete"
            />
        </div>
        <div class="d-flex justify-center flex-wrap" v-else>
            <SCardFeeTypeComp
                v-for="(fee, index) in listFeeTypeFilter"
                :key="index"
                :fee="fee"
                class="ma-2"
                @clickupdate="ac_showFormUpdate(fee)"
                @clickdelete="ac_confirmDelete(fee)"
            />
        </div>
        <v-dialog
            width="400"
            v-model="dialogHonorary"
            persistent
            content-class="dialog--margin"
        >
            <v-card class="pa-0 ma-0">
                <ODialogHeaderComp
                    :title="indexFeeType!=-1 ? 'Actualizar Tipo de Trabajo' : 'Nuevo Tipo de Trabajo'"
                    @clickclose="ac_closeForm()"
                />
                <v-form
                    v-on:submit.prevent="ac_confirmActionForm()"
                    ref="formhonorary"
                    v-model="valid"
                    lazy-validation
                    class="pa-2 pt-4 pb-0"
                >
                    <MTextFieldComp
                        label="Tipo de Trabajo"
                        :clearable="true"
                        v-model="dataFeeType.fee"
                        :counter="50"
                        :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                                v => (v+'').length<=50 || 'El Tipo de Trabajo no debe exceder los 50 dígitos']"
                    />
                </v-form>
                <ODialogActionComp
                    actiontext="Guardar"
                    @clickaction="ac_confirmActionForm()"
                />
            </v-card>
        </v-dialog>
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_cancelConfirmation()"
            @clickyes="ac_performOperation()"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_cancelConfirmation()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            :smsLoaderOperation="smsLoaderOperation"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MTextFieldComp,
    MNoDataComp
} from '../../components/molecules'
import {
    OSmsConfirmComp,
    OSmsErrorComp,
    OLoaderOperationComp,
    OSmsActionComp,
    OBtnHeaderToolTipComp,
    OLoaderDataComp,
    ODialogHeaderComp,
    ODialogActionComp
} from '../../components/organisms'
import {
    SCardFeeTypeComp,
    STableFeeTypeComp
} from '../../components/species'
import {
    dataGeneralComponetsMixin,
    responseServer,
    rulesMixin,
    userMixin
} from '../../mixins'
import {
    FeeType
} from '../../models'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        responseServer,
        rulesMixin,
        userMixin
    ],
    components: {
        OSmsConfirmComp,
        OSmsErrorComp,
        OLoaderOperationComp,
        OSmsActionComp,
        MNoDataComp,
        MTextFieldComp,
        MLineHeaderComp,
        OBtnHeaderToolTipComp,
        OLoaderDataComp,
        SCardFeeTypeComp,
        STableFeeTypeComp,
        ODialogHeaderComp,
        ODialogActionComp
    },
    data: () => ({
        valid: true,
        listFeeType: [],
        dataFeeType: new FeeType(),
        searchFeeType: null,
        viewTable: true,
        dialogHonorary: false,
        indexFeeType: -1,
        idFeeType: -1,
        isElimination: false
    }),
    computed: {
        listFeeTypeFilter: function() {
            if (this.m_rule_emptyField(this.searchFeeType)) return this.listFeeType
            else {
                return this.listFeeType.filter(fee => {
                    return fee.fee.toLowerCase()
                            .indexOf(this.searchFeeType.toLowerCase()) !== -1
                })
            }
        }
    },
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_enableActionBtn(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        getListFeeType() {
            this.dialogLoaderData = true
            FeeType.getListFeeType().then(response => {
                this.listFeeType = response
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        resetDataDelete() {
            this.idFeeType = -1
            this.indexFeeType = -1
            this.isElimination = false
            this.dialogLoaderOperation = false
        },
        validateData() {
            if (!this.$refs.formhonorary.validate()) return false
            return true
        },
        addHonorary() {
            FeeType.addFeeType(this.dataFeeType).then(response => {
                this.listFeeType.unshift(response)
                this.ac_closeForm()
                this.dialogLoaderOperation = false
                this.showEvent(['Registro exitoso',
                    `Se registro el tipo de trabajo: ${response.fee}`])
            }).catch(error => {
                this.processError(error)
            })
        },
        updateHonorary() {
            FeeType.updateHonorary(this.dataFeeType).then(response => {
                Object.assign(this.listFeeType[this.indexFeeType], response)
                this.ac_closeForm()
                this.dialogLoaderOperation = false
                this.showEvent(['Actualización exitosa',
                    `Se actualizó el tipo de trabajo ${response.fee}`])
            }).catch(error => {
                this.processError(error)
            })
        },
        deleteHonorary() {
            FeeType.deleteHonorary({'id' :this.idFeeType}).then(() => {
                this.listFeeType.splice(this.indexFeeType, 1)
                this.resetDataDelete()
                this.showEvent(['Eliminación exitosa',
                    `Se eliminó el tipo de trabajo`])
            }).catch(error => {
                this.processError(error)
            })
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        ac_closeForm() {
            this.dialogHonorary = false
            setTimeout(() => {
                this.$refs.formhonorary.resetValidation()
                this.dataFeeType = new FeeType()
                this.indexFeeType = -1
            }, 200)
        },
        ac_confirmActionForm() {
            this.dialogHonorary = false
            if (!this.validateData()) {
                this.smsError = ['Revise los datos marcados con rojo y corrija porfavor']
                this.actionError = true
                this.dialogError = true
            } else {
                if (this.indexFeeType !== -1) {
                    this.smsConfirm = [`Está seguro de actualizar el tipo de trabajo: ${this.dataFeeType.fee}`]
                } else {
                    this.smsConfirm = [`Está seguro de registrar el tipo de trabajo: ${this.dataFeeType.fee}`]
                }
                this.dialogConfirm = true
            }
        },
        ac_showFormUpdate(fee) {
            this.m_dataGeneral_frameTimeReset()
            this.dataFeeType = Object.assign({}, fee)
            this.indexFeeType = this.listFeeType.indexOf(fee)
            this.dialogHonorary = true
        },
        ac_cancelConfirmation() {
            if (this.noAccess) {
                localStorage.removeItem('agapeconsultores')
                this.$router.replace('/login')
            } else {
                this.dialogConfirm = false
                this.dialogError = false
                if (!this.isElimination) this.dialogHonorary = true
                else this.resetDataDelete()
            }
        },
        ac_confirmDelete(fee) {
            this.m_dataGeneral_frameTimeReset()
            this.smsConfirm = [`Está seguro de eliminar el tipo de trabajo: ${fee.fee}`]
            this.isElimination = true
            this.indexFeeType = this.listFeeType.indexOf(fee)
            this.idFeeType = fee.id
            this.dialogConfirm = true
        },
        ac_performOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            if (this.isElimination)
                this.deleteHonorary()
            else {
                if (this.indexFeeType !== -1)
                    this.updateHonorary()
                else this.addHonorary()
            }
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            this.getListFeeType()
        } else this.$router.replace('/login')
    }
}
</script>