<template>
    <table class="s-table-fee-type animation-opacity" border="0">
        <thead>
            <th>Tipo Impuesto</th>
            <th>Acciones</th>
        </thead>
        <tbody>
            <tr
                v-for="(tax, index) in listTaxs"
                :key="index"
            >
                <td class="s-table-fee-type__border-line text-center">
                    {{tax.tax}}</td>
                <td>
                    <div class="d-flex justify-center">
                        <OBtnOptionToolTipComp
                            smsTooltip="Actualizar impuesto"
                            icon="mdi-lead-pencil"
                            style="margin:2px"
                            @click="$emit('clickupdate', tax)"
                        />
                    </div>
                </td>
            </tr>
            <tr v-if="listTaxs.length==0" class="text-center">
                <td colspan="2">SIN DATOS</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import { OBtnOptionToolTipComp } from '../organisms'
export default {
    props: {
        listTaxs: Array
    },
    components: {
        OBtnOptionToolTipComp
    }
}
</script>