import { mapMutations } from 'vuex'
export default {
    methods: {
        ...mapMutations('userStore', ['saveHeader']),
        m_user_saveToken() {
            if (JSON.parse(localStorage.getItem('agapeconsultores'))) {
                var apitoken = JSON.parse(localStorage.getItem('agapeconsultores')).token
                var header = {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + apitoken,
                }
                this.saveHeader(header)
                return true
            } else {
                return false
            }
        }
    }
}