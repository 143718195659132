<template>
    <div class="o-sheet-receipt">
        <div class="d-flex">
            <div class="o-sheet-receipt__container">
                <div v-if="work.client!=undefined">
                    <h1 style="font-size:18px" v-if="ac_listPayments().length>0">
                        {{'RECIBO - N° '+ac_listPayments()[0].payments[0].detail.id}}</h1>
                    <h1 style="font-size:11px; font-weight:500;">(Expresado en Bolivianos)</h1>
                    <v-divider class="mt-1 mb-1"></v-divider>
                    <div class="d-flex align-center">
                        <div>
                            <div class="d-flex">
                                <h1 class="o-sheet-receipt__data-title mr-1">Cliente:</h1>
                                <h1 class="o-sheet-receipt__data-subtitle">
                                    {{work.client.full_name}}
                                </h1>
                            </div>
                            <div class="d-flex">
                                <h1 class="o-sheet-receipt__data-title mr-1">CI/NIT:</h1>
                                <h1 class="o-sheet-receipt__data-subtitle">
                                    {{work.client.ci_nit}}</h1>
                            </div>
                            <div class="d-flex" v-if="work.period!=null">
                                <h1 class="o-sheet-receipt__data-title mr-1">Trabajo:</h1>
                                <h1 class="o-sheet-receipt__data-subtitle">
                                    {{work.fee.fee}}
                                </h1>
                            </div>
                            <div class="d-flex" v-if="work.period!=null">
                                <h1 class="o-sheet-receipt__data-title mr-1">Periodo:</h1>
                                <h1 class="o-sheet-receipt__data-subtitle">
                                    {{work.period!=null ? work.period : 'S/P'}}
                                </h1>
                            </div>
                            <div class="d-flex">
                                <h1 class="o-sheet-receipt__data-title mr-1">Fecha:</h1>
                                <h1 class="o-sheet-receipt__data-subtitle">
                                    {{work.date}}
                                </h1>
                            </div>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="o-sheet-receipt__logo"></div>
                    </div>
                    <v-divider class="mt-1 mb-1"></v-divider>
                    <h1 class="text-center mt-2" style="font-size:16px">DETALLE</h1>
                    <table class="o-sheet-receipt__table">
                        <thead>
                            <tr>
                                <th>Concepto</th>
                                <th>Precio</th>
                                <th>Cancel.</th>
                                <th>Saldo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(payment, index) in ac_listPayments()"
                                :key="index"
                            >
                                <td class="s-table-fee-type__border-line">
                                    {{payment.honorary==1 ? 'Honorario' : payment.tax}}</td>
                                <td class="s-table-fee-type__border-line text-right">
                                    {{payment.payments[0].pre_balance.toFixed(2)}}</td>
                                <td class="s-table-fee-type__border-line text-right">
                                    {{payment.payments[0].payment.toFixed(2)}}</td>
                                <td class="s-table-fee-type__border-line text-right">
                                    {{(payment.payments[0].pre_balance-payment.payments[0].payment).toFixed(2)}}</td>
                            </tr>
                            <tr>
                                <th>TOTALES:</th>
                                <td class="s-table-fee-type__border-line text-right">
                                    {{m_calculation_totalCost(work).toFixed(2)}}</td>
                                <td class="s-table-fee-type__border-line text-right">
                                    {{m_calculation_totalCancelled(work).toFixed(2)}}</td>
                                <td class="s-table-fee-type__border-line text-right">
                                    {{ac_totalDebt(work).toFixed(2)}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex justify-center mt-10">
                        <div>
                            <v-divider></v-divider>
                            <h1 class="o-sheet-receipt__data-title">Entregué Conforme</h1>
                        </div>
                        <div class="ml-12">
                            <v-divider></v-divider>
                            <h1 class="o-sheet-receipt__data-title">Recibí Conforme</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="o-sheet-receipt__container">
                <div v-if="work.client!=undefined">
                    <h1 style="font-size:18px" v-if="ac_listPayments().length>0">
                        {{'RECIBO - N° '+ac_listPayments()[0].payments[0].detail.id}}</h1>
                    <h1 style="font-size:11px; font-weight:500;">(Expresado en Bolivianos)</h1>
                    <v-divider class="mt-1 mb-1"></v-divider>
                    <div class="d-flex align-center">
                        <div>
                            <div class="d-flex">
                                <h1 class="o-sheet-receipt__data-title mr-1">Cliente:</h1>
                                <h1 class="o-sheet-receipt__data-subtitle">
                                    {{work.client.full_name}}
                                </h1>
                            </div>
                            <div class="d-flex">
                                <h1 class="o-sheet-receipt__data-title mr-1">CI/NIT:</h1>
                                <h1 class="o-sheet-receipt__data-subtitle">
                                    {{work.client.ci_nit}}</h1>
                            </div>
                            <div class="d-flex" v-if="work.period!=null">
                                <h1 class="o-sheet-receipt__data-title mr-1">Trabajo:</h1>
                                <h1 class="o-sheet-receipt__data-subtitle">
                                    {{work.fee.fee}}
                                </h1>
                            </div>
                            <div class="d-flex" v-if="work.period!=null">
                                <h1 class="o-sheet-receipt__data-title mr-1">Periodo:</h1>
                                <h1 class="o-sheet-receipt__data-subtitle">
                                    {{work.period!=null ? work.period : 'S/P'}}
                                </h1>
                            </div>
                            <div class="d-flex">
                                <h1 class="o-sheet-receipt__data-title mr-1">Fecha:</h1>
                                <h1 class="o-sheet-receipt__data-subtitle">
                                    {{work.date}}
                                </h1>
                            </div>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="o-sheet-receipt__logo"></div>
                    </div>
                    <v-divider class="mt-1 mb-1"></v-divider>
                    <h1 class="text-center mt-2" style="font-size:16px">DETALLE</h1>
                    <table class="o-sheet-receipt__table">
                        <thead>
                            <tr>
                                <th>Concepto</th>
                                <th>Precio</th>
                                <th>Cancel.</th>
                                <th>Saldo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(payment, index) in ac_listPayments()"
                                :key="index"
                            >
                                <td class="s-table-fee-type__border-line">
                                    {{payment.honorary==1 ? 'Honorario' : payment.tax}}</td>
                                <td class="s-table-fee-type__border-line text-right">
                                    {{payment.payments[0].pre_balance.toFixed(2)}}</td>
                                <td class="s-table-fee-type__border-line text-right">
                                    {{payment.payments[0].payment.toFixed(2)}}</td>
                                <td class="s-table-fee-type__border-line text-right">
                                    {{(payment.payments[0].pre_balance-payment.payments[0].payment).toFixed(2)}}</td>
                            </tr>
                            <tr>
                                <th>TOTALES:</th>
                                <td class="s-table-fee-type__border-line text-right">
                                    {{m_calculation_totalCost(work).toFixed(2)}}</td>
                                <td class="s-table-fee-type__border-line text-right">
                                    {{m_calculation_totalCancelled(work).toFixed(2)}}</td>
                                <td class="s-table-fee-type__border-line text-right">
                                    {{ac_totalDebt(work).toFixed(2)}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex justify-center mt-10">
                        <div>
                            <v-divider></v-divider>
                            <h1 class="o-sheet-receipt__data-title">Entregué Conforme</h1>
                        </div>
                        <div class="ml-12">
                            <v-divider></v-divider>
                            <h1 class="o-sheet-receipt__data-title">Recibí Conforme</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-divider class="mt-2"></v-divider>
    </div>
</template>
<script>
import {
    calculationsWorkMixin
} from '../../mixins'
export default {
    mixins: [
        calculationsWorkMixin
    ],
    props: {
        work: {},
        listPayments: Array
    },
    methods: {
        ac_totalDebt(work) {
            const debt = this.m_calculation_totalCost(work)-this.m_calculation_totalCancelled(work)
            return debt
        },
        ac_listPayments() {
            if (this.work.detail_money !== undefined) {
                var listPayments = []
                for (var i = 0; i < this.work.detail_money.length; i++) {
                    if (this.work.detail_money[i].payments.length > 0)
                        listPayments.push(this.work.detail_money[i])
                }
                return listPayments
            } else return []
        }
    }
}
</script>
