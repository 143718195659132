<template>
    <div class="d-flex">
        <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                        dense
                        outlined
                        hide-details
                        v-model="formattedDate1"
                        label="Fecha Inicio"
                        readonly
                        v-on="on"
                        class="mr-1"
                ></v-text-field>
            </template>
            <v-date-picker
                    color="primary"
                    @change="selectedDates(1)"
                    locale="es-mx"
                    v-model="date1"
                    @input="menu1 = false"
            ></v-date-picker>
        </v-menu>
        <v-menu col="6"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                        dense
                        outlined
                        hide-details
                        v-model="formattedDate2"
                        label="Fecha Fin"
                        readonly
                        v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker
                    color="primary"
                    @change="selectedDates(2)"
                    locale="es-mx"
                    v-model="date2"
                    @input="menu2 = false"
            ></v-date-picker>
        </v-menu>
    </div>
</template>

<script>
    export default {
        data: () => ({
            menu1: false,
            menu2: false,
            date1: null,
            date2: null,
        }),
        mounted() {
            this.date2 = this.dateNow()
            this.date1 = this.dateNow()
        },
        methods: {
            selectedDates(numericDate) {
                if (numericDate === 1) {
                    if (new Date(this.date1).getTime() > new Date(this.date2).getTime()) {
                        this.date2 = this.date1
                    }
                } else {
                    if (new Date(this.date2).getTime() < new Date(this.date1).getTime()) {
                        this.date1 = this.date2
                    }
                }
                this.$emit('onChangingDate', {date1: this.date1, date2: this.date2})
            },
            formatDate(date) {
                if (!date) return null
                const [year, month, day] = date.split('-')
                return `${day}/${month}/${year}`
            },
            dateNow() {
                let today = new Date()
                let dd = today.getDate()
                let mm = today.getMonth() + 1 //January is 0!
                let yyyy = today.getFullYear()
                if (dd < 10) {
                    dd = '0' + dd
                }
                if (mm < 10) {
                    mm = '0' + mm
                }
                return today = yyyy + '-' + mm + '-' + dd
            },
        },
        computed: {
            formattedDate1() {
                return this.formatDate(this.date1)
            },
            formattedDate2() {
                return this.formatDate(this.date2)
            },
        }
    }
</script>
