<template>
    <div class="s-card-fee-type animation-opacity pa-2">
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Usuario:</h1>
            <h1 class="text-body-subtitle">
                {{user.username}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Categoría:</h1>
            <h1 class="text-body-subtitle">
                {{user.categorie==0 ? 'ADMINISTRADOR' : 'USUARIO'}}
            </h1>
        </div>
        <div class="d-flex justify-center mt-1">
            <OBtnOptionToolTipComp
                smsTooltip="Cambiar contraseña"
                icon="mdi-lead-pencil"
                class="mr-1"
                @click="$emit('clickupdate')"
            />
        </div>
    </div>
</template>
<script>
import {
    OBtnOptionToolTipComp
} from '../organisms'
export default {
    props: {
        user: {}
    },
    components: {
        OBtnOptionToolTipComp
    }
}
</script>