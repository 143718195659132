var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"formclient",staticClass:"pa-2 pb-0",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.ac_selectNewCustomer()}},model:{value:(_vm.validClient),callback:function ($$v) {_vm.validClient=$$v},expression:"validClient"}},[_c('MTextFieldComp',{attrs:{"label":"Cliente","clearable":true,"counter":50,"rules":[
                function (v) { return !_vm.m_rule_emptyField(v) || 'El cliente es requerido'; },
                function (v) { return (v+'').length<=50 || 'El cliente no debe exceder los 50 dígitos'; }]},model:{value:(_vm.client.full_name),callback:function ($$v) {_vm.$set(_vm.client, "full_name", $$v)},expression:"client.full_name"}}),_c('MTextFieldComp',{attrs:{"label":"CI/NIT","clearable":true,"counter":20,"rules":[
                function (v) { return !_vm.m_rule_emptyField(v) || 'El CI/NIT es requerido'; },
                function (v) { return _vm.m_rule_numberInteger(v) || 'El CI/NIT solo debe contener números'; },
                function (v) { return (v+'').length<=20 || 'El CI/NIT no debe exceder los 20 dígitos'; }
            ]},model:{value:(_vm.client.ci_nit),callback:function ($$v) {_vm.$set(_vm.client, "ci_nit", $$v)},expression:"client.ci_nit"}}),_c('MTextFieldComp',{attrs:{"label":"Dirección","clearable":true,"counter":50,"rules":[function (v) { return (v+'').length<=50 || 'La dirección no debe exceder los 50 dígitos'; }]},model:{value:(_vm.client.address),callback:function ($$v) {_vm.$set(_vm.client, "address", $$v)},expression:"client.address"}}),_c('MTextFieldComp',{attrs:{"label":"Celular","clearable":true,"counter":15,"rules":[function (v) { return _vm.ac_validPhone(v) || 'El celular debe ser un número'; },
                function (v) { return (v+'').length<=15 || 'El celular no debe exceder los 15 dígitos'; }]},model:{value:(_vm.client.phone),callback:function ($$v) {_vm.$set(_vm.client, "phone", $$v)},expression:"client.phone"}}),_c('MSelectComp',{attrs:{"label":"Tipo Empresa","items":_vm.listTypeCompany,"itemValue":"id","itemText":"type_company","rules":[function (v) { return !_vm.m_rule_emptyField(v) || 'La dato es requerido'; }]},model:{value:(_vm.client.fktypecompany),callback:function ($$v) {_vm.$set(_vm.client, "fktypecompany", $$v)},expression:"client.fktypecompany"}}),_c('MTextFieldComp',{attrs:{"label":"Mes de Cierre","clearable":true,"counter":30,"rules":[function (v) { return (v+'').length<=30 || 'El mes de cierre no debe exceder los 15 dígitos'; }]},model:{value:(_vm.client.closing_month),callback:function ($$v) {_vm.$set(_vm.client, "closing_month", $$v)},expression:"client.closing_month"}}),_c('v-textarea',{attrs:{"outlined":"","label":"Actividad Principal","height":"80","counter":400,"rules":[function (v) { return (v+'').length<=400 || 'La actividad principal no debe exceder los 400 dígitos'; }]},model:{value:(_vm.client.main_activity),callback:function ($$v) {_vm.$set(_vm.client, "main_activity", $$v)},expression:"client.main_activity"}})],1),_c('ODialogActionComp',{attrs:{"actiontext":"Seleccionar"},on:{"clickaction":function($event){return _vm.ac_selectNewCustomer()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }