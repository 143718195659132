<template>
    <div class="o-copyright">
        <h1>© Copyright 2022 - Ing. Beimar Apaza A. - Cel: 77120645</h1>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
    .o-copyright{
        position: fixed;
        left: 0px;
        bottom: 0px;
        width: 100%;
        background: #009c94;
        padding: 5px;
    }
    .o-copyright h1{
        text-align: center;
        color:#ffffffa6;
        font-weight: 400;
        font-size: 16px;
    }
    @media (max-width: 600px) {
        .o-copyright h1{
            font-size: 13px;
        }
    }
</style>