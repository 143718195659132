<template>
    <v-dialog
        persistent
        :value="value"
        width="500px"
        content-class="dialog--margin"
    >
        <v-card class="ma-0 pa-0" v-if="payment.id != undefined">
            <ODialogHeaderComp
                title="Editar Pago"
                @clickclose="$emit('clickclose')"
            />
            <div class="pt-2 pb-2">
                <div class="d-flex pl-2">
                    <h1 class="text-body-title">Cliente:</h1>
                    <h1 class="text-body-subtitle ml-1">{{work.client.full_name}}</h1>
                </div>
                <div class="d-flex pl-2">
                    <h1 class="text-body-title">Tipo Trabajo:</h1>
                    <h1 class="text-body-subtitle ml-1">{{work.fee.fee}}</h1>
                </div>
                <div class="d-flex pl-2">
                    <h1 class="text-body-title">Periodo:</h1>
                    <h1 class="text-body-subtitle ml-1">{{work.period}}</h1>
                </div>
                <v-divider class="mt-1 mb-2"></v-divider>
                <div
                    v-if="viewTable"
                    class="d-flex justify-cente pl-2 pr-2"
                >
                    <table style="width:100%" class="s-table-fee-type animation-opacity" border="0">
                        <thead>
                            <th>Concepto</th>
                            <th>Adeudado</th>
                            <th width="220px">Monto a Cancelar</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="s-table-fee-type__border-line">
                                    {{payment.detail_money.honorary == 1 ? 'Honorario' : payment.detail_money.tax}}</td>
                                <td class="s-table-fee-type__border-line">
                                    {{payment.pre_balance}}</td>
                                <td class="s-table-fee-type__border-line d-flex justify-center">
                                    <MTextFieldComp
                                        style="width:180px; max-width:180px;"
                                        :clearable="true"
                                        v-model="payment.payment"
                                        @click="ac_validateForm()"
                                        :counter="10"
                                        class="mr-1"
                                        :hideDetails="true"
                                        :rules="[
                                            v => !m_rule_emptyField(v) || 'El dato es requerido',
                                            v => m_rule_numberDecimal(v) || 'El pago debe ser un número',
                                            v => !m_rule_exceededNumber(v, payment.pre_balance) || 'El pago no debe superar el monto adeudado',
                                            v => (v+'').length<=10 || 'El pago no debe exceder los 10 dígitos']"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else class="pa-2 pt-0">
                    <div class="d-flex">
                        <h1 class="text-body-title mr-1">Concepto:</h1>
                        <h1 class="text-body-subtitle">
                            {{payment.detail_money.honorary == 1 ? 'Honorario' : payment.detail_money.tax}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-title mr-1">Adeudado:</h1>
                        <h1 class="text-body-subtitle">{{payment.pre_balance}}</h1>
                    </div>
                    <div>
                        <h1 class="text-body-title mr-1">Monto a Cancelar:</h1>
                        <MTextFieldComp
                            style="width:100%"
                            :clearable="true"
                            v-model="payment.payment"
                            :counter="10"
                            class="mr-1 mt-1"
                            :hideDetails="true"
                            :rules="[
                                v => !m_rule_emptyField(v) || 'El dato es requerido',
                                v => m_rule_numberDecimal(v) || 'El pago debe ser un número',
                                v => !m_rule_exceededNumber(v, payment.pre_balance) || 'El pago no debe superar el monto adeudado',
                                v => (v+'').length<=10 || 'El pago no debe exceder los 10 dígitos']"
                        />
                    </div>
                </div>
            </div>
            <ODialogActionComp
                actiontext="Editar"
                @clickaction="ac_validateForm()"
            />
        </v-card>
    </v-dialog>
</template>
<script>
import {
    MTextFieldComp
} from '../molecules';
import {
    ODialogHeaderComp,
    ODialogActionComp
} from '../organisms';
import { rulesMixin } from '../../mixins'
export default {
    mixins: [
        rulesMixin
    ],
    components: {
        MTextFieldComp,
        ODialogHeaderComp,
        ODialogActionComp
    },
    props: {
        payment: Object,
        work: Object,
        viewTable: Boolean,
        value: Boolean
    },
    methods: {
        ac_validateForm() {
            if (this.m_rule_emptyField(this.payment.payment)
                || !this.m_rule_numberDecimal(this.payment.payment)
                || this.m_rule_exceededNumber(parseFloat(this.payment.payment), this.payment.pre_balance)) {
                    this.$emit('clickform', false);
            } else this.$emit('clickform', true);
        }
    }
}
</script>