<template>
    <div class="s-card-work s-card-fee-type animation-opacity pa-2">
        <div class="s-card-work__ornament">
            <v-icon color="green" v-if="work.cancelled==1">mdi-check-circle</v-icon>
            <v-icon color="red" v-else>mdi-account-cash</v-icon>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-2">Cliente:</h1>
            <h1 class="text-body-subtitle">{{work.client.full_name}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-2">Tipo de Trab.:</h1>
            <h1 class="text-body-subtitle">{{work.fee.fee}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-2">Periodo:</h1>
            <h1 class="text-body-subtitle">{{work.period!=null ? work.period : 'S/P'}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-2">Total Precio:</h1>
            <h1 class="text-body-subtitle">{{m_calculation_totalCost(work).toFixed(2)}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-2">Total Cancelado:</h1>
            <h1 class="text-body-subtitle">
                {{m_calculation_totalCancelled(work).toFixed(2)}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-2">Total por Cobrar:</h1>
            <h1 class="text-body-subtitle">{{ac_totalDebt(work).toFixed(2)}}</h1>
        </div>
        <div class="d-flex justify-center mt-1">
            <OBtnOptionToolTipComp
                smsTooltip="Detalle del trabajo"
                icon="mdi-format-list-checkbox"
                style="margin:2px"
                @click="$emit('ckickdetail')"
            />
            <OBtnOptionToolTipComp
                v-if="ac_validPrint(work) && !isReport"
                smsTooltip="Imprimir recibo"
                icon="mdi-printer"
                style="margin:2px"
                @click="$emit('clickprint')"
            />
            <OBtnOptionToolTipComp
                smsTooltip="Lista de Pagos"
                icon="mdi-cash"
                style="margin:2px"
                @click="$emit('clickpayments')"
            />
            <OBtnOptionToolTipComp
                v-if="!isReport"
                smsTooltip="Anular trabajo"
                icon="mdi-trash-can"
                style="margin:2px"
                @click="$emit('clickdiscard')"
            />
        </div>
    </div>
</template>
<script>
import { OBtnOptionToolTipComp } from '../organisms'
import { calculationsWorkMixin } from '../../mixins'
export default {
    mixins: [
        calculationsWorkMixin
    ],
    components: {
        OBtnOptionToolTipComp
    },
    props: {
        work: {},
        isReport: {
            default: false,
            type: Boolean
        }
    },
    methods: {
        ac_totalDebt(work) {
            const debt = this.m_calculation_totalCost(work)-this.m_calculation_totalCancelled(work)
            return debt
        },
        ac_validPrint(work) {
            var quantityPayments = 0
            for(var i=0; i < work.detail_money.length; i++) {
                if (work.detail_money[i].payments.length > 0) quantityPayments++
                if (work.detail_money[i].payments.length > 1) return false
            }
            if (quantityPayments === 0) return false
            return true
        }
    }
}
</script>