<template>
    <v-btn
        :ripple="false"
        depressed
        :color="color"
        small
        :outlined="outlined"
        @click="$emit('click')"
    >
        <slot></slot>
    </v-btn>
</template>
<script>
export default {
    props: {
        color: String,
        outlined: Boolean
    }
}
</script>