<template>
    <div>
        <div class="d-none">
            <div ref="sheetreceipt">
                <OSheetReceiptComp
                    :work="work"
                />
            </div>
        </div>
        <div class="section-print">
            <div class="pa-2 d-flex align-center">
                <h1 class="text-body-title">TRABAJOS</h1>
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    <OBtnHeaderToolTipComp
                        v-if="tab!=2"
                        smsTooltip="Registrar trabajo concluido"
                        icon="mdi-plus"
                        @click="tab=2"
                    />
                    <OBtnHeaderToolTipComp
                        v-if="tab!=0"
                        smsTooltip="Volver a la lista de trabajos."
                        icon="mdi-database"
                        class="ml-1"
                        @click="ac_backDataBaseWorks()"
                    />
                </div>
            </div>
            <MLineHeaderComp />
            <v-tabs-items
                style="background:transparent !important"
                v-model="tab"
                :touchless="true"
            >
                <v-tab-item>
                    <div class="d-flex pa-2 pb-0">
                        <h1 class="text-body-title">Expresado en:</h1>
                        <h1 class="text-body-subtitle ml-1">Bolivianos</h1>
                    </div>
                    <div class="pa-2 pt-0 o-header-work-payments">
                        <div class="o-header-work-payments__first">
                            <div class="d-flex">
                                <h1 class="text-body-title">Total Honorarios:</h1>
                                <h1 class="text-body-subtitle ml-1">
                                    {{m_calculation_totalIncome(listWorks, 1).toFixed(2)}}
                                </h1>
                            </div>
                            <div class="d-flex">
                                <h1 class="text-body-title">Total Cancelado:</h1>
                                <h1 class="text-body-subtitle ml-1">
                                    {{m_calculation_totalCancelledDate(listWorks, 1).toFixed(2)}}
                                </h1>
                            </div>
                            <div class="d-flex">
                                <h1 class="text-body-title">Total Adeudado:</h1>
                                <h1 class="text-body-subtitle ml-1">
                                    {{m_calculation_totalOwed(listWorks, 1).toFixed(2)}}
                                </h1>
                            </div>
                        </div>
                        <div class="o-header-work-payments__first">
                            <div class="d-flex">
                                <h1 class="text-body-title">Total Fondos Impuestos:</h1>
                                <h1 class="text-body-subtitle ml-1">
                                    {{m_calculation_totalIncome(listWorks, 0).toFixed(2)}}
                                </h1>
                            </div>
                            <div class="d-flex">
                                <h1 class="text-body-title">Total Cancelado:</h1>
                                <h1 class="text-body-subtitle ml-1">
                                    {{m_calculation_totalCancelledDate(listWorks, 0).toFixed(2)}}
                                </h1>
                            </div>
                            <div class="d-flex">
                                <h1 class="text-body-title">Total Adeudado:</h1>
                                <h1 class="text-body-subtitle ml-1">
                                    {{m_calculation_totalOwed(listWorks, 0).toFixed(2)}}
                                </h1>
                            </div>
                        </div>
                        <div :class="viewTable ? '' : 'mt-2'">
                            <ODateOneComp
                                v-model="dateWork"
                                :hideDetails="true"
                                label="Fecha de Trabajos"
                                :styleDate="viewTable ? 'width:200px; max-width:200px;' : 'width:100%'"
                                @change="ac_changeDateWork"
                            />
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <OLoaderDataComp
                        v-if="dialogLoaderData"
                        class="mt-2"
                        smsLoaderData="Obteniendo Trabajos"
                    />
                    <MNoDataComp
                        smsNotData="SIN DATOS"
                        class="ma-1"
                        v-if="listWorks.length==0 && !dialogLoaderData && !viewTable"
                    />
                    <div class="d-flex justify-center mt-3" v-if="viewTable">
                        <STableWorkComp
                            v-if="!dialogLoaderData"
                            :listWorks="listWorks"
                            @clickpayments="ac_showListPayments"
                            @clickdiscard="ac_confirmDiscardWork"
                            @ckickdetail="ac_showDetailWork"
                            @clickprint="ac_printReceiptWork"
                        />
                    </div>
                    <div class="d-flex justify-center" v-else>
                        <div
                            v-if="!dialogLoaderData"
                            class="d-flex justify-center flex-wrap"
                            style="width:100%;"
                        >
                            <SCardWorkComp
                                v-for="(work, index) in listWorks"
                                :key="index"
                                class="ma-2"
                                :work="work"
                                @clickpayments="ac_showListPayments(work)"
                                @clickdiscard="ac_confirmDiscardWork(work)"
                                @ckickdetail="ac_showDetailWork(work)"
                                @clickprint="ac_printReceiptWork(work)"
                            />
                        </div>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <OHeaderWorkPaymentsComp
                        :work="work"
                    />
                    <v-divider></v-divider>
                    <OLoaderDataComp
                        v-if="dialogLoaderDataPayments"
                        class="mt-2"
                        smsLoaderData="Obteniendo Pagos"
                    />
                    <MNoDataComp
                        smsNotData="SIN DATOS"
                        class="ma-1"
                        v-if="listPayments.length==0 && !dialogLoaderDataPayments && !viewTable"
                    />
                    <div class="d-flex justify-center mt-3" v-if="viewTable">
                        <STablePaymentsComp
                            v-if="!dialogLoaderDataPayments"
                            :listPayments="listPayments"
                            :isTable="false"
                        />
                    </div>
                    <div class="d-flex justify-center" v-else>
                        <div
                            v-if="!dialogLoaderDataPayments"
                            class="d-flex justify-center flex-wrap"
                            style="width:100%"
                        >
                            <SCardPaymentComp
                                class="ma-2"
                                v-for="(payment, index) in listPayments"
                                :key="index"
                                :payment="payment"
                                :isTable="false"
                            />
                        </div>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <h1 class="text-body-title text-center mt-2">
                        FORMULARIO DE REGISTRO</h1>
                    <div :style="viewTable ? '' : 'width:100%'" class="d-flex justify-center mt-3">
                        <div class="d-flex pl-2 pr-2" :style="viewTable ? '' : 'width:100%'">
                            <v-form
                                ref="formwork"
                                v-model="valid"
                                lazy-validation
                                class="works__form-work"
                            >
                                <div :class="errorSelectClient ? 'd-flex mr-2 pb-2' : 'd-flex mr-2 pb-6'">
                                    <div
                                        v-if="viewTable"
                                        class="expenses__title-form"
                                    >
                                        <h1 class="text-body-title text-right mr-1 pt-2">
                                            Cliente:</h1>
                                    </div>
                                    <div class="d-flex" :style="viewTable ? 'width:240px' : 'width:100%'">
                                        <div style="width:100%" class="mr-1 works__client-form">
                                            <div :class="errorSelectClient ? 'works__client-form--danger' : 'works__client-form--normal'">
                                                <h1 class="text-body-subtitle">
                                                    {{dataWork.client.full_name!=null ? dataWork.client.full_name : 'Sin cliente'}}</h1>
                                            </div>
                                            <p class="mb-0" v-if="errorSelectClient">El cliente es requerido</p>
                                        </div>
                                        <OBtnHeaderToolTipComp
                                            smsTooltip="Añadir cliente"
                                            icon="mdi-plus"
                                            style="margin-top:2px"
                                            @click="dialogClient=true"
                                        />
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div
                                        v-if="viewTable"
                                        class="expenses__title-form"
                                    >
                                        <h1 class="text-body-title text-right mr-1 pt-2">
                                            Tipo Trabajo:</h1>
                                    </div>
                                    <MSelectComp
                                        :singleLine="viewTable ? true : false"
                                        :label="viewTable ? '' : 'Tipo Trabajo'"
                                        :style="viewTable ? 'width:240px; max-width:240px;' : ''"
                                        v-model="dataWork.fkfeetype"
                                        :items="listFee"
                                        itemText="fee"
                                        itemValue="id"
                                        :rules="[v => !m_rule_emptyField(v) || 'El tipo de trabajo es requerido']"
                                    />
                                </div>
                                <div class="d-flex">
                                    <div
                                        v-if="viewTable"
                                        class="expenses__title-form"
                                    >
                                        <h1 class="text-body-title text-right mr-1 pt-2">
                                            Periodo:</h1>
                                    </div>
                                    <MTextFieldComp
                                        :singleLine="viewTable ? true : false"
                                        :label="viewTable ? '' : 'Periodo'"
                                        :style="viewTable ? 'width:240px; max-width:240px;' : ''"
                                        :clearable="true"
                                        v-model="dataWork.period"
                                        :counter="20"
                                        :rules="[v => (v+'').length<=20 || 'El periodo no debe exceder los 10 dígitos']"
                                    />
                                </div>
                                <div class="d-flex">
                                    <div
                                        v-if="viewTable"
                                        class="expenses__title-form"
                                    >
                                        <h1 class="text-body-title text-right mr-1 pt-2">
                                            Estado Trabajo:</h1>
                                    </div>
                                    <MSelectComp
                                        :singleLine="viewTable ? true : false"
                                        :label="viewTable ? '' : 'Estado Trabajo'"
                                        :style="viewTable ? 'width:240px; max-width:240px;' : ''"
                                        v-model="dataWork.concluded"
                                        :items="listStateWork"
                                        itemText="title"
                                        itemValue="value"
                                        :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido']"
                                    />
                                </div>
                                <div class="d-flex">
                                    <div
                                        v-if="viewTable"
                                        class="expenses__title-form"
                                    >
                                        <h1 class="text-body-title text-right mr-1 pt-2">
                                            Inf. Adicional:</h1>
                                    </div>
                                    <v-textarea
                                        :singleLine="viewTable ? true : false"
                                        :label="viewTable ? '' : 'Inf. Adicional'"
                                        :style="viewTable ? 'width:240px; max-width:240px;' : ''"
                                        v-model="dataWork.description"
                                        outlined
                                        class="mt-2"
                                        height="70"
                                        clearable
                                        counter="100"
                                        :rules="[v => (v+'').length<=100 || 'La información adicional no debe exceder los 100 dígitos']"
                                    ></v-textarea>
                                </div>
                                <div class="d-flex">
                                    <div
                                        v-if="viewTable"
                                        class="expenses__title-form"
                                    >
                                        <h1 class="text-body-title text-right mr-1 pt-2">
                                            Fecha Trabajo:</h1>
                                    </div>
                                    <ODateOneComp
                                        :singleLine="viewTable ? true : false"
                                        :label="viewTable ? '' : 'Fecha Trabajo'"
                                        :styleDate="viewTable ? 'width:240px; max-width:240px;' : ''"
                                        v-model="dateNewWork"
                                        :hideDetails="true"
                                        @change="ac_changeDateNewWork"
                                    />
                                </div>
                                <v-divider class="mt-2 mb-2"></v-divider>
                                <div @click="dialogHonoraryTax=true" class="works__form-honorary">
                                    <div style="width:100%" class="mr-1 works__client-form">
                                        <div class="works__client-form--normal">
                                            <h1 class="text-body-subtitle">
                                                Añadir Honorario/Impuesto (pulse aqui)
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-center mt-2 mb-2">
                                    <table style="width:100%" class="s-table-fee-type animation-opacity" border="0">
                                        <thead>
                                            <th>Concepto</th>
                                            <th>Precio</th>
                                            <th>Cancelado</th>
                                            <th>Saldo</th>
                                            <th>x</th>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(payment, index) in dataWork.detail_money"
                                                :key="index"
                                            >
                                                <td class="s-table-fee-type__border-line">
                                                    {{payment.honorary==1 ? 'Honorario' : payment.tax}}</td>
                                                <td class="s-table-fee-type__border-line" style="text-align:right">
                                                    {{payment.price.toFixed(2)}}</td>
                                                <td class="s-table-fee-type__border-line" style="text-align:right">
                                                    {{payment.payment.toFixed(2)}}</td>
                                                <td class="s-table-fee-type__border-line" style="text-align:right">
                                                    {{(payment.price-payment.payment).toFixed(2)}}</td>
                                                <td>
                                                    <v-btn
                                                        :ripple="false"
                                                        style="width:24px; height:24px;"
                                                        color="red"
                                                        fab
                                                        depressed
                                                        @click="ac_removeFromDetail(index)"
                                                    >x</v-btn>
                                                </td>
                                            </tr>
                                            <tr v-if="dataWork.detail_money.length==0">
                                                <td colspan="5" class="text-center">SIN DATOS</td>
                                            </tr>
                                            <tr v-else>
                                                <th class="text-center">TOTAL :</th>
                                                <td class="s-table-fee-type__border-line text-right">
                                                    {{ac_totalPriceWork.toFixed(2)}}
                                                </td>
                                                <td class="s-table-fee-type__border-line text-right">
                                                    {{ac_totalCancelledWork.toFixed(2)}}
                                                </td>
                                                <td class="s-table-fee-type__border-line text-right">
                                                    {{ac_totalDebt.toFixed(2)}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="d-flex justify-center mb-1">
                                    <MBtnNormalComp
                                        :outlined="true"
                                        color="primary"
                                        @click="ac_confirmActionWork()"
                                        class="mr-1"
                                    >REGISTRAR</MBtnNormalComp>
                                </div>
                            </v-form>
                            <div class="ml-4 works__container-detail">
                                <v-form
                                    ref="formpayment"
                                    v-model="validHonorary"
                                    lazy-validation
                                    class="works__form-work"
                                    style="width:240px"
                                >
                                    <div class="d-flex align-center mb-4">
                                        <h1 class="text-body-title">
                                            {{viewPayment ? 'Honorario' : 'Impuestos'}}</h1>
                                        <v-spacer></v-spacer>
                                        <OBtnHeaderToolTipComp
                                            :smsTooltip="viewPayment ? 'Cambiar a impuestos' : 'Cambiar a Honorario'"
                                            icon="mdi-arrow-right-box"
                                            @click="ac_changeFormPayment()"
                                        />
                                    </div>
                                    <div v-if="viewPayment">
                                        <div class="d-flex mt-2">
                                            <MTextFieldComp
                                                :clearable="true"
                                                v-model="dataDetailMoney.price"
                                                label="Honorario (Bs.)"
                                                class="mr-1"
                                                :counter="10"
                                                :rules="[
                                                    v => !m_rule_emptyField(v) || 'Es requerido',
                                                    v => m_rule_numberDecimal(v) || 'Debe ser un número',
                                                    v => (v+'').length<=10 || 'No debe exceder los 10 dígitos']"
                                            />
                                            <OBtnHeaderToolTipComp
                                                smsTooltip="Obtener tarifa"
                                                icon="mdi-account-cash"
                                                :loading="loadingBtnRate"
                                                style="margin-top:2px"
                                                @click="ac_getRateClient()"
                                            />
                                            <div style="position:relative" v-if="dataRateClient.exist!=undefined">
                                                <div class="works__rate-client">
                                                    <div class="works__rate-client-sms">
                                                        <h1 class="text-body-subtitle">{{smsRateClient}}</h1>
                                                        <div class="text-center mt-1">
                                                            <MBtnNormalComp
                                                                v-if="dataRateClient.exist==1"
                                                                color="warning"
                                                                class="mr-2"
                                                                @click="ac_addRateFormWork()"
                                                            >AGREGAR</MBtnNormalComp>
                                                            <MBtnNormalComp
                                                                color="error"
                                                                @click="ac_closeSmsRate()"
                                                            >x</MBtnNormalComp>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex mt-2">
                                            <MTextFieldComp
                                                :clearable="true"
                                                v-model="dataDetailMoney.payment"
                                                label="Cancelado (Bs.)"
                                                class="mr-1"
                                                :counter="10"
                                                :rules="[
                                                    v => !m_rule_emptyField(v) || 'Es requerido',
                                                    v => m_rule_numberDecimal(v) || 'Debe ser un número',
                                                    v => !m_rule_exceededNumber(v, dataDetailMoney.price) || 'No mayor a honorario',
                                                    v => (v+'').length<=10 || 'No debe exceder los 10 dígitos']"
                                            />
                                            <OBtnHeaderToolTipComp
                                                smsTooltip="Agregar Honorario"
                                                icon="mdi-check-bold"
                                                @click="ac_addDetailMoneyWork(true)"
                                            />
                                        </div>
                                    </div>
                                    <div v-else>
                                        <MSelectComp
                                            v-model="dataDetailMoney.tax"
                                            :items="listTaxs"
                                            label="Impuesto"
                                            itemText="tax"
                                            itemValue="tax"
                                            :rules="[v => !m_rule_emptyField(v) || 'El impuesto es requerido']"
                                        />
                                        <MTextFieldComp
                                            :clearable="true"
                                            v-model="dataDetailMoney.price"
                                            label="Precio (Bs.)"
                                            class="mt-2"
                                            :counter="10"
                                            :rules="[
                                                v => !m_rule_emptyField(v) || 'Es requerido',
                                                v => m_rule_numberDecimal(v) || 'Debe ser un número',
                                                v => (v+'').length<=10 || 'No debe exceder los 10 dígitos']"
                                        />
                                        <div class="d-flex mt-2">
                                            <MTextFieldComp
                                                :clearable="true"
                                                v-model="dataDetailMoney.payment"
                                                label="Cancelado (Bs.)"
                                                class="mr-1"
                                                :counter="10"
                                                :rules="[
                                                    v => !m_rule_emptyField(v) || 'Es requerido',
                                                    v => m_rule_numberDecimal(v) || 'Debe ser un número',
                                                    v => !m_rule_exceededNumber(v, dataDetailMoney.price) || 'No mayor a honorario',
                                                    v => (v+'').length<=10 || 'No debe exceder los 10 dígitos']"
                                            />
                                            <OBtnHeaderToolTipComp
                                                smsTooltip="Agregar Impuesto"
                                                icon="mdi-check-bold"
                                                @click="ac_addDetailMoneyWork(false)"
                                            />
                                        </div>
                                    </div>
                                </v-form>
                            </div>
                        </div>
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <v-dialog
            v-model="dialogClient"
            persistent
            width="500"
            content-class="dialog--margin"
        >
            <v-card class="ma-0 pa-0">
                <ODialogHeaderComp
                    title="Ingresar Cliente"
                    @clickclose="ac_closeFormClient(true)"
                />
                <div class="d-flex align-center pa-2">
                    <OBtnHeaderToolTipComp
                        v-if="!optionFormClient"
                        smsTooltip="Nuevo cliente"
                        icon="mdi-plus"
                        @click="optionFormClient=true"
                    />
                    <OBtnHeaderToolTipComp
                        v-if="optionFormClient"
                        smsTooltip="Volver a la lista de clientes"
                        icon="mdi-arrow-left-bold"
                        @click="optionFormClient=false"
                    />
                    <OSearchComp
                        v-else
                        v-model="searchClient"
                        label="CI/NIT - Nombre"
                        class="ml-1"
                        @search="ac_seachClient(1)"
                    />
                </div>
                <v-divider></v-divider>
                <div class="pa-2 pt-4" v-if="optionFormClient">
                    <SFormClientComp
                        :client="dataClient"
                        :listTypeCompany="listTypeCompany"
                        @clickaction="ac_selectNewCustomer"
                    />
                </div>
                <div class="works__list-clients mt-2" v-else>
                    <MNoDataComp
                        smsNotData="SIN CLIENTES"
                        class="ma-1"
                        v-if="listClients.length==0 && !dialogLoaderDataClient"
                    />
                    <OLoaderDataComp
                        v-if="dialogLoaderDataClient"
                        class="mt-2"
                        smsLoaderData="Obteniendo Clientes"
                    />
                    <div
                        v-for="(client, index) in listClients"
                        :key="index"
                        class="works__client-selection animation-opacity ma-2"
                        @click="ac_selectedClient(client)"
                    >
                        <div class="d-flex">
                            <h1 class="text-body-subtitle">
                            CI/NIT: {{client.ci_nit}}</h1>
                            <div style="heigth:100%; width:5px; background:black; border-radius:3px;" class="mr-1 ml-1"></div>
                            <h1 class="text-body-subtitle">{{client.full_name}}</h1>
                        </div>
                    </div>
                    <SPaginationComp
                        class="mt-2"
                        v-if="!dialogLoaderDataClient && listClients.length>0"
                        :condicionGo="page_client < last_page_client"
                        :condicionBack="page_client > 1"
                        :titlePagination="'Pag. '+page_client+' de '+last_page_client"
                        @clickback="ac_changePage(false)"
                        @clickgo="ac_changePage(true)"
                    />
                </div>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogDetailWork"
            width="650px"
            persistent
            content-class="dialog--margin"
        >
            <v-card class="ma-0 pa-0" v-if="work.client!=undefined">
                <ODialogHeaderComp
                    :title="work.client.full_name"
                    @clickclose="dialogDetailWork=false"
                />
                <SDetailWorkComp
                    :work="work"
                    :isInfo="true"
                />
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogHonoraryTax"
            persistent
            content-class="dialog--margin"
        >
            <v-card class="ma-0 pa-0">
                <ODialogHeaderComp
                    title="Añadir Honorario/Impuesto"
                    @clickclose="ac_closeDialogHonoraryTax()"
                />
                <v-form
                    ref="formpayment"
                    v-model="validHonorary"
                    lazy-validation
                    class="pa-2"
                >
                    <div class="d-flex align-center mb-4">
                        <h1 class="text-body-title">
                            {{viewPayment ? 'Honorario' : 'Impuestos'}}</h1>
                        <v-spacer></v-spacer>
                        <OBtnHeaderToolTipComp
                            :smsTooltip="viewPayment ? 'Cambiar a impuestos' : 'Cambiar a Honorario'"
                            icon="mdi-arrow-right-box"
                            @click="ac_changeFormPayment()"
                        />
                    </div>
                    <div v-if="viewPayment">
                        <div class="d-flex mt-2" style="position:relative">
                            <MTextFieldComp
                                :clearable="true"
                                v-model="dataDetailMoney.price"
                                label="Honorario (Bs.)"
                                class="mr-1"
                                :counter="10"
                                :rules="[
                                    v => !m_rule_emptyField(v) || 'Es requerido',
                                    v => m_rule_numberDecimal(v) || 'Debe ser un número',
                                    v => (v+'').length<=10 || 'No debe exceder los 10 dígitos']"
                            />
                            <OBtnHeaderToolTipComp
                                smsTooltip="Obtener tarifa"
                                icon="mdi-account-cash"
                                :loading="loadingBtnRate"
                                style="margin-top:2px"
                                @click="ac_getRateClient()"
                            />
                            <div v-if="dataRateClient.exist!=undefined">
                                <div class="works__rate-client">
                                    <div class="works__rate-client-sms">
                                        <h1 class="text-body-subtitle">{{smsRateClient}}</h1>
                                        <div class="text-center mt-1">
                                            <MBtnNormalComp
                                                v-if="dataRateClient.exist==1"
                                                color="warning"
                                                class="mr-2"
                                                @click="ac_addRateFormWork()"
                                            >AGREGAR</MBtnNormalComp>
                                            <MBtnNormalComp
                                                color="error"
                                                @click="ac_closeSmsRate()"
                                            >x</MBtnNormalComp>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <MTextFieldComp
                            :clearable="true"
                            v-model="dataDetailMoney.payment"
                            label="Cancelado (Bs.)"
                            class="mr-1 mt-2"
                            :counter="10"
                            :rules="[
                                v => !m_rule_emptyField(v) || 'Es requerido',
                                v => m_rule_numberDecimal(v) || 'Debe ser un número',
                                v => !m_rule_exceededNumber(v, dataDetailMoney.price) || 'No mayor a honorario',
                                v => (v+'').length<=10 || 'No debe exceder los 10 dígitos']"
                        />
                        <ODialogActionComp
                            actiontext="Agregar Honorario"
                            @clickaction="ac_addDetailMoneyWork(true)"
                        />
                    </div>
                    <div v-else>
                        <MSelectComp
                            v-model="dataDetailMoney.tax"
                            :items="listTaxs"
                            label="Impuesto"
                            itemText="tax"
                            itemValue="tax"
                            :rules="[v => !m_rule_emptyField(v) || 'El impuesto es requerido']"
                        />
                        <MTextFieldComp
                            :clearable="true"
                            v-model="dataDetailMoney.price"
                            label="Precio (Bs.)"
                            class="mt-2"
                            :counter="10"
                            :rules="[
                                v => !m_rule_emptyField(v) || 'Es requerido',
                                v => m_rule_numberDecimal(v) || 'Debe ser un número',
                                v => (v+'').length<=10 || 'No debe exceder los 10 dígitos']"
                        />
                        <MTextFieldComp
                            :clearable="true"
                            v-model="dataDetailMoney.payment"
                            label="Cancelado (Bs.)"
                            class="mt-2"
                            :counter="10"
                            :rules="[
                                v => !m_rule_emptyField(v) || 'Es requerido',
                                v => m_rule_numberDecimal(v) || 'Debe ser un número',
                                v => !m_rule_exceededNumber(v, dataDetailMoney.price) || 'No mayor a honorario',
                                v => (v+'').length<=10 || 'No debe exceder los 10 dígitos']"
                        />
                        <ODialogActionComp
                            actiontext="Agregar Impuesto"
                            @clickaction="ac_addDetailMoneyWork(false)"
                        />
                    </div>
                </v-form>
            </v-card>
        </v-dialog>
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_closeSmsError()"
            @clickyes="ac_performOperation()"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Realizando Operación"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :colorMessage="colorMessage"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MNoDataComp,
    MTextFieldComp,
    MSelectComp,
    MBtnNormalComp
} from '../../components/molecules'
import {
    OSmsConfirmComp,
    OSmsErrorComp,
    OLoaderOperationComp,
    OSmsActionComp,
    OLoaderDataComp,
    OBtnHeaderToolTipComp,
    ODialogHeaderComp,
    ODialogActionComp,
    OSearchComp,
    OSheetReceiptComp,
    OHeaderWorkPaymentsComp,
    ODateOneComp
} from '../../components/organisms'
import {
    SCardWorkComp,
    STableWorkComp,
    SCardPaymentComp,
    STablePaymentsComp,
    SPaginationComp,
    SFormClientComp,
    SDetailWorkComp
} from '../../components/species'
import {
    rulesMixin,
    responseServer,
    dataGeneralComponetsMixin,
    calculationsWorkMixin,
    datesMixin,
    userMixin
} from '../../mixins'
import {
    Work,
    FeeType,
    Client,
    Rate,
    Tax,
    TypeCompany,
    DetailMoney,
    Payment
} from '../../models'
export default {
    mixins: [
        rulesMixin,
        responseServer,
        dataGeneralComponetsMixin,
        calculationsWorkMixin,
        datesMixin,
        userMixin
    ],
    components: {
        OSmsConfirmComp,
        OSmsErrorComp,
        OLoaderOperationComp,
        OSmsActionComp,
        OBtnHeaderToolTipComp,
        MLineHeaderComp,
        MNoDataComp,
        OLoaderDataComp,
        SCardWorkComp,
        STableWorkComp,
        SCardPaymentComp,
        STablePaymentsComp,
        MTextFieldComp,
        MSelectComp,
        ODialogHeaderComp,
        ODialogActionComp,
        OSearchComp,
        SPaginationComp,
        MBtnNormalComp,
        SDetailWorkComp,
        OSheetReceiptComp,
        OHeaderWorkPaymentsComp,
        SFormClientComp,
        ODateOneComp
    },
    data: () => ({
        tab: 0,
        viewTable: true,
        dataWork : new Work(),
        listWorks: [],
        work: {},
        dialogFormWork: true,
        valid: true,
        dataClient: new Client(),
        listFee: [],
        dialogClient: false,
        listClients: [],
        page_client: 0,
        last_page_client: 0,
        dialogLoaderDataClient: false,
        searchClient: '',
        optionFormClient: false,
        validClient: true,
        errorSelectClient: false,
        indexWork: -1,
        dialogDetailWork: false,
        loadingBtnRate: false,
        smsRateClient: '',
        colorMessage: '',
        dataRateClient: {},
        listTaxs: [],
        listTypeCompany: [],
        listStateWork: [
            {title: 'Ejecutado', value: 1},
            {title: 'No ejecutado', value: 0}
        ],
        dateWork: '',
        listPayments: [],
        dialogLoaderDataPayments: false,
        validHonorary: true,
        viewPayment: true,
        dateNewWork: '',
        dataDetailMoney: new DetailMoney(),
        dialogHonoraryTax: false
    }),
    computed: {
        ac_totalPriceWork: function() {
            var totalPrice = 0
            for (var i=0; i<this.dataWork.detail_money.length; i++) {
                totalPrice += this.dataWork.detail_money[i].price
            }
            return totalPrice
        },
        ac_totalCancelledWork: function () {
            var totalCancelled = 0
            for (var i=0; i<this.dataWork.detail_money.length; i++) {
                totalCancelled += this.dataWork.detail_money[i].payment
            }
            return totalCancelled
        },
        ac_totalDebt: function() {
            return this.ac_totalPriceWork-this.ac_totalCancelledWork
        }
    },
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_enableActionBtn(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        getRequest() {
            this.dialogLoaderData = true
            const listFee = FeeType.getListFeeType()
            const listWorks = Work.getListWorks('')
            const listTaxs = Tax.getListTaxs()
            const listTypeCompany = TypeCompany.getListTypeCompany()
            Promise.all([listFee, listWorks, listTaxs, listTypeCompany]).then(responses => {
                this.listFee = responses[0]
                this.listWorks = responses[1]
                this.listTaxs = responses[2]
                this.listTypeCompany = responses[3]
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_showListPayments(work) {
            this.work = work
            this.tab = 1
            this.dialogLoaderDataPayments = true
            Payment.getListPaymentsWork(work.id).then(response => {
                this.listPayments = response
                this.dialogLoaderDataPayments = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_seachClient(page) {
            this.listClients = []
            this.dialogLoaderDataClient = true
            Client.getListClients(page, this.searchClient, 0).then(response => {
                this.listClients = response.data
                this.page_client = response.current_page
                this.last_page_client = response.last_page
                this.dialogLoaderDataClient = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_closeFormClient(errorSelectClient) {
            this.dialogClient = false
            if (errorSelectClient) {
                if (this.dataWork.client.id === null) this.errorSelectClient = true
            } else this.errorSelectClient = false
            setTimeout(() => {
                this.listClients = []
                this.page_client = 0
                this.last_page_client = 0
                this.searchClient = ''
                this.optionFormClient = false
                this.dataClient = new Client()
            }, 200)
        },
        ac_changePage(state) {
            if (state) { ++this.page_client }
            else { --this.page_client }
            this.ac_seachClient(this.page_client)
        },
        ac_selectNewCustomer(state_form) {
            this.dataRateClient = false
            this.dataWork.client = Object.assign({}, this.dataClient)
            this.dataWork.newClient = true
            this.ac_closeFormClient(state_form)
        },
        ac_selectedClient(client) {
            this.dataRateClient = false
            this.dataWork.client = Object.assign({}, client)
            this.dataWork.newClient = false
            this.ac_closeFormClient(false)
        },
        validateDataWork() {
            if (!this.$refs.formwork.validate()) return false
            return true
        },
        activateSmsError(smsError) {
            this.smsError = [smsError]
            this.actionError = true
            this.dialogError = true
        },
        getCanceledStatus() {
            for (var i = 0; i < this.dataWork.detail_money.length; i++) {
                if (this.dataWork.detail_money[i].cancelled === 0) return 0
            }
            return 1
        },
        ac_confirmActionWork() {
            window.scroll(0, 0)
            setTimeout(() => {
                if (!this.validateDataWork() || this.dataWork.client.full_name === null || this.dataWork.detail_money.length === 0) {
                    if (this.dataWork.client.full_name === null) { this.errorSelectClient = true }
                    if (this.dataWork.detail_money.length === 0) this.activateSmsError('Revise los datos marcados con rojo, introduzca el honorario y corrija porfavor')
                    else this.activateSmsError('Revise los datos marcados con rojo y corrija porfavor')
                } else {
                    const fee = this.listFee.filter(fee => {
                        return fee.id === this.dataWork.fkfeetype
                    })
                    this.dataWork.cancelled = this.getCanceledStatus()
                    const period = this.dataWork.period !== null ? this.dataWork.period : 'S/P'
                    this.smsConfirm = [
                        `Cliente: ${this.dataWork.client.full_name}`,
                        `Tipo Honorario: ${fee[0].fee}`,
                        `Periodo: ${period}`,
                        `Fecha de Registro: ${this.dateNewWork}`
                    ]
                    this.dialogConfirm = true
                }
            }, 100)
        },
        ac_backDataBaseWorks() {
            this.dataRateClient = false
            this.indexWork = -1
            if (this.tab === 2) {
                setTimeout(() => {
                    this.dataWork = new Work()
                    this.dataWork.work_date = this.m_date_systemDate(false)
                    this.$refs.formwork.resetValidation()
                    this.errorSelectClient = false
                }, 200)
            }
            this.tab = 0
        },
        showEvent(message, colorSms) {
            this.smsAction = message
            this.colorMessage = colorSms
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        addWork() {
            Work.addWork(this.dataWork).then(response => {
                this.work = response
                this.dialogLoaderOperation = false
                this.listWorks.unshift(response)
                this.ac_backDataBaseWorks()
                this.showEvent(['Operación exitosa',
                    `Se registró el trabajo`], 'success')
                if (window.innerWidth > 600) {
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.print(this.$refs.sheetreceipt);
                        }, 500)
                    })
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        discardedWork() {
            Work.discardedWork({'id': this.work.id}).then(() => {
                this.listWorks.splice(this.indexWork, 1)
                this.indexWork = -1
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa',
                    `El trabajo fue anulado`], 'success')
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_performOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            if (this.indexWork !== -1) this.discardedWork()
            else this.addWork()
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('agapeconsultores')
                this.$router.replace('/login')
            } else {
                this.indexWork = -1
                this.dialogConfirm = false
                this.dialogError = false
            }
        },
        ac_showDetailWork(work) {
            this.work = work
            this.dialogDetailWork = true
        },
        ac_confirmDiscardWork(work) {
            this.indexWork = this.listWorks.indexOf(work)
            this.work = work
            this.smsConfirm = ['¿Está seguro de anular el trabajo realizado para:',
                `${work.client.full_name}?`]
            this.dialogConfirm = true
        },
        ac_printReceiptWork(work) {
            if (window.innerWidth <= 600) {
                this.showEvent(['Operación no valida',
                    `Solo puede imprimirse en PC`], 'error')
            } else {
                this.work = work
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.print(this.$refs.sheetreceipt);
                    }, 500)
                })
            }
        },
        print(element) {
            let printSection = document.getElementById('printSection')
            var domClone = element.cloneNode(true)
            var nodoImg = document.createElement("img")
            nodoImg.setAttribute("src", '/imgs/logo_print.jpg')
            nodoImg.onload = function() {
                printSection.appendChild(domClone)
                window.print()
                printSection.innerHTML = ""
            }
        },
        //new metodhs
        ac_getRateClient() {
            this.dataRateClient = {}
            if (this.dataWork.client.id !== null && this.dataWork.fkfeetype !== null) {
                this.loadingBtnRate = true
                Rate.rateClient(this.dataWork.client.id, this.dataWork.fkfeetype).then(response => {
                    if (response.exist !== 0) this.smsRateClient = `Tarifa: ${response.rate.toFixed(2)} Bs., para ${response.fee}`
                    else this.smsRateClient = `El cliente no tiene una tarifa establecida para el tipo de trabajo`
                    setTimeout(() => {
                        this.dataRateClient = response
                        this.loadingBtnRate = false
                    }, 150)
                }).catch(error => {
                    this.processError(error)
                })
            } else {
                this.m_dataGeneral_frameTimeReset()
                setTimeout(() => {
                    this.showEvent(['Debe seleccionar el cliente',
                    `y el tipo de trabajo`], 'error')
                }, 200)
            }
        },
        ac_addRateFormWork() {
            this.dataDetailMoney.price = this.dataRateClient.rate
            setTimeout(() => {
                this.dataRateClient = {}
            }, 200)
        },
        ac_closeSmsRate() {
            this.dataRateClient = {}
        },
        ac_changeDateWork(e) {
            this.dialogLoaderData = true
            this.dateWork = this.m_date_formatDate(e)
            Work.getListWorks(e).then(response => {
                this.listWorks = response
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        validateData() {
            if (!this.$refs.formpayment.validate()) return false
            return true
        },
        thereIsAFee() {
            for(var i = 0; i < this.dataWork.detail_money.length; i++) {
                if (this.dataWork.detail_money[i].honorary === 1) return true
            }
            return false
        },
        thereIsTax(tax) {
            for(var i = 0; i < this.dataWork.detail_money.length; i++) {
                if (this.dataWork.detail_money[i].tax === tax) return true
            }
            return false
        },
        ac_closeDialogHonoraryTax() {
            this.m_dataGeneral_frameTimeReset()
            this.dataDetailMoney = new DetailMoney()
            this.$refs.formpayment.resetValidation()
            this.dialogHonoraryTax = false
        },
        ac_addDetailMoneyWork(isHonorary) {
            this.m_dataGeneral_frameTimeReset()
            const validData = isHonorary ? this.thereIsAFee() : this.thereIsTax(this.dataDetailMoney.tax)
            if (this.validateData() && !validData) {
                const cancelled = (parseFloat(this.dataDetailMoney.price)-parseFloat(this.dataDetailMoney.payment)) > 0 ? 0 : 1
                const paramaters = {
                    'price': parseFloat(this.dataDetailMoney.price),
                    'payment': parseFloat(this.dataDetailMoney.payment),
                    'cancelled': cancelled,
                    'honorary': isHonorary ? 1 : 0,
                    'tax': this.dataDetailMoney.tax
                }
                this.dataWork.detail_money.push(paramaters)
                this.dataDetailMoney = new DetailMoney()
                this.$refs.formpayment.resetValidation()
                const smsSuccess = this.viewPayment ? ['Se añadio el Honorario'] : ['Se añadio el Impuesto']
                this.showEvent(smsSuccess, 'success')
            } else {
                var smsError = ['Corrija los datos', `marcados con rojo`]
                if (validData) {
                    if (isHonorary) smsError = ['Solo puede agregar', 'un honorario']
                    else smsError = [`El impuesto: ${this.dataDetailMoney.tax}`, 'ya está agregado']
                }
                setTimeout(() => {
                    this.showEvent(smsError, 'error')
                }, 200)
            }
        },
        ac_removeFromDetail(index) {
            this.dataWork.detail_money.splice(index, 1)
        },
        ac_changeFormPayment() {
            this.dataDetailMoney = new DetailMoney()
            this.$refs.formpayment.resetValidation()
            this.viewPayment = !this.viewPayment
        },
        ac_changeDateNewWork(e) {
            this.dateNewWork = this.m_date_formatDate(e)
            this.dataWork.work_date = e
        }
    },
    mounted() {
        if (this.m_user_saveToken()) {
            this.dateWork = this.m_date_systemDate(true)
            this.dateNewWork = this.m_date_systemDate(true)
            this.dataWork.work_date = this.m_date_systemDate(false)
            this.getRequest()
        } else this.$router.replace('/login')
        if (window.innerWidth < 600) this.viewTable = false
    }
}
</script>