<template>
    <table class="s-table-fee-type animation-opacity" border="0">
        <thead>
            <th>Cancelado</th>
            <th>Cliente</th>
            <th>Trabajo</th>
            <th>Periodo</th>
            <th>Fecha Trabajo</th>
            <th>Pago por</th>
            <th>Fecha Registro</th>
        </thead>
        <tbody>
            <tr
                v-for="(payment, index) in payments"
                :key="index"
            >
                <td class="s-table-fee-type__border-line">
                    {{payment.payment.toFixed(2)}}</td>
                <td class="s-table-fee-type__border-line">
                    {{payment.detail_money.work.client.full_name}}</td>
                <td class="s-table-fee-type__border-line">
                    {{payment.detail_money.work.fee.fee}}</td>
                <td class="s-table-fee-type__border-line">
                    {{payment.detail_money.work.period != null
                        ? payment.detail_money.work.period
                        : 'S/P'
                    }}</td>
                <td class="s-table-fee-type__border-line">
                    {{payment.detail_money.work.date}}</td>
                <td class="s-table-fee-type__border-line">
                    {{payment.detail_money.honorary==1
                        ? 'Honorario'
                        : `Impuesto: ${payment.detail_money.tax}`
                    }}</td>
                <td class="s-table-fee-type__border-line">
                    {{payment.hours+' '+payment.date}}</td>
            </tr>
            <tr v-if="payments.length==0" class="text-center">
                <td colspan="7">SIN DATOS</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
export default {
    props: {
        payments: Array
    }
}
</script>