<template>
    <table class="s-table-fee-type animation-opacity" border="0">
        <thead>
            <th>Tipo Trabajo</th>
            <th>Periodo</th>
            <th>Adeudado</th>
            <th>Cancelado</th>
            <th>Saldo</th>
            <th>Fecha de registro</th>
            <th>Día registro</th>
            <th>Info. Adicional</th>
            <th>N° Recibo</th>
        </thead>
        <tbody>
            <tr
                v-for="(payment, index) in listPayments"
                :key="index"
            >
                <td class="s-table-fee-type__border-line text-center">
                    {{payment.detail_money.work.fee.fee}}</td>
                <td class="s-table-fee-type__border-line text-center">
                    {{payment.detail_money.work.period!=null ? payment.detail_money.work.period : 'S/P'}}</td>
                <td class="s-table-fee-type__border-line text-center" style="text-align:right">
                    {{payment.pre_balance.toFixed(2)}}</td>
                <td class="s-table-fee-type__border-line text-center" style="text-align:right">
                    {{payment.payment.toFixed(2)}}</td>
                <td class="s-table-fee-type__border-line text-center" style="text-align:right">
                    {{(payment.pre_balance-payment.payment).toFixed(2)}}</td>
                <td class="s-table-fee-type__border-line text-center">
                    {{payment.hours+' '+payment.date}}</td>
                <td class="s-table-fee-type__border-line text-center">
                    {{payment.pay_day}}</td>
                <td class="s-table-fee-type__border-line text-center">
                    {{payment.detail_money.work.description != null ? payment.detail_money.work.description : 'S/D'}}</td>
                <td class="s-table-fee-type__border-line text-center">
                    {{payment.fkdetailpayment}}</td>
            </tr>
            <tr v-if="listPayments.length==0" class="text-center">
                <td colspan="9">SIN PAGOS</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
export default {
    props: {
        listPayments: Array
    }
}
</script>