import axios from 'axios'
import { apiUrls } from '../plugins/apiUrls'
import { userStore } from '../store/modules'

export default class FeeType{
    constructor(
        fee = null
    ){
        this.fee = fee
    }
    static getListFeeType() {
        return axios.get(
            apiUrls.FEED_TYPE + '/list',
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static addFeeType(parameters) {
        return axios.post(
            apiUrls.FEED_TYPE + '/add',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static updateHonorary(parameters) {
        return axios.put(
            apiUrls.FEED_TYPE + '/update',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static deleteHonorary(parameters) {
        return axios.delete(
            apiUrls.FEED_TYPE + '/delete',
            { headers: userStore.state.header, data: parameters }
        )
    }
}