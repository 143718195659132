import axios from 'axios'
import { apiUrls } from '../plugins/apiUrls'
import { userStore } from '../store/modules'

export default class Client{
    constructor(
        id = null,
        full_name = null,
        address = null,
        ci_nit = null,
        phone = null,
        main_activity = null,
        closing_month = null,
        fktypecompany = null
    ){
        this.id = id
        this.full_name = full_name
        this.address = address
        this.ci_nit = ci_nit
        this.phone = phone
        this.main_activity = main_activity
        this.closing_month = closing_month
        this.fktypecompany = fktypecompany
    }
    static getListClients(page, search, fktypecompany) {
        return axios.get(
            apiUrls.CLIENT + '/list?page=' + page + '&search=' + search + '&fktypecompany='+fktypecompany,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static addClient(parameters) {
        return axios.post(
            apiUrls.CLIENT + '/add',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static updateClient(parameters) {
        return axios.put(
            apiUrls.CLIENT + '/update',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static getListClientsDebts(page, search) {
        return axios.get(
            apiUrls.CLIENT + '/list-with-works?page=' + page + '&search=' + search,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static changeState(parameters) {
        return axios.put(
            apiUrls.CLIENT + '/change-state',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static getListHistory(page, ci_nit, date1, date2) {
        return axios.get(
            apiUrls.CLIENT + '/payments-history?page=' + page + '&ci_nit=' + ci_nit + '&date1='+date1+'&date2='+date2,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static getCancellationsHistory(page, ci_nit, date1, date2) {
        return axios.get(
            apiUrls.CLIENT + '/cancellation-history?page=' + page + '&ci_nit=' + ci_nit + '&date1='+date1+'&date2='+date2,
            { headers: userStore.state.header }
        ).then(response => {
                return response.data
        })
    }
}