<template>
    <div class="d-flex" style="width:100%">
        <MTextFieldComp
            :label="label"
            :hideDetails="true"
            :singleLine="true"
            :value="value"
            background="white"
            :clearable="true"
            @click="$emit('search')"
            @input="(e)=>(this.$emit('input', e))"
        />
        <v-btn
            class="ml-1"
            fab
            small
            :ripple="false"
            depressed
            color="primary"
            @click="$emit('search')"
        >
            <v-icon small color="white">mdi-magnify</v-icon>
        </v-btn>
    </div>
</template>
<script>
import { MTextFieldComp } from '../molecules'
export default {
    components: {
        MTextFieldComp
    },
    props: {
        value: String,
        label: String
    },
    model: {
        prop: 'value',
        event: 'input'
    }
}
</script>
