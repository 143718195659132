<template>
    <div class="s-card-fee-type animation-opacity pa-2">
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Cancelado:</h1>
            <h1 class="text-body-subtitle">
                {{payment.payment.toFixed(2)}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Cliente:</h1>
            <h1 class="text-body-subtitle">
                {{payment.detail_money.work.client.full_name}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Trabajo:</h1>
            <h1 class="text-body-subtitle">
                {{payment.detail_money.work.fee.fee}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Periodo:</h1>
            <h1 class="text-body-subtitle">
                {{payment.detail_money.work.period != null
                    ? payment.detail_money.work.period
                    : 'S/P'
                }}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Fecha Trabajo:</h1>
            <h1 class="text-body-subtitle">
                {{payment.detail_money.work.date}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Pago por:</h1>
            <h1 class="text-body-subtitle">
                {{payment.detail_money.honorary==1
                    ? 'Honorario'
                    : `Impuesto: ${payment.detail_money.tax}`
                }}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Fecha Registro:</h1>
            <h1 class="text-body-subtitle">{{payment.hours+' '+payment.date}}</h1>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        payment: {}
    }
}
</script>