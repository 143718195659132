<template>
    <table class="s-table-fee-type animation-opacity" border="0">
        <thead>
            <th>Usuario</th>
            <th>Categoría</th>
            <th>Acciones</th>
        </thead>
        <tbody>
            <tr
                v-for="(user, index) in listUsers"
                :key="index"
            >
                <td class="s-table-fee-type__border-line text-center">{{user.username}}</td>
                <td class="s-table-fee-type__border-line text-center">
                    {{user.categorie==0 ? 'ADMINISTRADOR' : 'USUARIO'}}</td>
                <td>
                    <div class="d-flex justify-center">
                        <OBtnOptionToolTipComp
                            smsTooltip="Cambiar contraseña"
                            icon="mdi-lead-pencil"
                            style="margin:2px"
                            @click="$emit('clickupdate', user)"
                        />
                    </div>
                </td>
            </tr>
            <tr v-if="listUsers.length==0" class="text-center">
                <td colspan="2">SIN DATOS</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import { OBtnOptionToolTipComp } from '../organisms'
export default {
    props: {
        listUsers: Array
    },
    components: {
        OBtnOptionToolTipComp
    }
}
</script>