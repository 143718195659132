<template>
    <div>
        <v-form
            v-on:submit.prevent="ac_selectNewCustomer()"
            ref="formclient"
            v-model="validClient"
            lazy-validation
            class="pa-2 pb-0"
        >
            <MTextFieldComp
                label="Cliente"
                :clearable="true"
                v-model="client.full_name"
                :counter="50"
                :rules="[
                    v => !m_rule_emptyField(v) || 'El cliente es requerido',
                    v => (v+'').length<=50 || 'El cliente no debe exceder los 50 dígitos']"
            />
            <MTextFieldComp
                label="CI/NIT"
                :clearable="true"
                v-model="client.ci_nit"
                :counter="20"
                :rules="[
                    v => !m_rule_emptyField(v) || 'El CI/NIT es requerido',
                    v => m_rule_numberInteger(v) || 'El CI/NIT solo debe contener números',
                    v => (v+'').length<=20 || 'El CI/NIT no debe exceder los 20 dígitos'
                ]"
            />
            <MTextFieldComp
                label="Dirección"
                :clearable="true"
                v-model="client.address"
                :counter="50"
                :rules="[v => (v+'').length<=50 || 'La dirección no debe exceder los 50 dígitos']"
            />
            <MTextFieldComp
                label="Celular"
                :clearable="true"
                v-model="client.phone"
                :counter="15"
                :rules="[v => ac_validPhone(v) || 'El celular debe ser un número',
                    v => (v+'').length<=15 || 'El celular no debe exceder los 15 dígitos']"
            />
            <MSelectComp
                label="Tipo Empresa"
                :items="listTypeCompany"
                v-model="client.fktypecompany"
                itemValue="id"
                itemText="type_company"
                :rules="[v => !m_rule_emptyField(v) || 'La dato es requerido']"
            />
            <MTextFieldComp
                label="Mes de Cierre"
                :clearable="true"
                v-model="client.closing_month"
                :counter="30"
                :rules="[v => (v+'').length<=30 || 'El mes de cierre no debe exceder los 15 dígitos']"
            />
            <v-textarea
                outlined
                label="Actividad Principal"
                height="80"
                v-model="client.main_activity"
                :counter="400"
                :rules="[v => (v+'').length<=400 || 'La actividad principal no debe exceder los 400 dígitos']"
            ></v-textarea>
        </v-form>
        <ODialogActionComp
            actiontext="Seleccionar"
            @clickaction="ac_selectNewCustomer()"
        />
    </div>
</template>
<script>
import { MTextFieldComp, MSelectComp } from '../molecules'
import { ODialogActionComp } from '../organisms'
import { rulesMixin } from '../../mixins'
export default {
    mixins: [ rulesMixin ],
    components: {
        MTextFieldComp,
        ODialogActionComp,
        MSelectComp
    },
    props: {
        client: Object,
        listTypeCompany: Array
    },
    data: () => ({
        validClient: true
    }),
    methods: {
        ac_validPhone(input) {
            if (this.m_rule_emptyField(input)) return true
            else return this.m_rule_numberInteger(input)
        },
        validateData() {
            if (!this.$refs.formclient.validate()) return false
            return true
        },
        ac_selectNewCustomer() {
            if (this.validateData()) {
                this.$emit('clickaction', false)
            }
        }
    }
}
</script>