<template>
    <div>
        <div class="clients__header">
            <div class="clients__header-first">
                <h1 class="text-body-title">IMPUESTOS</h1>
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    <MTextFieldComp
                        label="Buscar"
                        :hideDetails="true"
                        class="history-client__search"
                        background="white"
                        :singleLine="true"
                        :clearable="true"
                        v-model="search"
                    />
                    <OBtnHeaderToolTipComp
                        smsTooltip="Registrar impuesto"
                        icon="mdi-plus"
                        class="ml-1"
                        @click="dialogTax=true"
                    />
                </div>
            </div>
        </div>
        <MLineHeaderComp />
        <OLoaderDataComp
            v-if="dialogLoaderData"
            class="mt-2"
            smsLoaderData="Obteniendo Lista"
        />
        <MNoDataComp
            smsNotData="SIN DATOS"
            class="ma-1"
            v-if="listTaxFilter.length==0 && !dialogLoaderData && !viewTable"
        />
        <div class="d-flex justify-center mt-3" v-if="viewTable">
            <STableTaxComp
                v-if="!dialogLoaderData"
                :listTaxs="listTaxFilter"
                @clickupdate="ac_openFormUpdate"
            />
        </div>
        <div class="d-flex justify-center flex-wrap" v-else>
            <SCardTaxComp
                v-for="(tax, index) in listTaxFilter"
                :key="index"
                :tax="tax"
                class="ma-2"
                @clickupdate="ac_openFormUpdate(tax)"
            />
        </div>
        <v-dialog
            width="400"
            v-model="dialogTax"
            persistent
            content-class="dialog--margin"
        >
            <v-card class="pa-0 ma-0">
                <v-form
                    v-on:submit.prevent="ac_sendValidForm()"
                    ref="formtax"
                    lazy-validation
                    v-model="valid"
                >
                    <ODialogHeaderComp
                        :title="indexTax!=-1 ? 'Actualizar impuesto' : 'Nuevo impuesto'"
                        @clickclose="ac_closeForm()"
                    />
                    <div class="pa-2 pb-0 pt-4">
                        <MTextFieldComp
                            label="Impuesto"
                            :clearable="true"
                            v-model="dataTax.tax"
                            :counter="50"
                            :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                                    v => (v+'').length<=50 || 'El impuesto no puede superar los 50 dígitos']"
                        />
                    </div>
                    <ODialogActionComp
                        actiontext="Guardar"
                        @clickaction="ac_sendValidForm()"
                    />
                </v-form>
            </v-card>
        </v-dialog>
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_cancelConfirmation()"
            @clickyes="ac_performOperation()"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_cancelConfirmation()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            :smsLoaderOperation="smsLoaderOperation"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MLineHeaderComp,
    MNoDataComp
} from '../../components/molecules'
import {
    OBtnHeaderToolTipComp,
    OSmsConfirmComp,
    OSmsErrorComp,
    OLoaderOperationComp,
    OSmsActionComp,
    ODialogHeaderComp,
    ODialogActionComp,
    OLoaderDataComp
} from '../../components/organisms'
import {
    STableTaxComp,
    SCardTaxComp
} from '../../components/species'
import { Tax } from '../../models'
import {
    dataGeneralComponetsMixin,
    rulesMixin,
    responseServer,
    userMixin
} from '../../mixins'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        rulesMixin,
        responseServer,
        userMixin
    ],
    components: {
        MTextFieldComp,
        MLineHeaderComp,
        OBtnHeaderToolTipComp,
        OSmsConfirmComp,
        OSmsErrorComp,
        OLoaderOperationComp,
        OSmsActionComp,
        MNoDataComp,
        STableTaxComp,
        ODialogHeaderComp,
        MTextFieldComp,
        ODialogActionComp,
        OLoaderDataComp,
        SCardTaxComp
    },
    data: () => ({
        viewTable: true,
        search: '',
        dialogTax: false,
        listTaxs: [],
        valid: true,
        dataTax: new Tax(),
        indexTax: -1
    }),
    computed: {
        listTaxFilter: function() {
            if (this.m_rule_emptyField(this.search)) return this.listTaxs
            else {
                return this.listTaxs.filter(tax => {
                    return tax.tax.toLowerCase()
                            .indexOf(this.search.toLowerCase()) !== -1
                })
            }
        }
    },
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_enableActionBtn(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        getListTaxs() {
            this.dialogLoaderData = true
            Tax.getListTaxs().then(response => {
                this.listTaxs = response
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_closeForm() {
            this.dialogTax = false
            setTimeout(() => {
                this.indexTax = -1
                this.dataTax = new Tax()
                this.$refs.formtax.resetValidation()
            }, 200)
        },
        ac_cancelConfirmation() {
            if (this.noAccess) {
                localStorage.removeItem('agapeconsultores')
                this.$router.replace('/login')
            } else {
                this.dialogConfirm = false
                this.dialogError = false
                this.dialogTax = true
            }
        },
        validateData() {
            if (!this.$refs.formtax.validate()) return false
            return true
        },
        ac_sendValidForm() {
            this.dialogTax = false
            if (!this.validateData()) {
                this.smsError = ['Revise los datos marcados con rojo y corrija porfavor']
                this.actionError = true
                this.dialogError = true
            } else {
                this.smsConfirm = ['¿Está seguro(a) de registrar el impuesto?']
                this.dialogConfirm = true
            }
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        addTax() {
            Tax.addTax(this.dataTax).then(response => {
                this.listTaxs.push(response)
                this.showEvent(['Registro exitoso',
                    `Se registró el impuesto: ${response.tax}`])
                this.ac_closeForm()
                this.dialogLoaderOperation = false
            }).catch(error => {
                this.processError(error)
            })
        },
        updateTax() {
            Tax.updateTax(this.dataTax).then(response => {
                Object.assign(this.listTaxs[this.indexTax], response)
                this.showEvent(['Operación exitosa',
                    `Se editó el impuesto: ${response.tax}`])
                this.ac_closeForm()
                this.dialogLoaderOperation = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_performOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            if (this.indexTax === -1) this.addTax()
            else this.updateTax()
        },
        ac_openFormUpdate(tax) {
            this.indexTax = this.listTaxs.indexOf(tax)
            this.dataTax = Object.assign({}, tax)
            this.dialogTax = true
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            this.getListTaxs()
        } else this.$router.replace('/login')
    }
}
</script>