<template>
    <div class="s-card-fee-type animation-opacity pa-2">
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Tipo Honorario:</h1>
            <h1 class="text-body-subtitle">{{rate.feetype.fee}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Tarifa (Bs.):</h1>
            <h1 class="text-body-subtitle">{{rate.rate.toFixed(2)}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Client:</h1>
            <h1 class="text-body-subtitle">{{rate.client.full_name}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">CI/NIT:</h1>
            <h1 class="text-body-subtitle">{{rate.client.ci_nit}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Descripción:</h1>
            <h1 class="text-body-subtitle">
                {{rate.description!=null ? rate.description : 'S/D'}}</h1>
        </div>
        <div class="d-flex justify-center mt-1">
            <OBtnOptionToolTipComp
                smsTooltip="Actualizar Tarifa"
                icon="mdi-lead-pencil"
                class="mr-1"
                @click="$emit('clickupdate')"
            />
            <OBtnOptionToolTipComp
                smsTooltip="Eliminar Tarifa"
                icon="mdi-trash-can"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import {
    OBtnOptionToolTipComp
} from '../organisms'
export default {
    props: {
        rate: {}
    },
    components: {
        OBtnOptionToolTipComp
    }
}
</script>