<template>
    <div class="s-card-fee-type animation-opacity pa-2">
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Cliente:</h1>
            <text-highlight
                class="text-body-subtitle"
                :queries="searchClient"
            >
                {{client.full_name}}
            </text-highlight>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">CI/NIT:</h1>
            <text-highlight
                class="text-body-subtitle"
                :queries="searchClient"
            >   
                {{client.ci_nit}}
            </text-highlight>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Celular:</h1>
            <h1 class="text-body-subtitle">
                {{client.phone!=null ? client.phone : 'S/N'}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Mes de Cierre:</h1>
            <h1 class="text-body-subtitle">
                {{client.closing_month!=null ? client.closing_month : 'S/C'}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Tipo de Empresa:</h1>
            <h1 class="text-body-subtitle">
                {{client.company.type_company}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Estado:</h1>
            <h1 class="text-body-subtitle">
                {{client.enabled!=0 ? 'Habilitado' : 'Inhabilitado'}}</h1>
        </div>
        <div class="d-flex justify-center mt-1">
            <OBtnOptionToolTipComp
                smsTooltip="Información cliente"
                icon="mdi-format-list-bulleted"
                class="mr-1"
                @click="$emit('clickdetail')"
            />
            <OBtnOptionToolTipComp
                smsTooltip="Actualizar cliente"
                icon="mdi-lead-pencil"
                class="mr-1"
                @click="$emit('clickupdate')"
            />
            <OBtnOptionToolTipComp
                :smsTooltip="client.enabled!=0 ? 'Inhabilitar cliente' : 'Habilitar cliente'"
                :icon="client.enabled!=0 ? 'mdi-eye-off' : 'mdi-eye'"
                class="mr-1"
                @click="$emit('clickstate')"
            />
        </div>
    </div>
</template>
<script>
import {
    OBtnOptionToolTipComp
} from '../organisms'
import TextHighlight from 'vue-text-highlight'
export default {
    props: {
        client: {},
        searchClient: {
            type: String,
            default: ''
        }
    },
    components: {
        OBtnOptionToolTipComp,
        TextHighlight
    }
}
</script>