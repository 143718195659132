<template>
    <div>
        <div class="clients__header">
            <div class="clients__header-first">
                <h1 class="text-body-title">HISTORIAL PAGOS</h1>
                <v-spacer></v-spacer>
                <OSearchComp
                    label="CI/NIT"
                    class="history-client__search"
                    v-model="searchClient"
                    @search="ac_searchClient()"
                />
            </div>
            <div class="clients__header-second">
                <ODualDatesComp
                    class="history-client__date"
                    @onChangingDate="ac_searchDates"
                />
            </div>
        </div>
        <MLineHeaderComp />
        <div class="pa-2">
            <div class="d-flex">
                <h1 class="text-body-title">Total cantidad de pagos:</h1>
                <h1 class="text-body-subtitle ml-1">{{total}}</h1>
            </div>
        </div>
        <v-divider></v-divider>
        <div v-if="existClient" class="pa-2">
            <div class="d-flex">
                <h1 class="text-body-title mr-1">Expresado en:</h1>
                <h1 class="text-body-subtitle">Bolivianos</h1>
            </div>
            <div class="d-flex">
                <h1 class="text-body-title mr-1">Cliente:</h1>
                <h1 class="text-body-subtitle">{{client.full_name}}</h1>
            </div>
            <div class="d-flex">
                <h1 class="text-body-title mr-1">CI/NIT:</h1>
                <h1 class="text-body-subtitle">{{client.ci_nit}}</h1>
            </div>
            <div class="d-flex">
                <h1 class="text-body-title mr-1">Celular:</h1>
                <h1 class="text-body-subtitle">
                    {{client.phone!=null ? client.phone : 'S/N'}}</h1>
            </div>
            <div class="d-flex">
                <h1 class="text-body-title mr-1">Dirección:</h1>
                <h1 class="text-body-subtitle">
                    {{client.address!=null ? client.address : 'S/D'}}</h1>
            </div>
            <v-divider id="position-scroll" class="mt-1 mb-1"></v-divider>
            <div class="d-flex justify-center mt-3" v-if="viewTable">
                <STableHistoryClientComp
                    :listPayments="listPayments"
                />
            </div>
            <div class="d-flex justify-center" v-else>
                <div
                    v-if="!dialogLoaderData"
                    class="d-flex justify-center flex-wrap"
                    style="width:100%;"
                >
                    <SCardHistoryClientComp
                        v-for="(payment, index) in listPayments"
                        :key="index"
                        :payment="payment"
                        class="ma-2"
                    />
                </div>
            </div>
            <MNoDataComp
                v-if="!dialogLoaderData && listPayments.length==0 && !viewTable"
                smsNotData="SIN PAGOS REALIZADOS"
                class="mt-1"
            />
            <SPaginationComp
                class="mt-2"
                v-if="!dialogLoaderData && listPayments.length>0"
                :condicionGo="page < last_page"
                :condicionBack="page > 1"
                :titlePagination="'Pag. '+page+' de '+last_page"
                @clickback="ac_changePage(false)"
                @clickgo="ac_changePage(true)"
            />
        </div>
        <div v-else>
            <MNoDataComp
                v-if="!dialogLoaderData"
                smsNotData="SIN CLIENTE"
                class="ma-2"
            />
        </div>
        <OLoaderDataComp
            v-if="dialogLoaderData"
            class="mt-2"
            smsLoaderData="Obteniendo Historial de Pagos"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MNoDataComp
} from '../../components/molecules'
import {
    OSearchComp,
    OSmsErrorComp,
    OLoaderDataComp,
    ODateOneComp,
    ODualDatesComp
} from '../../components/organisms'
import {
    STableHistoryClientComp,
    SPaginationComp,
    SCardHistoryClientComp
} from '../../components/species'
import {
    dataGeneralComponetsMixin,
    responseServer,
    datesMixin,
    userMixin
} from '../../mixins'
import {
    Client
} from '../../models'
export default {
    mixins: [
        responseServer,
        dataGeneralComponetsMixin,
        datesMixin,
        userMixin
    ],
    components: {
        MLineHeaderComp,
        OSearchComp,
        OSmsErrorComp,
        OLoaderDataComp,
        ODateOneComp,
        ODualDatesComp,
        MNoDataComp,
        STableHistoryClientComp,
        SPaginationComp,
        SCardHistoryClientComp
    },
    data: () => ({
        searchClient: '',
        listPayments: [],
        page: 0,
        last_page: 0,
        total: 0,
        existClient: false,
        client: {},
        date1: '',
        date2: '',
        viewTable: true
    }),
    methods: {
        processError(error) {
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_enableActionBtn(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('agapeconsultores')
                this.$router.replace('/login')
            } else this.dialogError = false
        },
        getHistoryClient(page, search, date1, date2, topPos = 0) {
            this.listPayments = []
            this.dialogLoaderData = true
            this.existClient = false
            Client.getListHistory(page, search, date1, date2).then(response => {
                this.dialogLoaderData = true
                if (response.exist_client === 1) {
                    this.listPayments = response.data
                    this.page = response.page
                    this.last_page = response.last_page
                    this.total = response.total
                    this.client = response.client
                    this.existClient = true
                }
                this.dialogLoaderData = false
                if (window.innerWidth <= 600) {
                    setTimeout(() => {
                        window.scroll(0, topPos)
                    }, 150)
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_searchClient() {
            if (this.searchClient === null) this.searchClient = ''
            this.getHistoryClient(1, this.searchClient, this.date1, this.date2)
        },
        ac_searchDates(dates = null) {
            this.date1 = dates ? dates.date1 : ''
            this.date2 = dates ? dates.date2 : ''
            setTimeout(()=> {
                this.getHistoryClient(1, this.searchClient, this.date1, this.date2)
            }, 200)
        },
        ac_changePage(state) {
            var myElement = document.getElementById('position-scroll');
            var topPos = parseInt(myElement.offsetTop) - 45;
            if (state) { ++this.page }
            else { --this.page }
            if (window.innerWidth <= 600) {
                window.scroll(0, 0)
                setTimeout(() => {
                    this.getHistoryClient(this.page, this.searchClient, this.date1, this.date2, topPos)
                }, 100)
            } else this.getHistoryClient(this.page, this.searchClient, this.date1, this.date2, topPos)
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (!this.m_user_saveToken()) this.$router.replace('/login')
    }
}
</script>