import axios from 'axios'
import { apiUrls } from '../plugins/apiUrls'
import { userStore } from '../store/modules'

export default class DetailPayments{
    static getLastFiveReceipt() {
        return axios.get(
            apiUrls.DETAIL_PAYMENTS + '/last-five-receipt',
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
}