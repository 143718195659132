<template>
    <table class="s-table-fee-type animation-opacity" border="0">
        <thead>
            <th>Concepto</th>
            <th>Adeudado</th>
            <th>Cancelado</th>
            <th>Saldo</th>
            <th>Día Cancelado</th>
            <th>Fecha de Registro</th>
            <th>N° Recibo</th>
            <th v-if="isTable">Editar Pago</th>
        </thead>
        <tbody>
            <tr
                v-for="(payment, index) in listPayments"
                :key="index"
            >
                <td class="s-table-fee-type__border-line">
                    {{payment.detail_money.honorary==1 ? 'Honorario' : payment.detail_money.tax}}</td>
                <td class="s-table-fee-type__border-line" style="text-align:right">
                    {{payment.pre_balance.toFixed(2)}}</td>
                <td class="s-table-fee-type__border-line" style="text-align:right">
                    {{payment.payment.toFixed(2)}}</td>
                <td class="s-table-fee-type__border-line" style="text-align:right">
                    {{(payment.pre_balance-payment.payment).toFixed(2)}}</td>
                <td class="s-table-fee-type__border-line">{{payment.pay_day}}</td>
                <td class="s-table-fee-type__border-line">
                    {{payment.hours+' '+payment.date}}</td>
                <td class="s-table-fee-type__border-line">{{payment.fkdetailpayment}}</td>
                <td v-if="isTable">
                    <div class="d-flex justify-center flex-wrap">
                        <OBtnOptionToolTipComp
                            smsTooltip="Editar Trabajo"
                            icon="mdi-lead-pencil"
                            style="margin:2px"
                            @click="$emit('clickedit', payment)"
                        />
                    </div>
                </td>
            </tr>
            <tr v-if="listPayments.length==0" class="text-center">
                <td :colspan="isTable ? '8' : '7'">SIN PAGOS</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import { OBtnOptionToolTipComp } from '../organisms'
export default {
    props: {
        listPayments: Array,
        isTable: {
            type: Boolean,
            default: true
        }
    },
    components: {
        OBtnOptionToolTipComp
    }
}
</script>