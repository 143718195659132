<template>
    <v-form
        v-on:submit.prevent="ac_sendValidForm()"
        ref="formtypeexpense"
        lazy-validation
        v-model="valid"
    >
        <ODialogHeaderComp
            :title="indexTypeExpense!=-1 ? 'Actualizar tipo de Gasto' : 'Nuevo tipo de Gasto'"
            @clickclose="$emit('onClickCloseForm')"
        />
        <div class="pa-2 pb-0 pt-4">
            <MTextFieldComp
                label="Tipo de gasto"
                :clearable="true"
                v-model="dataTypeExpense.type_expense"
                :counter="50"
                :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                        v => (v+'').length<=50 || 'El tipo de gasto no debe exceder los 50 dígitos']"
            />
        </div>
        <ODialogActionComp
            actiontext="Guardar"
            @clickaction="ac_sendValidForm()"
        />
    </v-form>
</template>
<script>
import { MTextFieldComp } from '../molecules'
import {
    ODialogActionComp,
    ODialogHeaderComp
} from '../organisms'
import { rulesMixin } from '../../mixins'
export default {
    props: {
        dataTypeExpense: Object,
        indexTypeExpense: Number
    },
    mixins: [
        rulesMixin  
    ],
    components: {
        MTextFieldComp,
        ODialogActionComp,
        ODialogHeaderComp
    },
    data: () => ({
        valid: true
    }),
    methods: {
        validateData() {
            if (!this.$refs.formtypeexpense.validate()) return false
            return true
        },
        ac_sendValidForm() {
            var validForm = true
            if (!this.validateData()) {
                validForm = false
            }
            this.$emit('onClickForm', validForm)
        },
        resetForm() {
            this.$refs.formtypeexpense.resetValidation()
        },
        /*ac_closeForm() {
            this.$refs.formtypeexpense.resetValidation()
            this.$emit('onClickCloseForm')
        }*/
    }
}
</script>