<template>
    <table class="s-table-fee-type animation-opacity" border="0">
        <thead>
            <th>N°</th>
            <th>Concepto</th>
            <th>Periodo</th>
            <th width="120px">Deuda</th>
            <th width="180px">Monto a Cancelar</th>
            <th width="120px">Saldo</th>
            <th>Eliminar</th>
        </thead>
        <tbody>
            <tr
                v-for="(payment, index) in detailPayments"
                :key="index"
            >
                <td class="s-table-fee-type__border-line">{{(index+1)}}</td>
                <td class="s-table-fee-type__border-line">
                    {{payment.concept}}</td>
                <td class="s-table-fee-type__border-line">
                    {{payment.period}}</td>
                <td class="s-table-fee-type__border-line" style="text-align:right">
                    {{payment.debt.toFixed(2)}}</td>
                <td class="s-table-fee-type__border-line">
                    <div>
                    <MTextFieldComp
                        :singleLine="true"
                        style="width:180px; max-width:180px;"
                        v-model="payment.payment_amount"
                        :hideDetails="true"
                        :reverse="true"
                        :counter="10"
                        :rules="[
                            v => !m_rule_emptyField(v) || 'El dato es requerido',
                            v => m_rule_numberDecimal(v) || 'El pago debe ser un número',
                            v => !m_rule_exceededNumber(v, payment.debt) || 'El pago no debe superar el precio del trabajo',
                            v => m_rule_isZero(v) || 'El pago debe ser mayor a cero',
                            v => (v+'').length<=10 || 'El pago no debe exceder los 10 dígitos']"
                    />
                    </div>
                </td>
                <td class="s-table-fee-type__border-line" style="text-align:right">
                    {{(payment.debt-parseFloat(payment.payment_amount)).toFixed(2)}}</td>
                <td class="s-table-fee-type__border-line">
                    <div class="d-flex justify-center">
                        <v-btn
                            color="red"
                            depressed
                            :ripple="false"
                            fab
                            x-small
                            @click="$emit('clickdelete', index)"
                        >
                            <v-icon color="white">mdi-close</v-icon>
                        </v-btn>
                    </div>
                </td>
            </tr>
            <tr v-if="detailPayments.length>0" class="text-center">
                <th colspan="3">TOTALES</th>
                <th class="s-table-fee-type__border-line pa-1" style="text-align:right">{{totalDebt.toFixed(2)}}</th>
                <th class="s-table-fee-type__border-line pa-1" style="text-align:right">
                    {{totalPayment.toFixed(2)}}</th>
                <th class="s-table-fee-type__border-line pa-1" style="text-align:right">
                    {{totalSaldo.toFixed(2)}}</th>
            </tr>
            <tr v-if="detailPayments.length==0" class="text-center">
                <td colspan="7">SIN PAGOS</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import { MTextFieldComp } from '../../components/molecules'
import { rulesMixin } from '../../mixins'
export default {
    mixins: [ rulesMixin ],
    components: {
        MTextFieldComp
    },
    props: {
        detailPayments: Array
    },
    computed: {
        totalDebt: function() {
            var total = 0
            for(var i=0; i<this.detailPayments.length; i++) {
                total += parseFloat(this.detailPayments[i].debt)
            }
            return total
        },
        totalPayment: function() {
            var total = 0
            for(var i=0; i<this.detailPayments.length; i++) {
                total += parseFloat(this.detailPayments[i].payment_amount)
            }
            return total
        },
        totalSaldo: function() {
            return this.totalDebt-this.totalPayment
        }
    }
}
</script>