<template>
    <v-dialog
        persistent
        width="420"
        :value="value"
    >
        <v-card class="ma-0 pa-0">
            <ODialogHeaderComp
                title="Editar"
                @clickclose="$emit('clickclose')"
            />
            <div class="pa-2 pb-0" v-if="money.id != undefined">
                <h1 class="text-body-title">
                    {{money.tax!=null ? `Impuesto: ${money.tax}` : 'Honorario'}}
                </h1>
                <div class="d-flex">
                    <h1 class="text-body-title mr-1">Precio Anterior:</h1>
                    <h1 class="text-body-subtitle">{{`${money.previewPrice.toFixed(2)} Bs.`}}</h1>
                </div>
                <MTextFieldComp
                    class="mt-2"
                    :clearable="true"
                    v-model="money.price"
                    :label="money.tax!=null ? `Precio ${money.tax}` : 'Precio honorario'"
                    :counter="10"
                    @click="ac_validateForm()"
                    :rules="[
                        v => !m_rule_emptyField(v) || 'El precio es requerido',
                        v => m_rule_numberDecimal(v) || 'El precio debe ser un número',
                        v => (v+'').length<=10 || 'El precio no debe exceder los 10 dígitos']"
                />
            </div>
            <ODialogActionComp
                actiontext="Editar"
                @clickaction="ac_validateForm()"
            />
        </v-card>
    </v-dialog>
</template>
<script>
import {
    MTextFieldComp
} from '../molecules'
import {
    ODialogHeaderComp,
    ODialogActionComp
} from "../organisms"
import {
    rulesMixin
} from "../../mixins"
export default {
    mixins: [ rulesMixin ],
    components: {
        ODialogHeaderComp,
        ODialogActionComp,
        MTextFieldComp
    },
    props: {
        value: Boolean,
        money: Object
    },
    data: () => ({
        valid: true
    }),
    methods: {
        ac_validateForm() {
            if (this.m_rule_emptyField(this.money.price)
                || !this.m_rule_numberDecimal(this.money.price)) {
                    this.$emit('clickform', false);
            } else this.$emit('clickform', true);
        }
    }
}
</script>