<template>
    <div>
        <div class="pa-2 d-flex align-center">
            <h1 class="text-body-title">USUARIOS</h1>
        </div>
        <MLineHeaderComp />
        <OLoaderDataComp
            v-if="dialogLoaderData"
            class="mt-2"
            smsLoaderData="Obteniendo Lista"
        />
        <MNoDataComp
            smsNotData="SIN DATOS"
            class="ma-1"
            v-if="listUsers.length==0 && !dialogLoaderData && !viewTable"
        />
         <div class="d-flex justify-center mt-3" v-if="viewTable">
            <STableUserComp
                v-if="!dialogLoaderData"
                :listUsers="listUsers"
                @clickupdate="ac_openForm"
            />
        </div>
        <div class="d-flex justify-center flex-wrap" v-else>
            <SCardUserComp
                v-for="(user, index) in listUsers"
                :key="index"
                :user="user"
                class="ma-2"
                @clickupdate="ac_openForm(user)"
            />
        </div>
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_cancelConfirmation()"
            @clickyes="ac_performOperation()"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_cancelConfirmation()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            :smsLoaderOperation="smsLoaderOperation"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
        <v-dialog
            v-model="dialogForm"
            width="450"
            persistent
            content-class="dialog--margin"
        >
            <v-card class="pa-0 ma-0">
                <ODialogHeaderComp
                    title="Cambiar contraseña"
                    @clickclose="ac_closeForm()"
                />
                <v-form
                    v-model="valid"
                    v-on:submit.prevent="ac_sendFormUser()"
                    lazy-validation
                    ref="formuser"
                    class="pa-2 pt-4"
                >
                    <MTextFieldComp
                        v-model="password"
                        :clearable="true"
                        label="Contraseña"
                        :dense="true"
                        :type="showPassword ? 'text' : 'password'"
                        :icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @clicksee="showPassword = !showPassword"
                        @click="ac_sendFormUser()"
                        :rules="[v => !this.m_rule_emptyField(v) || 'El dato es requerido']"
                    />
                </v-form>
                <div class="text-center pb-3">
                    <MBtnNormalComp
                        color="primary"
                        @click="ac_sendFormUser()"
                    >CAMBIAR CONTRASEÑA</MBtnNormalComp>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MNoDataComp,
    MBtnNormalComp,
    MTextFieldComp
} from '../../components/molecules'
import {
    OLoaderDataComp,
    ODialogHeaderComp,
    OSmsConfirmComp,
    OSmsErrorComp,
    OLoaderOperationComp,
    OSmsActionComp
} from '../../components/organisms'
import {
    STableUserComp,
    SCardUserComp
} from '../../components/species'
import {
    userMixin,
    responseServer,
    dataGeneralComponetsMixin,
    rulesMixin
} from '../../mixins'
import { User } from '../../models'
import md5 from 'js-md5'
export default {
    mixins: [
        userMixin,
        responseServer,
        dataGeneralComponetsMixin,
        rulesMixin
    ],
    components: {
        OSmsConfirmComp,
        OSmsErrorComp,
        OLoaderOperationComp,
        OSmsActionComp,
        OLoaderDataComp,
        MLineHeaderComp,
        MNoDataComp,
        STableUserComp,
        MBtnNormalComp,
        MTextFieldComp,
        ODialogHeaderComp,
        SCardUserComp
    },
    data: () => ({
        password: null,
        dataUser: {},
        indexUser: -1,
        dialogForm: false,
        listUsers: [],
        showPassword: false,
        valid: true,
        viewTable: true
    }),
    methods: {
        processError(error) {
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_enableActionBtn(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
            this.dialogLoaderOperation = false
        },
        ac_cancelConfirmation() {
            if (this.noAccess) {
                localStorage.removeItem('agapeconsultores')
                this.$router.replace('/login')
            } else {
                this.dialogConfirm = false
                this.dialogError = false
                this.dialogForm = true
            }
        },
        ac_closeForm() {
            this.password = null
            this.$refs.formuser.resetValidation()
            this.dialogForm = false
        },
        getUsers() {
            this.dialogLoaderData = true
            User.list().then(response => {
                this.listUsers = response
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_openForm(user) {
            this.indexUser = this.listUsers.indexOf(user)
            this.dataUser = Object.assign({}, user)
            this.dialogForm = true
        },
        validateData() {
            if (!this.$refs.formuser.validate()) return false
            return true
        },
        ac_sendFormUser() {
            this.dialogForm = false
            if (!this.validateData()) {
                this.smsError = ['Revise los datos marcados con rojo y corrija porfavor']
                this.actionError = true
                this.dialogError = true
            } else {
                this.smsConfirm = [`Está seguro de cambiar la contraseña de: ${this.dataUser.username}?`]
                this.dialogConfirm = true
            }
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        ac_performOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            const dataSend = {
                id: this.dataUser.id,
                password: md5(this.password)
            }
            User.changePassword(dataSend).then(response => {
                Object.assign(this.listUsers[this.indexUser], response)
                this.ac_closeForm()
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa',
                    `Se actualizó la contraseña`])
            }).catch(error => {
                this.processError(error)
            })
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            this.getUsers()
        } else this.$router.replace('/login')
    } 
}
</script>
