<template>
    <div>
        <div class="clients__header">
            <div class="clients__header-first">
                <h1 class="text-body-title">TRABAJOS NO EJECUTADOS</h1>
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    <OSearchComp
                        label="Nombre o CI/NIT"
                        v-model="searchClient"
                        class="history-client__search"
                        @search="ac_searchClient()"
                    />
                </div>
            </div>
        </div>
        <MLineHeaderComp />
        <div class="pa-2">
            <div class="d-flex">
                <h1 class="text-body-title">Total de trabajos:</h1>
                <h1 class="text-body-subtitle ml-1">{{total}}</h1>
            </div>
        </div>
        <v-divider id="position-scroll"></v-divider>
        <OLoaderDataComp
            v-if="dialogLoaderData"
            class="mt-2"
            smsLoaderData="Obteniendo Trabajos"
        />
        <MNoDataComp
            smsNotData="SIN TRABAJOS"
            class="ma-1 mt-2"
            v-if="listWorks.length==0 && !dialogLoaderData && !viewTable"
        />
        <div class="d-flex justify-center mt-3" v-if="viewTable">
            <STableUnfinishedWorkComp
                v-if="!dialogLoaderData"
                :listWorks="listWorks"
                @ckickdetail="ac_showDetailWork"
                @clickconcluded="ac_changeConcluded"
            />
        </div>
        <div class="d-flex justify-center flex-wrap" v-else>
            <SCardUnfinishedWorkComp
                v-for="(work, index) in listWorks"
                :key="index"
                :work="work"
                class="ma-2"
                @ckickdetail="ac_showDetailWork(work)"
                @clickconcluded="ac_changeConcluded(work)"
            />
        </div>
        <SPaginationComp
            class="mt-2"
            v-if="!dialogLoaderData && listWorks.length > 0"
            :condicionGo="page < last_page"
            :condicionBack="page > 1"
            :titlePagination="'Pag. '+page+' de '+last_page"
            @clickback="ac_changePage(false)"
            @clickgo="ac_changePage(true)"
        />
        <v-dialog
            v-model="dialogDetailWork"
            width="650px"
            persistent
            content-class="dialog--margin"
        >
            <v-card class="ma-0 pa-0" v-if="work.client!=undefined">
                <ODialogHeaderComp
                    :title="work.client.full_name"
                    @clickclose="dialogDetailWork=false"
                />
                <SDetailWorkComp
                    :work="work"
                    :isInfo="true"
                />
            </v-card>
        </v-dialog>
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="dialogConfirm=false"
            @clickyes="ac_performOperation()"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Realizando Operación"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MNoDataComp
} from '../../components/molecules'
import {
    OSearchComp,
    OSmsErrorComp,
    OSmsConfirmComp,
    OLoaderOperationComp,
    OSmsActionComp,
    OLoaderDataComp,
    ODialogHeaderComp,
    OBtnHeaderToolTipComp
} from '../../components/organisms'
import {
    STableUnfinishedWorkComp,
    SPaginationComp,
    SCardUnfinishedWorkComp,
    SDetailWorkComp
} from '../../components/species'
import {
    dataGeneralComponetsMixin,
    responseServer,
    userMixin
} from '../../mixins'
import { Work } from '../../models'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        responseServer,
        userMixin
    ],
    components: {
        OSearchComp,
        MLineHeaderComp,
        OSmsErrorComp,
        OSmsConfirmComp,
        OLoaderOperationComp,
        OSmsActionComp,
        OLoaderDataComp,
        STableUnfinishedWorkComp,
        ODialogHeaderComp,
        SDetailWorkComp,
        SPaginationComp,
        OBtnHeaderToolTipComp,
        MNoDataComp,
        SCardUnfinishedWorkComp
    },
    data: () => ({
        searchClient: '',
        total: 0,
        page: 0,
        last_page: 0,
        listWorks: [],
        dialogDetailWork: false,
        work: {},
        indexWork: -1,
        viewTable: true
    }),
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_enableActionBtn(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('agapeconsultores')
                this.$router.replace('/login')
            } else this.dialogError = false
        },
        getWorks(page, search, topPos = 0) {
            this.listWorks = []
            this.dialogLoaderData = true
            Work.getListUnfinishedWorks(page, search).then(response => {
                this.listWorks = response.data
                this.page = response.page
                this.last_page = response.last_page
                this.total = response.total
                this.dialogLoaderData = false
                if (window.innerWidth <= 600) {
                    setTimeout(() => {
                        window.scroll(0, topPos)
                    }, 150)
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_showDetailWork(work) {
            this.work = work
            this.dialogDetailWork = true
        },
        ac_changeConcluded(work) {
            this.work = work
            this.indexWork = this.listWorks.indexOf(work)
            const smsState = work.concluded === 0 ? 'EJECUTADO' : 'NO EJECUTADO'
            this.smsConfirm = [`¿Está seguro de cambiar el trabajo a: ${smsState}, de: ${work.client.full_name}?`]
            this.dialogConfirm = true
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        ac_performOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            Work.changeConcluded({id: this.work.id}).then(response => {
                Object.assign(this.listWorks[this.indexWork], response)
                this.showEvent(['Operación exitosa',
                    `Se cambio el estado de ejecución del trabajo`])
                this.dialogLoaderOperation = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_changePage(state) {
            var myElement = document.getElementById('position-scroll');
            var topPos = parseInt(myElement.offsetTop) - 45;
            this.m_dataGeneral_frameTimeReset()
            if (this.searchClient===null) this.searchClient = ''
            if (state) { ++this.page }
            else { --this.page }
            if (window.innerWidth <= 600) {
                window.scroll(0, 0)
                setTimeout(() => {
                    this.getWorks(this.page, this.searchClient, topPos)
                }, 100)
            } else this.getWorks(this.page, this.searchClient)
        },
        ac_searchClient() {
            if (this.searchClient === null) this.searchClient = ''
            this.getWorks(1, this.searchClient)
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            this.getWorks(1, '')
        } else this.$router.replace('/login')
    }
}
</script>