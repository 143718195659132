<template>
    <div class="s-card-fee-type animation-opacity pa-2">
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Honorario:</h1>
            <h1 class="text-body-subtitle">{{fee.fee}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Fecha de registro:</h1>
            <h1 class="text-body-subtitle">{{fee.date}}</h1>
        </div>
        <div class="d-flex justify-center mt-1">
            <OBtnOptionToolTipComp
                smsTooltip="Actualizar Honorario"
                icon="mdi-lead-pencil"
                class="mr-1"
                @click="$emit('clickupdate')"
            />
            <OBtnOptionToolTipComp
                smsTooltip="Eliminar Honorario"
                icon="mdi-trash-can"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import {
    OBtnOptionToolTipComp
} from '../organisms'
export default {
    props: {
        fee: {}
    },
    components: {
        OBtnOptionToolTipComp
    }
}
</script>