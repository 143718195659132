<template>
    <table class="s-table-fee-type animation-opacity" border="0">
        <thead>
            <th>Tipo de Gasto</th>
            <th>Fecha de Registro</th>
            <th>Acciones</th>
        </thead>
        <tbody>
            <tr
                v-for="(expense, index) in listTypeExpense"
                :key="index"
            >
                <td class="s-table-fee-type__border-line">{{expense.type_expense}}</td>
                <td class="s-table-fee-type__border-line">{{expense.date}}</td>
                <td>
                    <div class="d-flex justify-center">
                        <OBtnOptionToolTipComp
                            smsTooltip="Actualizar Honorario"
                            icon="mdi-lead-pencil"
                            style="margin:2px"
                            @click="$emit('clickupdate', expense)"
                        />
                        <OBtnOptionToolTipComp
                            smsTooltip="Eliminar Honorario"
                            icon="mdi-trash-can"
                            style="margin:2px"
                            @click="$emit('clickdelete', expense)"
                        />
                    </div>
                </td>
            </tr>
            <tr v-if="listTypeExpense.length==0" class="text-center">
                <td colspan="3">SIN DATOS</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import { OBtnOptionToolTipComp } from '../organisms'
export default {
    props: {
        listTypeExpense: Array
    },
    components: {
        OBtnOptionToolTipComp
    }
}
</script>