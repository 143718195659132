import axios from 'axios'
import { apiUrls } from '../plugins/apiUrls'
import { userStore } from '../store/modules'

export default class Tax{
    constructor(
        tax = null
    ){
        this.tax = tax
    }
    static getListTaxs() {
        return axios.get(
            apiUrls.TAX + '/list',
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static addTax(parameters) {
        return axios.post(
            apiUrls.TAX + '/add',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static updateTax(parameters) {
        return axios.put(
            apiUrls.TAX + '/update',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
}