<template>
    <div class="s-card-fee-type animation-opacity pa-2">
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Tipo de empresa:</h1>
            <h1 class="text-body-subtitle">{{type_company.type_company}}</h1>
        </div>
        <div class="d-flex justify-center mt-1">
            <OBtnOptionToolTipComp
                smsTooltip="Actualizar tipo de empresa"
                icon="mdi-lead-pencil"
                class="mr-1"
                @click="$emit('clickupdate')"
            />
        </div>
    </div>
</template>
<script>
import {
    OBtnOptionToolTipComp
} from '../organisms'
export default {
    props: {
        type_company: {}
    },
    components: {
        OBtnOptionToolTipComp
    }
}
</script>