export default {
    methods: {
        m_calculation_totalIncome(listWorks, typeMoney) {
            var totalIncome = 0
            for(var i = 0; i < listWorks.length; i++) {
                totalIncome += this.m_calculation_totalHonorary(listWorks[i], typeMoney)
            }
            return totalIncome
        },
        m_calculation_totalHonorary(work, typeMoney) {
            var totalHonorary = 0
            for(var i=0; i<work.detail_money.length; i++) {
                if (work.detail_money[i].honorary === typeMoney) totalHonorary += work.detail_money[i].price
            }
            return totalHonorary
        },
        m_calculation_totalCancelledDate(listWorks, typeMoney) {
            var totalCancelled = 0
            for(var i = 0; i < listWorks.length; i++) {
                totalCancelled += this.m_calculation_totalPaymentsHonorary(listWorks[i], typeMoney)
            }
            return totalCancelled
        },
        m_calculation_totalPaymentsHonorary(work, typeMoney) {
            var totalPayments = 0
            for(var i=0; i<work.detail_money.length; i++) {
                if (work.detail_money[i].honorary === typeMoney) {
                    for(var k=0; k<work.detail_money[i].payments.length; k++) {
                        totalPayments += work.detail_money[i].payments[k].payment
                    }
                }
            }
            return totalPayments
        },
        m_calculation_totalOwed(listWorks, typeMoney) {
            const total = this.m_calculation_totalIncome(listWorks, typeMoney)-this.m_calculation_totalCancelledDate(listWorks, typeMoney)
            return total
        },
        m_calculation_totalCost(work) {
            var totalCost = 0
            for(var i=0; i < work.detail_money.length; i++) {
                totalCost += work.detail_money[i].price
            }
            return totalCost
        },
        
        m_calculation_totalCancelled(work) {
            var totalPayments = 0
            for(var i=0; i<work.detail_money.length; i++) {
                for(var k=0; k<work.detail_money[i].payments.length; k++) {
                    totalPayments += work.detail_money[i].payments[k].payment
                }
            }
            return totalPayments
        },
        m_calculation_sumPayments(payments) {
            var totalPayments = 0
            for (var i=0; i<payments.length; i++) {
                totalPayments += payments[i].payment
            }
            return totalPayments
        },
        m_calculation_sumPreBalance(payments) {
            var totalPreBalance = 0
            for (var i=0; i<payments.length; i++) {
                totalPreBalance += payments[i].pre_balance
            }
            return totalPreBalance
        }
    }
}