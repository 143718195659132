import axios from 'axios'
import { apiUrls } from '../plugins/apiUrls'
import Client from './Client'
import { userStore } from '../store/modules'

export default class Work {
    constructor(
        period = null,
        description = null,
        cancelled = 0,
        work_date = null,
        fkfeetype = null,
        client = new Client(),
        newClient = false,
        concluded = 1,
        detail_money = []
    ){
        this.period = period
        this.description = description
        this.cancelled = cancelled
        this.work_date = work_date
        this.fkfeetype = fkfeetype
        this.client = client
        this.newClient = newClient
        this.concluded = concluded
        this.detail_money = detail_money
    }
    static getListWorks(date) {
        return axios.get(
            apiUrls.WORK + '/list?date='+date,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static addWork(parameters) {
        return axios.post(
            apiUrls.WORK + '/add',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static discardedWork(parameters) {
        return axios.put(
            apiUrls.WORK + '/change-discard',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static getListWorkReport(page, date1, date2, id_fee, id_state, type_date) {
        return axios.get(
            `${apiUrls.WORK}/list-report?page=${page}&date1=${date1}&date2=${date2}&id_fee=${id_fee}
            &id_state=${id_state}&type_date=${type_date}`,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static reportPdf(date1, date2, id_fee, id_state, type_date, state, type_work, type_honorary) {
        window.open(`${apiUrls.WORK}/report-pdf?date1=${date1}&date2=${date2}&id_fee=${id_fee}
        &id_state=${id_state}&type_date=${type_date}&state=${state}
        &type_work=${type_work}&type_honorary=${type_honorary}`, '_blank')
    }
    static getListUnfinishedWorks(page, search) {
        return axios.get(
            apiUrls.WORK + '/list-unfinished?page='+page+'&search='+search,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static changeConcluded(parameters) {
        return axios.put(
            apiUrls.WORK + '/change-concluded',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static getListWorkClient(page, ci_nit) {
        return axios.get(
            apiUrls.WORK + '/list-work-client?page='+page+'&ci_nit='+ci_nit,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static totalWorkReport(page, date1, date2, id_fee, id_state, type_date) {
        return axios.get(
            `${apiUrls.WORK}/total-works?page=${page}&date1=${date1}&date2=${date2}&id_fee=${id_fee}
            &id_state=${id_state}&type_date=${type_date}`,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static balance(date1, date2) {
        return axios.get(
            apiUrls.WORK + '/balance?date1='+date1+'&date2='+date2,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static getListClientWorks(page, search) {
        return axios.get(
            `${apiUrls.WORK}/list-client-works?page=${page}&search=${search}`,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static getCloseBox(dateOne, dateTwo) {
        return axios.get(
            `${apiUrls.WORK}/close-box?date_one=${dateOne}&date_two=${dateTwo}`,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
}