<template>
    <div class="s-card-fee-type animation-opacity pa-2">
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Cliente:</h1>
            <text-highlight
                class="text-body-subtitle"
                :queries="searchClient"
            >
                {{client.full_name}}
            </text-highlight>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">CI/NIT</h1>
            <text-highlight
                class="text-body-subtitle"
                :queries="searchClient"
            >
                {{client.ci_nit}}
            </text-highlight>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Dirección:</h1>
            <h1 class="text-body-subtitle">
                {{client.address!=null ? client.address : 'S/D'}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Celular:</h1>
            <h1 class="text-body-subtitle">
                {{client.phone!=null ? client.phone : 'S/N'}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">N° de Deudas:</h1>
            <h1 class="text-body-subtitle">
                {{client.list_works.length}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Total Adeudado:</h1>
            <h1 class="text-body-subtitle">
                {{ac_totalDebts(client).toFixed(2)}}</h1>
        </div>
        <div class="d-flex justify-center mt-1">
            <OBtnOptionToolTipComp
                smsTooltip="Ver estado de deudas"
                icon="mdi-cash-minus"
                class="mr-1"
                @click="$emit('clickworks')"
            />
        </div>
    </div>
</template>
<script>
import {
    OBtnOptionToolTipComp
} from '../organisms'
import {
    calculationsWorkMixin
} from '../../mixins'
import TextHighlight from 'vue-text-highlight'
export default {
    mixins: [
        calculationsWorkMixin
    ],
    props: {
        client: {},
        searchClient: {
            type: String,
            default: ''
        }
    },
    components: {
        OBtnOptionToolTipComp,
        TextHighlight
    },
    methods:  {
        ac_totalDebts(client) {
            var totalDebts = 0
            for(var i = 0; i < client.list_works.length; i++) {
                const totalHonorary = this.m_calculation_totalHonorary(client.list_works[i], 1)
                const totalCancelledHonorary = this.m_calculation_totalPaymentsHonorary(client.list_works[i], 1)
                const totalTaxs = this.m_calculation_totalHonorary(client.list_works[i], 0)
                const totalCancelledTaxs = this.m_calculation_totalPaymentsHonorary(client.list_works[i], 0)
                totalDebts += (totalHonorary-totalCancelledHonorary)+(totalTaxs-totalCancelledTaxs)
            }
            return totalDebts
        }
    }
}
</script>