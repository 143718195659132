<template>
    <table class="s-table-fee-type animation-opacity" border="0">
        <thead>
            <th>Cliente</th>
            <th>CI/NIT</th>
            <th>Celular</th>
            <th>Mes de Cierre</th>
            <th>Tipo de Empresa</th>
            <th>Estado</th>
            <th>Acciones</th>
        </thead>
        <tbody>
            <tr
                v-for="(client, index) in listClients"
                :key="index"
            >
                <td class="s-table-fee-type__border-line">
                    <text-highlight
                        :queries="searchClient"
                    >
                        {{client.full_name}}
                    </text-highlight>
                </td>
                <td class="s-table-fee-type__border-line">
                    <text-highlight
                        :queries="searchClient"
                    >
                        {{client.ci_nit}}
                    </text-highlight>
                </td>
                <td class="s-table-fee-type__border-line">
                    {{client.phone!=null ? client.phone : 'S/N'}}</td>
                <td class="s-table-fee-type__border-line">
                    {{client.closing_month!=null ? client.closing_month : 'S/C'}}</td>
                <td class="s-table-fee-type__border-line">
                    {{client.company.type_company}}</td>
                <td class="s-table-fee-type__border-line">
                    {{client.enabled!=0 ? 'Habilitado' : 'Inhabilitado'}}</td>
                <td>
                    <div class="d-flex justify-center flex-wrap">
                        <OBtnOptionToolTipComp
                            smsTooltip="Información cliente"
                            icon="mdi-format-list-bulleted"
                            style="margin:2px"
                            @click="$emit('clickdetail', client)"
                        />
                        <OBtnOptionToolTipComp
                            smsTooltip="Actualizar Cliente"
                            icon="mdi-lead-pencil"
                            style="margin:2px"
                            @click="$emit('clickupdate', client)"
                        />
                        <OBtnOptionToolTipComp
                            :smsTooltip="client.enabled!=0 ? 'Inhabilitar cliente' : 'Habilitar cliente'"
                            :icon="client.enabled!=0 ? 'mdi-eye-off' : 'mdi-eye'"
                            style="margin:2px"
                            @click="$emit('clickstate', client)"
                        />
                    </div>
                </td>
            </tr>
            <tr v-if="listClients.length==0" class="text-center">
                <td colspan="7">SIN DATOS</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import { OBtnOptionToolTipComp } from '../organisms'
import TextHighlight from 'vue-text-highlight'
export default {
    props: {
        listClients: Array,
        searchClient: {
            type: String,
            default: ''
        }
    },
    components: {
        OBtnOptionToolTipComp,
        TextHighlight
    }
}
</script>