<template>
    <div>
        <div class="clients__header">
            <div class="clients__header-first">
                <h1 class="text-body-title">EDITAR TRABAJO</h1>
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    <OSearchComp
                        label="Nombre o CI/NIT"
                        class="history-client__search"
                        @search="ac_searchClient(1)"
                        v-model="search"
                    />
                    <OBtnHeaderToolTipComp
                        v-if="tab!=0"
                        smsTooltip="Volver a la lista de trabajos."
                        icon="mdi-database"
                        class="ml-1"
                        @click="tab=0"
                    />
                </div>
            </div>
        </div>
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <MLineHeaderComp id="position-scroll" />
                <OLoaderDataComp
                    v-if="dialogLoaderData"
                    class="mt-2"
                    smsLoaderData="Obteniendo Lista"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="listWorks.length==0 && !dialogLoaderData && !viewTable"
                />
                <div class="d-flex justify-center mt-3" v-if="viewTable">
                    <STableUnfinishedWorkComp
                        v-if="!dialogLoaderData"
                        :listWorks="listWorks"
                        :isTax="true"
                        :searchClient="searchFilter"
                        @ckickdetail="ac_showDetailWork"
                        @clickaddtax="ac_showDialogTax"
                        @clickpayments="ac_showListPayments"
                    />
                </div>
                <div class="d-flex justify-center flex-wrap" v-else>
                    <SCardUnfinishedWorkComp
                        v-for="(work, index) in listWorks"
                        :key="index"
                        :work="work"
                        class="ma-2"
                        :isTax="true"
                        :searchClient="searchFilter"
                        @ckickdetail="ac_showDetailWork(work)"
                        @clickaddtax="ac_showDialogTax(work)"
                    />
                </div>
                <SPaginationComp
                    class="mt-2"
                    v-if="!dialogLoaderData && listWorks.length > 0"
                    :condicionGo="page < last_page"
                    :condicionBack="page > 1"
                    :titlePagination="'Pag. '+page+' de '+last_page"
                    @clickback="ac_changePage(false)"
                    @clickgo="ac_changePage(true)"
                />
            </v-tab-item>
            <v-tab-item>
                <OHeaderWorkPaymentsComp
                    :work="work"
                />
                <v-divider></v-divider>
                <OLoaderDataComp
                    v-if="dialogLoaderDataPayments"
                    class="mt-2"
                    smsLoaderData="Obteniendo Pagos"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="listPayments.length==0 && !dialogLoaderDataPayments && !viewTable"
                />
                <div class="d-flex justify-center mt-3" v-if="viewTable">
                    <STablePaymentsComp
                        v-if="!dialogLoaderDataPayments"
                        :listPayments="listPayments"
                        @clickedit="ac_openFormEditPayment"
                    />
                </div>
                <div class="d-flex justify-center" v-else>
                    <div
                        v-if="!dialogLoaderDataPayments"
                        class="d-flex justify-center flex-wrap"
                        style="width:100%"
                    >
                        <SCardPaymentComp
                            class="ma-2"
                            v-for="(payment, index) in listPayments"
                            :key="index"
                            :payment="payment"
                            @clickedit="ac_openFormEditPayment(payment)"
                        />
                    </div>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog
            v-model="dialogDetailWork"
            width="650px"
            persistent
            content-class="dialog--margin"
        >
            <v-card class="ma-0 pa-0" v-if="work.client!=undefined">
                <ODialogHeaderComp
                    :title="work.client.full_name"
                    @clickclose="dialogDetailWork=false"
                />
                <SDetailWorkComp
                    :work="work"
                    @clickedit="ac_editOptionDetail"
                    @clickdelete="ac_confirmDeleteDetail"
                />
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogTax"
            width="680px"
            persistent
            content-class="dialog--margin"
        >
            <ODialogHeaderComp
                :title="work.client!=undefined ? work.client.full_name : ''"
                @clickclose="ac_closeFormTax()"
            />
            <v-card class="ma-0 pa-2">
                <v-form
                    ref="formtax"
                    v-model="valid"
                    lazy-validation
                    :class="viewTable ? 'd-flex pt-2' : 'pt-2'"
                >
                    <div class="d-flex">
                        <MSelectComp
                            v-model="dataDetailMoney.tax"
                            :items="listTaxFilter"
                            label="Impuesto"
                            :style="viewTable ? 'width:200px; max-width:200px;' : 'width:50%'"
                            itemText="tax"
                            itemValue="tax"
                            class="mr-1"
                            :rules="[v => !m_rule_emptyField(v) || 'El impuesto es requerido']"
                        />
                        <MTextFieldComp
                            :clearable="true"
                            v-model="dataDetailMoney.price"
                            label="Precio (Bs.)"
                            :style="viewTable ? 'width:200px; max-width:200px;' : 'width:50%'"
                            :counter="10"
                            class="mr-1"
                            :rules="[
                                v => !m_rule_emptyField(v) || 'Es requerido',
                                v => m_rule_numberDecimal(v) || 'Debe ser un número',
                                v => (v+'').length<=10 || 'No debe exceder los 10 dígitos']"
                        />
                    </div>
                    <div class="d-flex">
                        <MTextFieldComp
                            :clearable="true"
                            v-model="dataDetailMoney.payment"
                            label="Cancelado (Bs.)"
                            class="mr-1"
                            :style="viewTable ? 'width:200px; max-width:200px;' : 'width:100%'"
                            :counter="10"
                            :rules="[
                                v => !m_rule_emptyField(v) || 'Es requerido',
                                v => m_rule_numberDecimal(v) || 'Debe ser un número',
                                v => !m_rule_exceededNumber(v, dataDetailMoney.price) || 'No mayor a honorario',
                                v => (v+'').length<=10 || 'No debe exceder los 10 dígitos']"
                        />
                        <OBtnHeaderToolTipComp
                            smsTooltip="Agregar Impuesto"
                            icon="mdi-check-bold"
                            @click="ac_addDetailMoneyTax()"
                        />
                    </div>
                </v-form>
                <v-divider></v-divider>
                <div class="d-flex justify-center mt-2 mb-2">
                    <table style="width:100%" class="s-table-fee-type animation-opacity" border="0">
                        <thead>
                            <th>Concepto</th>
                            <th>Precio</th>
                            <th>Cancel.</th>
                            <th>Saldo</th>
                            <th>x</th>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(tax, index) in detailTax"
                                :key="index"
                            >
                                <td class="s-table-fee-type__border-line">
                                    {{tax.tax}}</td>
                                <td class="s-table-fee-type__border-line" style="text-align:right">
                                    {{tax.price.toFixed(2)}}</td>
                                <td class="s-table-fee-type__border-line" style="text-align:right">
                                    {{tax.payment.toFixed(2)}}</td>
                                <td class="s-table-fee-type__border-line" style="text-align:right">
                                    {{(tax.price-tax.payment).toFixed(2)}}</td>
                                <td class="text-center">
                                    <v-btn
                                        :ripple="false"
                                        style="width:24px; height:24px;"
                                        color="red"
                                        fab
                                        depressed
                                        @click="ac_removeFromDetail(index)"
                                    >x</v-btn>
                                </td>
                            </tr>
                            <tr v-if="detailTax.length==0">
                                <td colspan="5" class="text-center">SIN DATOS</td>
                            </tr>
                            <tr v-else>
                                <th class="text-center">TOTAL :</th>
                                <td class="s-table-fee-type__border-line text-right">
                                    {{ac_totalPrice.toFixed(2)}}
                                </td>
                                <td class="s-table-fee-type__border-line text-right">
                                    {{ac_totalCancelled.toFixed(2)}}
                                </td>
                                <td class="s-table-fee-type__border-line text-right">
                                    {{(ac_totalPrice-ac_totalCancelled).toFixed(2)}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-center mt-1">
                    <MBtnNormalComp
                        color="primary"
                        :outlined="true"
                        @click="ac_confirmRegistration()"
                    >REGISTRAR</MBtnNormalComp>
                </div>
            </v-card>
        </v-dialog>
        <SFormPaymentComp
            :payment="payment"
            :work="work"
            :value="dialogPaymentEdit"
            :viewTable="viewTable"
            @clickclose="ac_closeFormUpdatePayment()"
            @clickform="ac_confirmEditPayment"
        />
        <SFormDetailMoneyComp
            :value="dialogDetailMoney"
            :money="money"
            @clickclose="dialogDetailMoney=false"
            @clickform="ac_confirmUpdateMoney"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_cancelConfirmation()"
            @clickyes="ac_performOperation()"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_cancelConfirmation()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            :smsLoaderOperation="smsLoaderOperation"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            :colorMessage="colorMessage"
            @click="m_dataGeneral_frameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MLineHeaderComp,
    MNoDataComp,
    MSelectComp,
    MBtnNormalComp
} from '../../components/molecules'
import {
    OBtnHeaderToolTipComp,
    OSmsConfirmComp,
    OSmsErrorComp,
    OLoaderOperationComp,
    OSmsActionComp,
    ODialogHeaderComp,
    OLoaderDataComp,
    OSearchComp,
    OHeaderWorkPaymentsComp
} from '../../components/organisms'
import {
    STableUnfinishedWorkComp,
    SCardUnfinishedWorkComp,
    SPaginationComp,
    SDetailWorkComp,
    SFormDetailMoneyComp,
    STablePaymentsComp,
    SCardPaymentComp,
    SFormPaymentComp
} from '../../components/species'
import {
    Work,
    Tax,
    DetailMoney,
    Payment
} from '../../models'
import {
    dataGeneralComponetsMixin,
    rulesMixin,
    responseServer,
    userMixin
} from '../../mixins'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        rulesMixin,
        responseServer,
        userMixin
    ],
    components: {
        MTextFieldComp,
        MLineHeaderComp,
        OBtnHeaderToolTipComp,
        OSmsConfirmComp,
        OSmsErrorComp,
        OLoaderOperationComp,
        OSmsActionComp,
        MNoDataComp,
        STableUnfinishedWorkComp,
        ODialogHeaderComp,
        MTextFieldComp,
        OLoaderDataComp,
        SCardUnfinishedWorkComp,
        OSearchComp,
        SDetailWorkComp,
        SPaginationComp,
        MSelectComp,
        MBtnNormalComp,
        SFormDetailMoneyComp,
        OHeaderWorkPaymentsComp,
        STablePaymentsComp,
        SCardPaymentComp,
        SFormPaymentComp
    },
    data: () => ({
        viewTable: true,
        search: '',
        listTaxs: [],
        work: {},
        listWorks: [],
        page: 0,
        last_page: 0,
        dialogDetailWork: false,
        dialogTax: false,
        detailMoney: [],
        dataDetailMoney: new DetailMoney(),
        valid: true,
        detailTax: [],
        indexWork: -1,
        //news
        tab: 0,
        dialogPaymentEdit: false,
        payment: {},
        isUpdatePayment: false,
        indexPayment: -1,
        listPayments: [],
        dialogLoaderDataPayments: false,
        indexMoney: -1,
        idMoney: 0,
        money: {},
        dialogDetailMoney: false
    }),
    computed: {
        searchFilter: function() {
            if (this.search === null) return ''
            else return this.search
        },
        listTaxFilter: function() {
            var newListTaxs = []
            this.listTaxs.forEach(element => {
                var count = 0
                for(var i = 0; i < this.detailMoney.length; i++) {
                    if (element.tax === this.detailMoney[i]) {
                        ++count
                        break
                    }
                }
                if (count === 0) newListTaxs.push(element)
            })
            return newListTaxs
        },
        ac_totalPrice: function() {
            var total = 0
            for (var i = 0; i < this.detailTax.length; i++) total += this.detailTax[i].price
            return total
        },
        ac_totalCancelled: function() {
            var total = 0
            for (var i = 0; i < this.detailTax.length; i++) total += this.detailTax[i].payment
            return total
        }
    },
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_enableActionBtn(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        showEvent(message, colorMessage) {
            this.colorMessage = colorMessage
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        getListTax() {
            Tax.getListTaxs().then(response => {
                this.listTaxs = response
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_searchClient(page, topPos = 0) {
            this.m_dataGeneral_frameTimeReset()
            if (this.m_rule_emptyField(this.search)) {
                this.search = ''
                setTimeout(() => {
                    this.showEvent(['Debe introducir un Nombre o CI/NIT'], 'error')
                }, 200)
            } else {
                this.listWorks = []
                this.dialogLoaderData = true
                Work.getListWorkClient(page, this.search).then(response => {
                    this.listWorks = response.data
                    this.page = response.page
                    this.last_page = response.last_page
                    this.dialogLoaderData = false
                    if (window.innerWidth <= 600) {
                        setTimeout(() => {
                            window.scroll(0, topPos)
                        }, 150)
                    }
                }).catch(error => {
                    this.processError(error)
                })
            }
        },
        ac_showDetailWork(work) {
            this.work = work
            this.dialogDetailWork = true
        },
        ac_changePage(state) {
            var myElement = document.getElementById('position-scroll');
            var topPos = parseInt(myElement.offsetTop) - 45;
            this.m_dataGeneral_frameTimeReset()
            if (this.search===null) this.search = ''
            if (state) { ++this.page }
            else { --this.page }
            if (window.innerWidth <= 600) {
                window.scroll(0, 0)
                setTimeout(() => {
                    this.ac_searchClient(this.page, topPos)
                }, 100)
            } else this.ac_searchClient(this.page)
        },
        ac_showDialogTax(work) {
            this.indexWork = this.listWorks.indexOf(work)
            this.work = work
            var detailMoney = []
            work.detail_money.forEach(element => {
                if (element.honorary !== 1) detailMoney.push(element.tax)
            });
            this.detailMoney = detailMoney
            this.dialogTax = true
        },
        validateData() {
            if (!this.$refs.formtax.validate()) return false
            return true
        },
        thereIsTax() {
            for(var i = 0; i < this.detailTax.length; i++) {
                if (this.detailTax[i].tax === this.dataDetailMoney.tax) return true
            }
            return false
        },
        ac_addDetailMoneyTax() {
            this.m_dataGeneral_frameTimeReset()
            if (this.validateData() && !this.thereIsTax()) {
                const cancelled = (parseFloat(this.dataDetailMoney.price)-parseFloat(this.dataDetailMoney.payment)) > 0 ? 0 : 1
                const paramaters = {
                    //'name': isHonorary ? 'Honorario' : this.dataDetailMoney.tax,
                    'price': parseFloat(this.dataDetailMoney.price),
                    'payment': parseFloat(this.dataDetailMoney.payment),
                    'cancelled': cancelled,
                    'honorary': 0,
                    'tax': this.dataDetailMoney.tax
                }
                this.detailTax.push(paramaters)
                this.dataDetailMoney = new DetailMoney()
                this.$refs.formtax.resetValidation()
            } else {
                if (!this.thereIsTax()) var smsError = ['Corrija los datos', `marcados con rojo`]
                else var smsError = [`El impuesto ${this.dataDetailMoney.tax}`, `se se encuenta agregado`]
                setTimeout(() => {
                    this.showEvent(smsError, 'error')
                }, 200)
            }
        },
        ac_removeFromDetail(index) {
            this.detailTax.splice(index, 1)
        },
        ac_confirmRegistration() {
            if (this.detailTax.length > 0) {
                this.dialogTax = false
                this.smsConfirm = ['¿Está seguro de añadir los impuestos para:',
                    `${this.work.client.full_name}?`]
                this.dialogConfirm = true
            } else {
                this.m_dataGeneral_frameTimeReset()
                var smsError = [`Debe introducir un impuesto al detalle`]
                setTimeout(() => {
                    this.showEvent(smsError, 'error')
                }, 200)
            }
        },
        ac_closeFormTax() {
            this.detailTax = []
            this.dialogTax = false
            this.dataDetailMoney = new DetailMoney()
            this.$refs.formtax.resetValidation()
        },
        ac_cancelConfirmation() {
            if (this.noAccess) {
                localStorage.removeItem('agapeconsultores')
                this.$router.replace('/login')
            } else {
                this.dialogConfirm = false
                if (this.isUpdatePayment)
                    this.dialogPaymentEdit = true
                else {
                    if (this.indexMoney != -1) {
                        if (this.idMoney === 0)
                            this.dialogDetailMoney = true
                        else {
                            this.clearDataDeleteMoney()
                            this.dialogDetailWork = true
                        }
                    }
                    else this.dialogTax = true
                }
            }
        },
        addDetailMoney() {
            const parameters = {
                'id_client': this.work.client.id,
                'detail_tax': this.detailTax,
                'id_work': this.work.id
            }
            DetailMoney.addDetailMoney(parameters).then(response => {
                Object.assign(this.listWorks[this.indexWork], response)
                this.showEvent([`Operación exitosa`, `Se registraron los impuestos`], 'success')
                this.ac_closeFormTax()
                this.detailTax = []
                this.dialogLoaderOperation = false
            }).catch(error => {
                this.processError(error)
            })
        },
        clearDataDeleteMoney() {
            this.indexMoney = -1
            this.idMoney = 0
            this.indexWork = -1
        },
        deleteDetailMoney() {
            DetailMoney.deleteDetailMoney({id: this.idMoney}).then(() => {
                this.listWorks[this.indexWork].detail_money.splice(this.indexMoney, 1)
                this.showEvent([`Operación exitosa`, `Se eliminó el impuesto`], 'success')
                this.clearDataDeleteMoney()
                this.dialogLoaderOperation = false
                this.dialogDetailWork = true
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_performOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            if (this.isUpdatePayment)
                this.updatePayment();
            else {
                if (this.indexMoney !== -1) {
                    if (this.idMoney !== 0) this.deleteDetailMoney()
                    else this.updateDetailMoney()
                } else this.addDetailMoney()
            }
        },
        //new payments
        ac_showListPayments(work) {
            this.work = work
            this.tab = 1
            this.dialogLoaderDataPayments = true
            Payment.getListPaymentsWork(work.id).then(response => {
                this.listPayments = response
                this.dialogLoaderDataPayments = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_openFormEditPayment(payment) {
            this.indexPayment = this.listPayments.indexOf(payment)
            this.isUpdatePayment = true
            this.payment = Object.assign({}, payment)
            this.dialogPaymentEdit = true
        },
        ac_closeFormUpdatePayment() {
            this.dialogPaymentEdit = false
            this.isUpdatePayment = false
        },
        ac_confirmEditPayment(outErrors) {
            if (outErrors) {
                this.dialogPaymentEdit = false
                this.smsConfirm = [`Está seguro de editar el pago a: ${parseFloat(this.payment.payment).toFixed(2)} Bs.`]
                this.dialogConfirm = true
            } else this.showEvent([`ERROR DE DATO`, `corrija el pago`], 'error')
        },
        updatePayment() {
            const parameters = {
                id: this.payment.id,
                payment: this.payment.payment,
                id_work: this.work.id
            }
            Payment.updatePayment(parameters).then(response => {
                const indexWork = this.listWorks.indexOf(this.work)
                Object.assign(this.listWorks[indexWork], response.work)
                Object.assign(this.listPayments[this.indexPayment], response.payment)
                this.isUpdatePayment = false
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa',
                    `El pago fue editado`], 'success')
            }).catch(error => {
                this.processError(error)
            })
        },
        //new methods
        updateDetailMoney() {
            const parameters = {
                id: this.money.id,
                price: this.money.price
            }
            DetailMoney.editDetailMoney(parameters).then(response => {
                Object.assign(this.listWorks[this.indexWork].detail_money[this.indexMoney], response)
                this.showEvent(['Operación exitosa',
                    `El precio fue editado`], 'success')
                this.indexMoney = -1
                this.dialogLoaderOperation = false
                this.dialogDetailWork = true
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_editOptionDetail(money) {
            this.money = Object.assign({}, money)
            this.money.previewPrice = this.money.price
            this.indexWork = this.listWorks.indexOf(this.work)
            this.indexMoney = this.work.detail_money.indexOf(money)
            this.dialogDetailWork = false
            this.dialogDetailMoney = true
        },
        ac_confirmDeleteDetail(money) {
            this.idMoney = money.id
            this.indexWork = this.listWorks.indexOf(this.work)
            this.indexMoney = this.work.detail_money.indexOf(money)
            this.smsConfirm = [
                `¿Está seguro de eliminar el impuesto: ${money.tax}, por: ${money.price.toFixed(2)} Bs.?`
            ]
            this.dialogDetailWork = false
            this.dialogConfirm = true
        },
        ac_confirmUpdateMoney(outErrors) {
            if (outErrors) {
                this.dialogDetailMoney = false
                const typeMoney = this.money.tax != null ? `impuesto ${this.money.tax}` : 'honorario' 
                this.smsConfirm = [
                    `Está seguro de editar el precio de: ${typeMoney},
                    por ${parseFloat(this.money.price).toFixed(2)} Bs.`]
                this.dialogConfirm = true
            } else this.showEvent([`ERROR DE DATO`, `corrija el precio`], 'error')
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            this.getListTax()
        } else this.$router.replace('/login')
    }
}
</script>