import axios from 'axios'
import { apiUrls } from '../plugins/apiUrls'
import { userStore } from '../store/modules'

export default class Payment{
    static getListPaymentsWork(id_work) {
        return axios.get(
            apiUrls.PAYMENT + '/list?id_work='+id_work,
            { headers: userStore.state.header }
        ).then(response => {
                return response.data
            })
    }
    static addPayments(parameters) {
        return axios.post(
            apiUrls.PAYMENT + '/add',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static updatePayment(parameters) {
        return axios.put(
            apiUrls.PAYMENT + '/update-payment',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
                return response.data
        })
    }
    static paymentsCloseBox(page, dateOne, dateTwo) {
        return axios.get(
            `${apiUrls.PAYMENT}/payments-close-box?page=${page}&date_one=${dateOne}&date_two=${dateTwo}`,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
}