<template>
    <div class="s-card-fee-type animation-opacity pa-2">
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Tipo Trabajo:</h1>
            <h1 class="text-body-subtitle">
                {{payment.detail_money.work.fee.fee}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Periodo:</h1>
            <h1 class="text-body-subtitle">
                {{payment.detail_money.work.period!=null ? payment.detail_money.work.period : 'S/P'}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Adeudado:</h1>
            <h1 class="text-body-subtitle">
                {{payment.pre_balance.toFixed(2)}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Cancelado:</h1>
            <h1 class="text-body-subtitle">
                {{payment.payment.toFixed(2)}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Saldo:</h1>
            <h1 class="text-body-subtitle">
                {{(payment.pre_balance-payment.payment).toFixed(2)}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Fecha de registro:</h1>
            <h1 class="text-body-subtitle">
                {{payment.hours+' '+payment.date}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Día registro:</h1>
            <h1 class="text-body-subtitle">
                {{payment.pay_day}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Info. Adicional:</h1>
            <h1 class="text-body-subtitle">
                {{payment.detail_money.work.description != null ? payment.detail_money.work.description : 'S/D'}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">N° Recibo:</h1>
            <h1 class="text-body-subtitle">
                {{payment.fkdetailpayment}}
            </h1>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        payment: {}
    }
}
</script>