<template>
    <v-card
        :width="widthCard"
        class="pa-2"
    >
        <div class="d-flex">
            <h1 style="font-size:16px" class="text-center">RECIBO</h1>
            <v-spacer></v-spacer>
            <h1 style="font-size:16px" class="o-sheet-receipt__data-title">
                {{'N° '+receipt.id}}</h1>
        </div>
        <h1 style="font-size:11px; font-weight:500;">(Expresado en Bolivianos)</h1>
        <v-divider class="mt-1 mb-1"></v-divider>
        <div class="d-flex align-center">
            <div>
                <div class="d-flex">
                    <h1 class="o-sheet-receipt__data-title mr-1">Cliente:</h1>
                    <h1 class="o-sheet-receipt__data-subtitle">
                        {{receipt.client.full_name}}
                    </h1>
                </div>
                <div class="d-flex">
                    <h1 class="o-sheet-receipt__data-title mr-1">CI/NIT:</h1>
                    <h1 class="o-sheet-receipt__data-subtitle">
                        {{receipt.client.ci_nit}}</h1>
                </div>
                <div class="d-flex">
                    <h1 class="o-sheet-receipt__data-title mr-1">Fecha:</h1>
                    <h1 class="o-sheet-receipt__data-subtitle">
                        {{receipt.date}}
                    </h1>
                </div>
            </div>
            <v-spacer></v-spacer>
            <div class="o-sheet-receipt__logo"></div>
        </div>
        <v-divider class="mt-1 mb-1"></v-divider>
        <h1 class="text-center mt-2" style="font-size:14px">DETALLE</h1>
        <table class="o-sheet-receipt__table">
            <thead>
                <tr>
                    <th>N°</th>
                    <th>Concepto</th>
                    <th>Periodo</th>
                    <th>Deuda</th>
                    <th>Cancelado</th>
                    <th>Saldo</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(payment, index) in receipt.payments"
                    :key="index"
                >
                    <td class="td-center">{{(index+1)}}</td>
                    <td>
                        {{payment.detail_money.honorary==1 ? payment.detail_money.work.fee.fee+' - Honorario' : payment.detail_money.work.fee.fee+' - '+payment.detail_money.tax}}</td>
                    <td>
                        {{payment.detail_money.work.period!=null ? payment.detail_money.work.period : 'S/P'}}</td>
                    <td class="td-right">{{payment.pre_balance.toFixed(2)}}</td>
                    <td class="td-right">{{payment.payment.toFixed(2)}}</td>
                    <td class="td-right">
                        {{(payment.pre_balance-payment.payment).toFixed(2)}}
                    </td>
                </tr>
                <tr>
                    <th colspan="3">TOTAL</th>
                    <th class="td-right">{{m_calculation_sumPreBalance(receipt.payments).toFixed(2)}}</th>
                    <th class="td-right">{{m_calculation_sumPayments(receipt.payments).toFixed(2)}}</th>
                    <th class="td-right">
                        {{(m_calculation_sumPreBalance(receipt.payments)-m_calculation_sumPayments(receipt.payments)).toFixed(2)}}
                    </th>
                </tr>
            </tbody>
        </table>
        <div class="text-center mt-2">
            <MBtnNormalComp
                :outlined="true"
                color="primary"
                @click="$emit('clickprint')"
            >IMPRIMIR</MBtnNormalComp>
        </div>
    </v-card>
</template>
<script>
import { MBtnNormalComp } from '../molecules'
import { calculationsWorkMixin } from '../../mixins'
export default {
    mixins: [ calculationsWorkMixin ],
    props: {
        receipt: {},
        widthCard: {
            type: String,
            default: '540px'
        }
    },
    components: {
        MBtnNormalComp
    }
}
</script>