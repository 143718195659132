<template>
    <div class="s-card-fee-type animation-opacity pa-2">
        <div class="d-flex">
            <h1 class="text-body-title mr-1">N°:</h1>
            <h1 class="text-body-subtitle">{{(index+1)}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Concepto:</h1>
            <h1 class="text-body-subtitle">
                 {{payment.concept}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Periodo:</h1>
            <h1 class="text-body-subtitle">{{payment.period}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Deuda:</h1>
            <h1 class="text-body-subtitle">
                {{payment.debt.toFixed(2)}}</h1>
        </div>
        <div class="d-flex align-center">
            <h1 class="text-body-title mr-3">Monto a Cancelar:</h1>
            <MTextFieldComp
                style="width:140px; max-width:140px;"
                :singleLine="true"
                v-model="payment.payment_amount"
                background="white"
                :hideDetails="true"
                :counter="10"
                :rules="[
                    v => !m_rule_emptyField(v) || 'El dato es requerido',
                    v => m_rule_numberDecimal(v) || 'El pago debe ser un número',
                    v => !m_rule_exceededNumber(v, payment.debt) || 'El pago no debe superar el precio del trabajo',
                    v => m_rule_isZero(v) || 'El pago debe ser mayor a cero',
                    v => (v+'').length<=10 || 'El pago no debe exceder los 10 dígitos']"
            />
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Saldo:</h1>
            <h1 class="text-body-subtitle">
                {{(payment.debt-parseFloat(payment.payment_amount)).toFixed(2)}}</h1>
        </div>
    </div>
</template>
<script>
import { MTextFieldComp } from '../../components/molecules'
import { rulesMixin } from '../../mixins'
export default {
    mixins: [ rulesMixin ],
    components: {
        MTextFieldComp
    },
    props: {
        payment: {},
        index: Number
    }
}
</script>