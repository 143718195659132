<template>
    <div>
        <div class="clients__header">
            <div class="clients__header-first">
                <h1 class="text-body-title">TIPO DE GASTOS</h1>
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    <MTextFieldComp
                        label="Buscar"
                        :hideDetails="true"
                        class="history-client__search"
                        background="white"
                        :singleLine="true"
                        :clearable="true"
                        v-model="search"
                    />
                    <OBtnHeaderToolTipComp
                        smsTooltip="Registrar tipo gasto"
                        icon="mdi-plus"
                        class="ml-1"
                        @click="dialogTypeExpense=true"
                    />
                </div>
            </div>
        </div>
        <MLineHeaderComp />
        <OLoaderDataComp
            v-if="dialogLoaderData"
            class="mt-2"
            smsLoaderData="Obteniendo Lista"
        />
        <MNoDataComp
            smsNotData="SIN DATOS"
            class="ma-1"
            v-if="listTypeExpensesFilter.length==0 && !dialogLoaderData && !viewTable"
        />
        <div class="d-flex justify-center mt-3" v-if="viewTable">
            <STableTypeExpenseComp
                v-if="!dialogLoaderData"
                :listTypeExpense="listTypeExpensesFilter"
                @clickupdate="ac_showFormUpdate"
                @clickdelete="ac_confirmDelete"
            />
        </div>
        <div class="d-flex justify-center flex-wrap" v-else>
            <SCardTypeExpenseComp
                v-for="(expense, index) in listTypeExpensesFilter"
                :key="index"
                :expense="expense"
                class="ma-2"
                @clickupdate="ac_showFormUpdate(expense)"
                @clickdelete="ac_confirmDelete(expense)"
            />
        </div>
        <v-dialog
            width="400"
            v-model="dialogTypeExpense"
            persistent
            content-class="dialog--margin"
        >
            <v-card class="pa-0 ma-0">
                <SFormTypeExpenseComp
                    ref="containerform"
                    :dataTypeExpense="dataTypeExpense"
                    :indexTypeExpense="indexTypeExpense"
                    @onClickForm="ac_confirmationActionForm"
                    @onClickCloseForm="ac_closeForm()"
                />
            </v-card>
        </v-dialog>
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_cancelConfirmation()"
            @clickyes="ac_performOperation()"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_cancelConfirmation()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            :smsLoaderOperation="smsLoaderOperation"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MNoDataComp,
    MTextFieldComp
} from '../../components/molecules'
import {
    OSmsConfirmComp,
    OSmsErrorComp,
    OLoaderOperationComp,
    OSmsActionComp,
    OBtnHeaderToolTipComp,
    OLoaderDataComp,
    ODialogHeaderComp
} from '../../components/organisms'
import {
    SCardTypeExpenseComp,
    STableTypeExpenseComp,
    SFormTypeExpenseComp
} from '../../components/species'
import {
    TypeExpense
} from '../../models'
import {
    dataGeneralComponetsMixin,
    responseServer,
    rulesMixin,
    userMixin
} from '../../mixins'
import md5 from 'js-md5'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        responseServer,
        rulesMixin,
        userMixin
    ],
    components: {
        OSmsConfirmComp,
        OSmsErrorComp,
        OLoaderOperationComp,
        OSmsActionComp,

        OBtnHeaderToolTipComp,
        MLineHeaderComp,
        MNoDataComp,
        OLoaderDataComp,
        SCardTypeExpenseComp,
        STableTypeExpenseComp,
        SFormTypeExpenseComp,
        ODialogHeaderComp,
        MTextFieldComp
    },
    data: () => ({
        search: '',
        viewTable: true,
        listTypeExpenses: [],
        dataTypeExpense: new TypeExpense(),
        dialogTypeExpense: false,
        indexTypeExpense: -1,
        isElimination: false,
        idTypeExpense: -1
    }),
    computed: {
        listTypeExpensesFilter: function() {
            if (this.m_rule_emptyField(this.search)) return this.listTypeExpenses
            else {
                return this.listTypeExpenses.filter(expense => {
                    return expense.type_expense.toLowerCase()
                            .indexOf(this.search.toLowerCase()) !== -1
                })
            }
        }
    },
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_enableActionBtn(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        getTypeExpenses() {
            this.dialogLoaderData = true
            TypeExpense.getListTypeExpenses().then(response => {
                this.listTypeExpenses = response
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        resetDataDelete() {
            this.idTypeExpense = -1
            this.indexTypeExpense = -1
            this.isElimination = false
            this.dialogLoaderOperation = false
        },
        ac_closeForm() {
            this.dialogTypeExpense = false
            setTimeout(() => {
                this.$refs.containerform.resetForm()
                this.dataTypeExpense = new TypeExpense()
                this.indexTypeExpense = -1
            }, 200)
        },
        ac_confirmationActionForm(validForm) {
            this.dialogTypeExpense = false
            if (!validForm) {
                this.smsError = ['Revise los datos marcados con rojo y corrija porfavor']
                this.actionError = true
                this.dialogError = true
            } else {
                if (this.indexTypeExpense !== -1) {
                    this.smsConfirm = [`Está seguro de actualizar el tipo de gasto: ${this.dataTypeExpense.type_expense}`]
                } else {
                    this.smsConfirm = [`Está seguro de registrar el tipo de gasto: ${this.dataTypeExpense.type_expense}`]
                }
                this.dialogConfirm = true
            }
        },
        ac_cancelConfirmation() {
            if (this.noAccess) {
                localStorage.removeItem('agapeconsultores')
                this.$router.replace('/login')
            } else {
                this.dialogConfirm = false
                this.dialogError = false
                if (!this.isElimination) this.dialogTypeExpense = true
                else this.resetDataDelete()
            }
        },
        ac_performOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            if (this.isElimination)
                this.deleteTypeExpense()
            else {
                if (this.indexTypeExpense !== -1)
                    this.updateTypeExpense()
                else this.addTypeExpense()
            }
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        addTypeExpense() {
            TypeExpense.addTypeExpense(this.dataTypeExpense).then(response => {
                this.listTypeExpenses.unshift(response)
                this.ac_closeForm()
                this.dialogLoaderOperation = false
                this.showEvent(['Registro exitoso',
                    `Se registro el tipo de gasto: ${response.type_expense}`])
            }).catch(error => {
                this.processError(error)
            })
        },
        updateTypeExpense() {
            TypeExpense.updateTypeExpense(this.dataTypeExpense).then(response => {
                Object.assign(this.listTypeExpenses[this.indexTypeExpense], response)
                this.ac_closeForm()
                this.dialogLoaderOperation = false
                this.showEvent(['Actualización exitosa',
                    `Se actualizó el tipo de gasto: ${response.type_expense}`])
            }).catch(error => {
                this.processError(error)
            })
        },
        deleteTypeExpense() {
            TypeExpense.deleteTypeExpense({'id' :this.idTypeExpense}).then(() => {
                this.listTypeExpenses.splice(this.indexTypeExpense, 1)
                this.resetDataDelete()
                this.showEvent(['Eliminación exitosa',
                    `Se eliminó el tipo de gasto`])
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_showFormUpdate(type_expense) {
            this.m_dataGeneral_frameTimeReset()
            this.dataTypeExpense = Object.assign({}, type_expense)
            this.indexTypeExpense = this.listTypeExpenses.indexOf(type_expense)
            this.dialogTypeExpense = true
        },
        ac_confirmDelete(type_expense) {
            this.m_dataGeneral_frameTimeReset()
            this.smsConfirm = [`Está seguro de eliminar el honorario: ${type_expense.type_expense}`]
            this.isElimination = true
            this.indexTypeExpense = this.listTypeExpenses.indexOf(type_expense)
            this.idTypeExpense = type_expense.id
            this.dialogConfirm = true
        },
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            this.getTypeExpenses()
        } else this.$router.replace('/login')
    }
}
</script>