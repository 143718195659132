const domain = process.env.VUE_APP_ROOT_API;
export const apiUrls = {
    FEED_TYPE: domain + 'feed-type',
    CLIENT: domain + 'client',
    WORK: domain + 'work',
    TYPE_EXPENSE: domain + 'type-of-expense',
    EXPENSE: domain + 'expense',
    PAYMENT: domain + 'payment',
    RATE: domain + 'rate',
    TAX: domain + 'tax',
    TIPE_COMPANY: domain + 'type-company',
    DETAIL_MONEY: domain + 'detail-money',
    DETAIL_PAYMENTS: domain + 'detail-payment',
    USER: domain + 'user'
}