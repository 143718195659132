<template>
    <table class="s-table-fee-type animation-opacity" border="0">
        <thead>
            <th>Tipo de Honorario</th>
            <th>Tarifa (Bs.)</th>
            <th>Cliente</th>
            <th>CI / NIT</th>
            <th>Descripción</th>
            <th>Acciones</th>
        </thead>
        <tbody>
            <tr
                v-for="(rate, index) in listRates"
                :key="index"
            >
                <td class="s-table-fee-type__border-line">{{rate.feetype.fee}}</td>
                <td class="s-table-fee-type__border-line" style="text-align:right">{{rate.rate.toFixed(2)}}</td>
                <td class="s-table-fee-type__border-line">{{rate.client.full_name}}</td>
                <td class="s-table-fee-type__border-line">{{rate.client.ci_nit}}</td>
                <td class="s-table-fee-type__border-line">
                    {{rate.description!=null ? rate.description : 'S/D'}}</td>
                <td>
                    <div class="d-flex justify-center">
                        <OBtnOptionToolTipComp
                            smsTooltip="Actualizar Tarifa"
                            icon="mdi-lead-pencil"
                            style="margin:2px"
                            @click="$emit('clickupdate', rate)"
                        />
                        <OBtnOptionToolTipComp
                            smsTooltip="Eliminar Tarifa"
                            icon="mdi-trash-can"
                            style="margin:2px"
                            @click="$emit('clickdelete', rate)"
                        />
                    </div>
                </td>
            </tr>
            <tr v-if="listRates.length==0" class="text-center">
                <td colspan="6">SIN TARIFAS</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import { OBtnOptionToolTipComp } from '../organisms'
export default {
    props: {
        listRates: Array
    },
    components: {
        OBtnOptionToolTipComp
    }
}
</script>