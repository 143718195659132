<template>
    <div class="pa-2">
        <div class="d-flex">
            <h1 class="text-body-title mr-1">CI/NIT:</h1>
            <h1 class="text-body-subtitle">{{work.client.ci_nit}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Tipo Trabajo:</h1>
            <h1 class="text-body-subtitle">{{work.fee.fee}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Periodo:</h1>
            <h1 class="text-body-subtitle">
                {{work.period!=null ? work.period : 'S/P'}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Descripción:</h1>
            <h1 class="text-body-subtitle">
                {{work.description!=null ? work.description : 'S/D'}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Estado del trabajo:</h1>
            <h1 class="text-body-subtitle">
                {{work.concluded!=0 ? 'Ejecutado' : 'No ejecutado'}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Trabajo Cancelado:</h1>
            <h1 class="text-body-subtitle">
                {{work.cancelled==1 ? 'SI': 'NO'}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Fecha de Trabajo:</h1>
            <h1 class="text-body-subtitle">
                {{work.work_date}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Fecha de Registro:</h1>
            <h1 class="text-body-subtitle">
                {{work.date}}
            </h1>
        </div>
        <v-divider></v-divider>
        <div class="d-flex justify-center mt-2 mb-2">
            <table class="s-table-fee-type" border="0">
                <thead>
                    <th>{{mobile ? 'Concep.' : 'Concepto'}}</th>
                    <th>Precio</th>
                    <th>{{mobile ? 'Cancel.' : 'Cancelado'}}</th>
                    <th>Saldo</th>
                    <th v-if="!isInfo">Editar</th>
                </thead>
                <tbody>
                    <tr
                        v-for="(money, index) in work.detail_money"
                        :key="index"
                    >
                        <td class="s-table-fee-type__border-line">
                            {{money.honorary==1 ? 'Honorario' : money.tax}}
                        </td>
                        <td class="s-table-fee-type__border-line" style="text-align:right">
                            {{money.price.toFixed(2)}}
                        </td>
                        <td class="s-table-fee-type__border-line" style="text-align:right">
                            {{m_calculation_sumPayments(money.payments).toFixed(2)}}
                        </td>
                        <td style="text-align:right">
                            {{(money.price-m_calculation_sumPayments(money.payments)).toFixed(2)}}
                        </td>
                        <td 
                            class="d-flex justify-center flex-wrap"
                            v-if="!isInfo"
                        >
                            <OIconToolTipComp
                                icon="mdi-pencil"
                                style="margin:2px"
                                :smsTooltip="money.honorary==1 ? 'Editar Honorario' : 'Editar Impuesto'"
                                @click="$emit('clickedit', money)"
                            />
                            <OIconToolTipComp
                                v-if="money.honorary!=1"
                                style="margin:2px"
                                icon="mdi-trash-can"
                                smsTooltip="Eliminar Impuesto"
                                @click="$emit('clickdelete', money)"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>TOTALES:</th>
                        <td style="text-align:right">{{m_calculation_totalCost(work).toFixed(2)}}</td>
                        <td style="text-align:right">{{m_calculation_totalCancelled(work).toFixed(2)}}</td>
                        <td style="text-align:right">{{ac_totalDebt(work).toFixed(2)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { calculationsWorkMixin } from '../../mixins'
import { OIconToolTipComp } from '../organisms'
export default {
    components: {
        OIconToolTipComp
    },
    mixins: [
        calculationsWorkMixin
    ],
    props: {
        work: {},
        isInfo: Boolean
    },
    data: () => ({
        mobile: false
    }),
    methods: {
        ac_totalDebt(work) {
            const debt = this.m_calculation_totalCost(work)-this.m_calculation_totalCancelled(work)
            return debt
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true
    }
}
</script>