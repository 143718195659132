<template>
    <div class="no-data-comp d-flex justify-center animation-opacity">
        <h1 class="text-body-title">
            {{smsNotData}}
        </h1>
    </div>
</template>
<script>
export default {
    props: {
        smsNotData: String
    }
}
</script>
<style scoped>
    .no-data-comp{
        padding: 6px;
        border-radius: 5px;
        background: #d6e9d1;
    }
</style>