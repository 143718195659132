export default {
    methods: {
        m_response_enableActionBtn(error) {
            if( error.response !==  undefined ) {
                if (error.response.status == 500) return false
                return true
            }
            return false
        },
        m_response_smsError(error) {
            var smsResponse = []
            if( error.response !==  undefined ) {
                switch (error.response.status) {
                    case 422:
                        smsResponse = error.response.data
                        break
                    case 500:
                        smsResponse = ['Error en el proceso de almacenado en la B.D.',
                            'Revise su concexión de internet y refresque la página (F5)']
                        break
                    case 403:
                        smsResponse = ['Usted no tiene permiso para realizar la operación que solicitó',
                            'GRACIAS']
                        break
                    case 401:
                        smsResponse = ['OPERACIONES CANCELADAS',
                            'Se negaron permisos de acceso a su cuenta, ',
                            'comuníquese con el administrador']
                        break
                    default:
                        smsResponse = ['Error en el servidor', 'Revise su conexión de internet y refresque la página (F5)']
                }
            } else {
                smsResponse = ['Error de conexión de internet',
                    'Revise su concexión de internet y refresque la página (F5)']
            }
            return smsResponse
        },
        m_response_autorization(error) {
            if( error.response !==  undefined ) {
                if (error.response.status === 401) return true
            }
            return false
        }
    }
}