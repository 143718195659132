<template>
    <table class="s-table-fee-type animation-opacity" border="0">
        <thead>
            <th>N°</th>
            <th>Fecha de registro</th>
            <th>Día registro</th>
            <th>Cancelado</th>
            <th>Acciones</th>
        </thead>
        <tbody>
            <tr
                v-for="(receipt, index) in listTransactions"
                :key="index"
            >
                <td class="s-table-fee-type__border-line text-center">
                    {{(index+1)}}</td>
                <td class="s-table-fee-type__border-line text-center">
                    {{receipt.hour+' '+receipt.date}}</td>
                <td class="s-table-fee-type__border-line text-center">
                    {{receipt.pay_day}}</td>
                <td class="s-table-fee-type__border-line text-center">
                    {{getTotalCancelled(receipt).toFixed(2)}}</td>
                <td>
                    <div class="d-flex justify-center">
                        <OBtnOptionToolTipComp
                            smsTooltip="Lista de Pagos"
                            icon="mdi-cash"
                            style="margin:2px"
                            @click="$emit('clickpayments', receipt)"
                        />
                        <OBtnOptionToolTipComp
                            smsTooltip="Imprimir recibo"
                            icon="mdi-printer"
                            style="margin:2px"
                            @click="$emit('clickprint', receipt)"
                        />
                    </div>
                </td>
            </tr>
            <tr v-if="listTransactions.length==0" class="text-center">
                <td colspan="4">SIN CANCELACIONES</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import { OBtnOptionToolTipComp } from '../organisms'
export default {
    props: {
        listTransactions: Array
    },
    components: {
        OBtnOptionToolTipComp
    },
    methods: {
        getTotalCancelled(receipt) {
            var total = 0
            for(var  i = 0; i<receipt.payments.length; i++) {
                total += receipt.payments[i].payment
            }
            return total
        }
    }
}
</script>