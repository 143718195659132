import axios from 'axios'
import { apiUrls } from '../plugins/apiUrls'
import { userStore } from '../store/modules'
export default class DetailMoney{
    constructor(
        name = null,
        price = null,
        honorary = 0,
        payment = 0,
        tax = null,
        cancelled = 0
    ) {
        this.name = name
        this.price = price
        this.honorary = honorary
        this.tax = tax
        this.payment = payment
        this.cancelled = cancelled
    }
    static addDetailMoney(parameters) {
        return axios.post(
            apiUrls.DETAIL_MONEY + '/add',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static editDetailMoney(parameters) {
        return axios.put(
            apiUrls.DETAIL_MONEY + '/edit',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static deleteDetailMoney(parameters) {
        return axios.delete(
            apiUrls.DETAIL_MONEY + '/delete',
            { headers: userStore.state.header, data: parameters }
        ).then(response => {
            return response.data
        })
    }
}