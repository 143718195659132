import axios from 'axios'
import { apiUrls } from '../plugins/apiUrls'
import { userStore } from '../store/modules'

export default class Expense{
    constructor(
        expense = null,
        receipt_number = null,
        expense_date = null,
        transient = null,
        description = null,
        fktypeexpense = null,
        searchfktypeexpense = null
    ){
        this.expense = expense
        this.receipt_number = receipt_number
        this.expense_date = expense_date
        this.description = description
        this.transient = transient
        this.fktypeexpense = fktypeexpense
        this.searchfktypeexpense = searchfktypeexpense
    }
    static getListExpenses(page, fktypeexpense, date1, date2, transient) {
        return axios.get(
            `${apiUrls.EXPENSE}/list?page=${page}&fktypeexpense=${fktypeexpense}&date1=${date1}&date2=${date2}&transient=${transient}`,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static getListExpensesReport(page, fktypeexpense, date1, date2, transient) {
        return axios.get(
            `${apiUrls.EXPENSE}/list-report?page=${page}&fktypeexpense=${fktypeexpense}&date1=${date1}&date2=${date2}&transient=${transient}`,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static addExpense(parameters) {
        return axios.post(
            apiUrls.EXPENSE + '/add',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static updateExpense(parameters) {
        return axios.put(
            apiUrls.EXPENSE + '/update',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static deleteExpense(parameters) {
        return axios.delete(
            apiUrls.EXPENSE + '/delete',
            { headers: userStore.state.header, data: parameters }
        ).then(response => {
            return response.data
        })
    }
    static reportPdf(date1, date2, transient, fktypeexpense) {
        window.open(apiUrls.EXPENSE + '/report-pdf?date1=' + date1 + '&date2=' + date2 +
            '&transient=' + transient + '&fktypeexpense='+fktypeexpense, '_blank')
    }
    static getExpenseCloseBox(page, dateOne, dateTwo) {
        return axios.get(
            `${apiUrls.EXPENSE}/expenses-close-box?page=${page}&date_one=${dateOne}&date_two=${dateTwo}`,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
}