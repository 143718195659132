<template>
    <div>
        <div class="pa-2 d-flex align-center" style="height:56px">
            <h1 class="text-body-title mr-2">BALANCE</h1>
            <h1 class="text-body-subtitle">(Exp. en Bs.)</h1>
        </div>
        <MLineHeaderComp />
        <div class="d-flex justify-center pa-2 pb-0">
            <ODualDatesComp
                class="balance__submenu-second"
                @onChangingDate="ac_searchDates"
            />
        </div>
        <v-divider class="mt-2"></v-divider>
        <OLoaderDataComp
            v-if="dialogLoaderData"
            class="mt-2"
            smsLoaderData="Obteniendo Balance"
        />
        <div class="mt-2 animation-opacity">
            <div v-if="balance.total_honorary!=undefined" class="pl-2 pr-2">
                <div class="d-flex">
                    <div style="width:240px">
                        <h1 class="text-body-subtitle text-right">Cantidad Trabajos:</h1>
                    </div>
                    <div style="width:100px">
                        <h1 class="text-body-subtitle text-right">{{balance.quantity_works}}</h1>
                    </div>
                </div>
                <div class="d-flex mt-1">
                    <div style="width:240px">
                        <h1 class="text-body-title text-right">INGRESOS HONORARIOS:</h1>
                    </div>
                    <div style="width:100px">
                        <h1 class="text-body-subtitle text-right">{{balance.total_honorary.toFixed(2)}}</h1>
                    </div>
                </div>
                <div class="d-flex mt-1">
                    <div style="width:240px">
                        <h1 class="text-body-subtitle text-right pr-1">Cancelado:</h1>
                    </div>
                    <div style="width:100px">
                        <h1 class="text-body-subtitle text-right">
                            {{balance.total_payments_honorary.toFixed(2)}}
                        </h1>
                    </div>
                </div>
                <div class="d-flex mt-1">
                    <div style="width:240px">
                        <h1 class="text-body-subtitle text-right pr-1">Cuentas por Cobrar:</h1>
                    </div>
                    <div style="width:100px">
                        <h1 class="text-body-subtitle text-right">
                            {{(balance.total_honorary-balance.total_payments_honorary).toFixed(2)}}
                        </h1>
                    </div>
                </div>
                <v-divider class="mt-2 mb-2"></v-divider>
                <div class="d-flex">
                    <div style="width:240px">
                        <h1 class="text-body-title text-right pr-1">IMPUESTOS RECAUDADOS:</h1>
                    </div>
                    <div style="width:100px">
                        <h1 class="text-body-subtitle text-right">
                            {{balance.total_taxs.toFixed(2)}}
                        </h1>
                    </div>
                </div>
                <div class="d-flex mt-1">
                    <div style="width:240px">
                        <h1 class="text-body-subtitle text-right pr-1">Cancelado:</h1>
                    </div>
                    <div style="width:100px">
                        <h1 class="text-body-subtitle text-right">
                            {{balance.total_payments_taxs.toFixed(2)}}
                        </h1>
                    </div>
                </div>
                <div class="d-flex mt-1">
                    <div style="width:240px">
                        <h1 class="text-body-subtitle text-right pr-1">Cuentas por Cobrar:</h1>
                    </div>
                    <div style="width:100px">
                        <h1 class="text-body-subtitle text-right">
                            {{(balance.total_taxs-balance.total_payments_taxs).toFixed(2)}}
                        </h1>
                    </div>
                </div>
                <v-divider class="mt-2 mb-4"></v-divider>
                <div class="d-flex mt-1">
                    <div style="width:240px">
                        <h1 class="text-body-title text-right pr-1">(+) INGRESOS:</h1>
                    </div>
                    <div style="width:100px">
                        <h1 class="text-body-subtitle text-right">
                            {{balance.total_payments_honorary.toFixed(2)}}
                        </h1>
                    </div>
                </div>
                <div class="d-flex mt-1">
                    <div style="width:240px">
                        <h1 class="text-body-title text-right pr-1">(-) GASTOS:</h1>
                    </div>
                    <div style="width:100px">
                        <h1 class="text-body-subtitle text-right">
                            {{balance.total_expense.toFixed(2)}}
                        </h1>
                    </div>
                </div>
                <div class="d-flex mt-1">
                    <div style="width:240px">
                        <h1 :style="ac_getColorDiff" class="text-body-title text-right pr-1">
                            {{ac_getDiffCash>=0 ? 'GANANCIA' : 'PÉRDIDA'}}
                        </h1>
                    </div>
                    <div style="width:100px">
                        <h1 :style="ac_getColorDiff" class="text-body-subtitle text-right">
                            {{ac_getDiffCash.toFixed(2)}}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MSelectComp
} from '../../components/molecules'
import {
    ODualDatesComp,
    OSmsErrorComp,
    OLoaderDataComp
} from '../../components/organisms'
import { Work } from '../../models'
import { responseServer, userMixin } from '../../mixins'
export default {
    mixins: [responseServer, userMixin],
    components: {
        MLineHeaderComp,
        ODualDatesComp,
        OSmsErrorComp,
        MSelectComp,
        OLoaderDataComp
    },
    data: () => ({
        date1: '',
        date2: '',
        dialogError: false,
        smsError: [],
        actionError: true,
        balance: {},
        dialogLoaderData: false
    }),
    computed: {
        ac_getDiffCash: function() {
            if (this.balance.total_payments_honorary !== undefined) {
                return this.balance.total_payments_honorary-this.balance.total_expense
            }
            return 0;
        },
        ac_getColorDiff: function() {
            if (this.ac_getDiffCash < 0) return 'color: red'
            return 'color: green'
        }
    },
    methods: {
        processError(error) {
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_enableActionBtn(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('agapeconsultores')
                this.$router.replace('/login')
            } else this.dialogError = false
        },
        getBalance(date1, date2) {
            this.balance = {}
            this.dialogLoaderData = true
            Work.balance(date1, date2).then(response => {
                this.balance = response
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_searchDates(dates = null) {
            this.date1 = dates ? dates.date1 : ''
            this.date2 = dates ? dates.date2 : ''
            setTimeout(()=> {
                this.getBalance(this.date1, this.date2)
            }, 200)
        }
    },
    mounted() {
        if (this.m_user_saveToken()) {
            this.getBalance('','', 'created_at', 'created_at')
        } else this.$router.replace('/login')
    }
}
</script>