<template>
    <div class="s-card-fee-type animation-opacity pa-2">
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Gasto:</h1>
            <h1 class="text-body-subtitle">{{expense.type_expense.type_expense}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Monto:</h1>
            <h1 class="text-body-subtitle">
                {{expense.expense.toFixed(2)}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">N° de Rec./Fac.:</h1>
            <h1 class="text-body-subtitle">
                {{expense.receipt_number!= null ? expense.receipt_number : 'S/N'}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Transitorio:</h1>
            <h1 class="text-body-subtitle">
                {{expense.transient==0 ? 'No' : 'Si'}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Fecha de gasto:</h1>
            <h1 class="text-body-subtitle">
                {{expense.expense_date_format}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Inf. Adicional:</h1>
            <h1 class="text-body-subtitle">
                {{expense.description!=null ? expense.description : 'S/I'}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Fecha de registro:</h1>
            <h1 class="text-body-subtitle">
                {{expense.registration_date}}</h1>
        </div>
        <div class="d-flex justify-center mt-1" v-if="!itsJustInformation">
            <OBtnOptionToolTipComp
                smsTooltip="Actualizar gasto"
                icon="mdi-lead-pencil"
                class="mr-1"
                @click="$emit('clickupdate')"
            />
            <OBtnOptionToolTipComp
                smsTooltip="Eliminar gasto"
                icon="mdi-trash-can"
                style="margin:2px"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import {
    OBtnOptionToolTipComp
} from '../organisms'
export default {
    props: {
        expense: {},
        itsJustInformation: {
            type: Boolean,
            default: false
        }
    },
    components: {
        OBtnOptionToolTipComp
    }
}
</script>