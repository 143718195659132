<template>
    <div class="s-card-fee-type animation-opacity pa-2">
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Concepto:</h1>
            <h1 class="text-body-subtitle">
                {{payment.detail_money.honorary==1 ? 'Honorario' : payment.detail_money.tax}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Adeudado:</h1>
            <h1 class="text-body-subtitle">{{payment.pre_balance.toFixed(2)}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Cancelado:</h1>
            <h1 class="text-body-subtitle">{{payment.payment.toFixed(2)}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Saldo:</h1>
            <h1 class="text-body-subtitle">{{(payment.pre_balance-payment.payment).toFixed(2)}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Día Cancelado:</h1>
            <h1 class="text-body-subtitle">{{payment.pay_day}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">Fecha Cancelado:</h1>
            <h1 class="text-body-subtitle">{{payment.hours+' '+payment.date}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-title mr-1">N° Recibo:</h1>
            <h1 class="text-body-subtitle">{{payment.fkdetailpayment}}</h1>
        </div>
        <div class="d-flex justify-center mt-1" v-if="isTable">
            <OBtnOptionToolTipComp
                smsTooltip="Editar Trabajo"
                icon="mdi-lead-pencil"
                style="margin:2px"
                @click="$emit('clickedit')"
            />
        </div>
    </div>
</template>
<script>
import { OBtnOptionToolTipComp } from '../organisms'
export default {
    components: {
        OBtnOptionToolTipComp
    },
    props: {
        payment: {},
        isTable: {
            type: Boolean,
            default: true
        }
    }
}
</script>