import axios from 'axios'
import { apiUrls } from '../plugins/apiUrls'
import { userStore } from '../store/modules'

export default class TypeCompany{
    constructor(
        type_company = null
    ){
        this.type_company = type_company
    }
    static getListTypeCompany() {
        return axios.get(
            apiUrls.TIPE_COMPANY + '/list',
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static addTypeCompany(parameters) {
        return axios.post(
            apiUrls.TIPE_COMPANY + '/add',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static updateTypeCompany(parameters) {
        return axios.put(
            apiUrls.TIPE_COMPANY + '/update',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
}