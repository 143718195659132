<template>
    <v-card-actions class="pa-0 pb-2 pt-2 d-flex justify-center">
        <v-btn
            :small="true"
            :loading="loading"
            :ripple="false"
            color="primary"
            outlined
            @click="$emit('clickaction')"
        >{{actiontext}}</v-btn>
    </v-card-actions>
</template>
<script>
export default {
    props: {
        actiontext: String,
        loading: Boolean
    }
}
</script>
